/* eslint-disable react/prop-types */
import React from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";

const ComputerItem = ({
  session,
  onSelectItem,
  isAdmin = false,
  selectedComputerItem
}) => (
  <List.Item
    as="a"
    active={
      selectedComputerItem ? selectedComputerItem.id === session.id : false
    }
    onClick={() => onSelectItem(session)}
  >
    {/*{!isAdmin && (*/}
    {/*  <List.Content floated="right">*/}
    {/*    Online Session Duration: {Number(session.sessionDuration).toFixed(2)}{" "}*/}
    {/*    min*/}
    {/*  </List.Content>*/}
    {/*)}*/}
    <List.Icon
      name="computer"
      size="large"
      verticalAlign="middle"
      color={session.onlineStatus ? "green" : "red"}
    />

    <List.Content>
      <List.Header as="h4">{session.computerName}</List.Header>
      <List.Description>{session.computerDesc}</List.Description>
    </List.Content>
  </List.Item>
);

ComputerItem.propTypes = {
  session: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired
};

const ComputerList = ({ sessionData, onSelectItem, selectedComputerItem }) => (
  <List animated selection divided verticalAlign="middle">
    <List.Header as="h4">Active Agents</List.Header>
    {sessionData &&
      Object.prototype.hasOwnProperty.call(sessionData, "items") &&
      sessionData.items.map(session => {
        return (
          <ComputerItem
            key={session.id}
            session={session}
            onSelectItem={onSelectItem}
            selectedComputerItem={selectedComputerItem}
          />
        );
      })}
  </List>
);

ComputerList.propTypes = {
  sessionData: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool
};

export default ComputerList;
