/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createRef } from "react";
import { Button, Divider, Form, Input, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  closeModalService,
  saveCustomer,
  updateCustomer,
  onSelectCustomer
} from "../../../../actions";

import { Formik } from "formik";
import * as Yup from "yup";
import { useDecodeLicense } from "../../../../util/useDecodeLicense";

const userSchema = Yup.object().shape({
  id: Yup.string(),

  email: Yup.string()
    .label("Email")
    .email()
    .required(),
  firstName: Yup.string()
    .label("FirstName")
    .required(),
  lastName: Yup.string()
    .label("LastName")
    .required(),
  companyName: Yup.string()
    .label("CompanyName")
    .required(),
  phoneNumber: Yup.string()
    .label("PhoneNumber")
    .required()
});

class AddCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        phoneNumber: "",
        checkSum: "",
        role: ["Customer"]
      }
    };

    this.inputField = createRef();
  }

  // componentDidMount() {
  //   console.log("Model");
  // }

  componentDidMount() {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id")
    ) {
      this.setState({
        user: {
          ...this.props.selectedCustomer
        }
      });
    }
  }

  handleSubmit = values => {
    if (Object.prototype.hasOwnProperty.call(values, "id")) {
      this.props.updateCustomer(values);
    } else {
      this.props.saveCustomer(values);
    }
    setTimeout(() => {
      this.onClose();
    }, 500);
  };

  onClose = () => {
    this.props.closeModalService();
  };

  onClickUploadBtn = () => this.inputField.current.click();

  handleSubmitCustomer = event => {
    event.preventDefault();
    // eslint-disable-next-line no-useless-escape
    const ext = event.target.value.match(/\.([^\.]+)$/)[1];
    if (event.target.files[0] instanceof File && ext.toString() === "lic") {
      const customerData = useDecodeLicense(event.target.files[0]);
      setTimeout(() => {
        if (
          this.props.selectedCustomer &&
          Object.prototype.hasOwnProperty.call(
            this.props.selectedCustomer,
            "id"
          )
        ) {
          this.props.updateCustomer({
            ...this.props.selectedCustomer,
            ...customerData
          });
        } else {
          this.props.saveCustomer(customerData);
        }

        this.onClose();
      }, 500);
    }
  };

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <Modal.Header>
          {Object.prototype.hasOwnProperty.call(user, "id")
            ? "Update Customer"
            : "Add Customer"}
        </Modal.Header>
        <Modal.Content>
          <br />
          <Button color="blue" fluid onClick={this.onClickUploadBtn}>
            Upload license
          </Button>
          <input
            accept=".lic"
            id="upload-customer-file"
            type="file"
            style={{ display: "none" }}
            ref={this.inputField}
            onChange={this.handleSubmitCustomer}
          />
          <br />
          <p style={{ textAlign: "center" }}>OR</p>
          <Formik
            enableReinitialize={true}
            initialValues={user}
            validationSchema={userSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                this.handleSubmit(values);
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleBlur,
              handleSubmit
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Field
                  control={Input}
                  label="First name"
                  name="firstName"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={
                    errors.firstName && touched.firstName
                      ? { content: errors.firstName }
                      : null
                  }
                  placeholder="First name"
                />
                <Form.Field
                  control={Input}
                  label="Last name"
                  placeholder="Last name"
                  name="lastName"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={
                    errors.lastName && touched.lastName
                      ? { content: errors.lastName }
                      : null
                  }
                />
                <Form.Field
                  control={Input}
                  label="Email"
                  placeholder="email"
                  name="email"
                  required
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.email && touched.email
                      ? { content: errors.email }
                      : null
                  }
                />
                <Form.Field
                  control={Input}
                  label="Company"
                  placeholder="company name"
                  name="companyName"
                  required
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.companyName && touched.companyName
                      ? { content: errors.companyName }
                      : null
                  }
                />
                <Form.Field
                  control={Input}
                  label="Phone Number"
                  placeholder="+31 XXXXXXXXX"
                  name="phoneNumber"
                  required
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.phoneNumber && touched.phoneNumber
                      ? { content: errors.phoneNumber }
                      : null
                  }
                />

                <Divider />
                <Button.Group floated="right">
                  <Button negative onClick={this.onClose}>
                    Cancel
                  </Button>
                  <Button
                    positive
                    size="medium"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Button.Group>
              </Form>
            )}
          </Formik>
        </Modal.Content>
        <Modal.Actions />
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  saveCustomer,
  updateCustomer,
  onSelectCustomer,
  closeModalService
})(AddCustomer);
