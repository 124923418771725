import { API_URL_AUTH } from "../constants/Api";
import API from "./api";

export const webLogin = payload => {
  const body = JSON.stringify({
    ...payload
  });

  return {
    url: API_URL_AUTH + "login",
    opt: API.requestOptions("POST", null, null, { body })
  };
};

export const forgotPwd = payload => {
  const body = JSON.stringify({
    ...payload
  });

  return {
    url: API_URL_AUTH + "send-reset-link",
    opt: API.requestOptions("POST", null, null, { body })
  };
};

export const resetPwd = payload => {
  const body = JSON.stringify({
    ...payload
  });

  return {
    url: API_URL_AUTH + "reset-password",
    opt: API.requestOptions("POST", null, null, { body })
  };
};

export const esdLogin = payload => {
  const body = JSON.stringify({
    ...payload
  });

  return {
    url: API_URL_AUTH + "ahk",
    opt: API.requestOptions("POST", null, null, { body })
  };
};

export default {
  esdLogin,
  webLogin,
  forgotPwd,
  resetPwd
};
