import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Header,
  Menu,
  Icon,
  Table,
  Dropdown
} from "semantic-ui-react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PackageItem from "./PackageItem";
import { selectAuthUser } from "../../../../../selectors/auth";
import {
  deletePackage,
  findPackage,
  findPackageSuccess,
  openModalService
} from "../../../../../actions";
import PackageModalForm from "../../components/PackageForm";
import {
  getSelectedPackageSelector,
  getSelectedPackageStatusSelector
} from "../../../../../selectors/package";
import { widthSelector } from "../../../../../selectors/settings";
import Loader from "../../../../../components/Loader";
import CustomScrollbars from "../../../../../util/CustomScrollbars";
import CustomerList from "./CustomerList";

const PackageItemDetails = ({ setShowMenu }) => {
  const { params } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const selectedPackage = useSelector(getSelectedPackageSelector);
  const isFindingPackage = useSelector(getSelectedPackageStatusSelector);
  const width = useSelector(widthSelector);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(params, "packageId")) {
      setShowMenu(false);
      dispatch(findPackage(Number(params.packageId)));
    }
    return () => {
      setShowMenu(true);
    };
  }, [dispatch, setShowMenu]);

  const goBack = () => history.replace("/store/packages");

  const editPackage = () =>
    dispatch(
      openModalService({
        children: <PackageModalForm selectedPackage={selectedPackage} />,
        size: "tiny"
      })
    );

  const bulkAssignPackage = () =>
    dispatch(
      openModalService({
        children: (
          <CustomerList packageType={1} packageId={selectedPackage.id} />
        ),
        size: "tiny"
      })
    );

  const bulkDetachPackage = () =>
    dispatch(
      openModalService({
        children: (
          <CustomerList
            packageType={1}
            packageId={selectedPackage.id}
            isAttaching={false}
          />
        ),
        size: "tiny"
      })
    );

  const deleteUpdate = packageId => {
    dispatch(deletePackage(packageId));

    if (selectedPackage.id === packageId) {
      dispatch(findPackageSuccess({}));
      goBack();
    }
  };

  const addPackageUpdate = () =>
    dispatch(
      openModalService({
        children: <PackageModalForm parentPackage={selectedPackage} />,
        size: "tiny"
      })
    );

  const packageUpdates = Object.prototype.hasOwnProperty.call(
    selectedPackage,
    "availableUpdates"
  )
    ? selectedPackage.availableUpdates
    : [];

  const options = [
    {
      key: 1,
      text: "Edit Package",
      value: 1,
      visible: true,
      onSelect: editPackage
    },
    {
      key: 2,
      text: "Assign Package",
      value: 2,
      visible: true,
      onSelect: bulkAssignPackage
    },
    {
      key: 3,
      text: "UnAssign Package",
      value: 3,
      visible: true,
      onSelect: bulkDetachPackage
    },
    {
      key: 4,
      text: "Delete Package",
      value: 4,
      visible: true,
      onSelect: () => deleteUpdate(selectedPackage.id)
    }
  ];

  return (
    <React.Fragment>
      {isFindingPackage ? <Loader /> : null}
      <Grid.Row padded>
        <Menu secondary>
          <Menu.Item style={{ width: "40px" }}>
            <Button
              icon
              basic
              size="tiny"
              color="grey"
              onClick={goBack}
              style={{ fontSize: "12px" }}
            >
              <Icon name="arrow left" />
            </Button>
          </Menu.Item>
          <Menu.Menu position="right" style={{ paddingRight: "13px" }}>
            <Button
              basic
              size="tiny"
              color="grey"
              as={Menu.Item}
              style={{ width: "auto" }}
              onClick={addPackageUpdate}
            >
              Add Update
            </Button>
            {/* <Button
              basic
              size="tiny"
              color="grey"
              as={Menu.Item}
              style={{ width: "auto" }}
              onClick={() => editPackage(selectedPackage)}
            >
              Edit Package
            </Button> */}
            <Dropdown
              item
              text=" Manage "
              as={Button}
              basic
              size="tiny"
              color="grey"
              style={{ width: "auto" }}
            >
              <Dropdown.Menu>
                {/*<Dropdown.Header>Customer</Dropdown.Header>*/}
                {options
                  .filter(x => x.visible)
                  .map(option => (
                    <Dropdown.Item
                      key={option.key}
                      value={option.value}
                      onClick={() => option.onSelect()}
                    >
                      {option.text}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </Grid.Row>
      <Grid.Row>
        <Menu secondary>
          <Menu.Item>
            <Header as="h3">
              {selectedPackage.fileName}
              <Header.Subheader style={{ marginTop: "2px" }}>
                <b>Version:</b>{" "}
                <span style={{ marginLeft: "10px" }}>
                  {selectedPackage.version}
                </span>
              </Header.Subheader>
              <Header.Subheader style={{ marginTop: "2px" }}>
                <b>Platform:</b>{" "}
                <span style={{ marginLeft: "10px" }}>
                  {selectedPackage.platform ? "x64" : "x86"}
                </span>
              </Header.Subheader>
              <Header.Subheader style={{ marginTop: "2px" }}>
                <b>URL:</b>
                <span style={{ marginLeft: "10px" }}>
                  <a
                    href={selectedPackage.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {selectedPackage.url}
                  </a>
                </span>
              </Header.Subheader>
              <Header.Subheader style={{ marginTop: "2px" }}>
                <b>Install Arguments:</b>{" "}
                <span style={{ marginLeft: "10px" }}>
                  {selectedPackage.installArgs}
                </span>
              </Header.Subheader>
              <Header.Subheader style={{ marginTop: "2px" }}>
                <b>Uninstall Arguments:</b>{" "}
                <span style={{ marginLeft: "10px" }}>
                  {selectedPackage.uninstallArgs}
                </span>
              </Header.Subheader>
            </Header>
          </Menu.Item>
        </Menu>
      </Grid.Row>
      <Grid.Row>
        <br />
        <br />
        <Grid.Column style={{ paddingRight: "13px", paddingLeft: "13px" }}>
          <CustomScrollbars
            className=""
            style={{
              height:
                width >= 1200 ? "calc(100vh - 404px)" : "calc(100vh - 424px)"
            }}
          >
            <Table selectable padded basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="5">
                    Available Updates
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Package Name</Table.HeaderCell>
                  <Table.HeaderCell>Version</Table.HeaderCell>
                  <Table.HeaderCell>Platform</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {packageUpdates.map(_package => (
                  <PackageItem
                    key={_package.id}
                    _package={_package}
                    onDeleteItem={_package => deleteUpdate(_package)}
                    user={user}
                    // handleInstall={this.handleInstall}
                    canViewUpdates={false}
                    onEditItem={_package => editPackage(_package)}
                  />
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="5">
                    {packageUpdates.length} Updates
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </CustomScrollbars>
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};
PackageItemDetails.propTypes = {
  setShowMenu: PropTypes.func.isRequired
};

export default PackageItemDetails;
