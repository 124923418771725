import React, { useState, useEffect } from "react";
import { Form, Button } from "semantic-ui-react";
import PropTypes from "prop-types";

const InstallationForm = ({
  selectedInstallationItem,
  disableEdit,
  onSubmitForm
}) => {
  const [installationItem, setInstallationItem] = useState({
    computerName: "",
    computerDesc: "",
    ipAddress: ""
  });
  const handleInputChange = event => {
    event.persist();
    const { name, value } = event.target;
    setInstallationItem(installationItem => ({
      ...installationItem,
      [name]: value
    }));
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    onSubmitForm(installationItem);
  };

  useEffect(() => {
    setInstallationItem({ ...selectedInstallationItem });
  }, [selectedInstallationItem]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Input
        fluid
        label="InstallationName"
        name="installationName"
        placeholder="MAC Address"
        onChange={handleInputChange}
        value={
          installationItem.installationName
            ? installationItem.installationName
            : ""
        }
        readOnly={disableEdit}
      />

      <Form.Input
        fluid
        label="InstallationType"
        name="installationType"
        placeholder="installationType"
        onChange={handleInputChange}
        value={
          installationItem.installationType
            ? installationItem.installationType
            : ""
        }
        readOnly={disableEdit}
      />

      <Form.Input
        fluid
        label="InstallCmd"
        name="installCmd"
        placeholder="installCmd"
        onChange={handleInputChange}
        value={installationItem.installCmd ? installationItem.installCmd : ""}
        readOnly={disableEdit}
      />

      <Form.Input
        fluid
        label="RepairCmd"
        name="repairCmd"
        placeholder="repairCmd"
        onChange={handleInputChange}
        value={installationItem.repairCmd ? installationItem.repairCmd : ""}
        readOnly={disableEdit}
      />

      <Form.Input
        fluid
        label="SourceDir"
        name="sourceDir"
        placeholder="sourceDir"
        onChange={handleInputChange}
        value={installationItem.sourceDir ? installationItem.sourceDir : ""}
        readOnly={disableEdit}
      />
      <Form.Input
        fluid
        label="UnistallCmd"
        name="unistallCmd"
        placeholder="unistallCmd"
        onChange={handleInputChange}
        value={installationItem.unistallCmd ? installationItem.unistallCmd : ""}
        readOnly={disableEdit}
      />

      {!disableEdit && <Button type="submit">Update</Button>}
    </Form>
  );
};

InstallationForm.propTypes = {
  selectedInstallationItem: PropTypes.object.isRequired,
  disableEdit: PropTypes.bool.isRequired,
  onSubmitForm: PropTypes.func.isRequired
};

export default InstallationForm;
