/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from "react-router-redux";

import { Image, Header } from "semantic-ui-react";
import userManager from "../../../util/userManager";

class CallbackPage extends React.Component {
  get isAdmin() {
    return (
      this.props.user.profile.role.includes("Admin") ||
      this.props.user.profile.role.includes("SuperAdmin") ||
      this.props.user.profile.role.includes("Normal")
    );
  }

  get isCustomer() {
    return this.props.user.profile.role.includes("Customer");
  }
  render() {
    // just redirect to '/' in both cases
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={user => {
          // console.log(user);
          const canAccessCustomer =
            user.profile["role"].includes("Customer") ||
            user.profile["role"].includes("Admin") ||
            user.profile["role"].includes("SuperAdmin") ||
            user.profile["role"].includes("Normal");

          if (
            canAccessCustomer &&
            !user.profile["role"].includes("APIClient")
          ) {
            this.props.dispatch(push("/store/customers", {}));
          } else {
            this.props.dispatch(push(`/store/profile/${user.profile.sub}`));
          }
        }}
        // eslint-disable-next-line no-unused-vars
        errorCallback={error => {
          this.props.dispatch(push("/signin", {}));
          // eslint-disable-next-line no-console
          console.error(error);
        }}
      >
        <div className="app-module-login">
          <div className="login-container">
            <div className="login-header">
              <Image src={require("../../../assets/esd.png")} />
            </div>
            <div className="login-form">
              <br />
              <Header as="h4">
                <Header.Content>
                  <Header.Subheader>Status: </Header.Subheader>
                  Signing in .....
                </Header.Content>
              </Header>
            </div>
          </div>
        </div>
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);
