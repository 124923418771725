import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, Button, Label } from "semantic-ui-react";

const LicenseItem = ({
  license,
  removeLicense,
  downloadLicense,
  setActiveLicense,
  isFetchingLicense,
  isRemovingLicense,
  isActivatingLicense
}) => {
  const [selectedLicenseId, SetSelectedLicenseId] = useState(undefined);
  const [selectedLicenseIdForDelete, SetSelectedLicenseIdForDelete] = useState(
    undefined
  );
  const [selectedLicenseIdForDownload, SelectedLicenseIdForDownload] = useState(
    undefined
  );

  useEffect(() => {
    if (!isFetchingLicense) {
      SelectedLicenseIdForDownload(undefined);
    }
    if (!isActivatingLicense) {
      SetSelectedLicenseId(undefined);
    }

    if (!isRemovingLicense) {
      SetSelectedLicenseIdForDelete(undefined);
    }
    return () => {};
  }, [isActivatingLicense, isFetchingLicense, isRemovingLicense]);

  const handleSetActiveLicense = useCallback(
    id => {
      SetSelectedLicenseId(id);
      setActiveLicense(id);
    },
    [SetSelectedLicenseId, setActiveLicense]
  );

  const handleDownloadLicense = useCallback(
    id => {
      SelectedLicenseIdForDownload(id);
      downloadLicense(id);
    },

    [SetSelectedLicenseId, downloadLicense]
  );

  const handleDeleteLicense = useCallback(
    id => {
      SetSelectedLicenseIdForDelete(id);
      removeLicense(id);
    },

    [SetSelectedLicenseIdForDelete, removeLicense]
  );

  return (
    <Table.Row className="tbl-pad">
      {/*<Table.Cell>{license.id}</Table.Cell>*/}
      <Table.Cell>{license.numberOfClients}</Table.Cell>
      <Table.Cell>
        <Label color={license.licenseType ? "teal" : "olive"}>
          {license.licenseType ? "Trial" : "Live"}
        </Label>
      </Table.Cell>
      <Table.Cell>
        {moment(license.created).format("DD/MM/YYYY, HH:mm:a")}
      </Table.Cell>
      <Table.Cell>
        {moment(license.expirationDate).format("DD/MM/YYYY, HH:mm:a")}
      </Table.Cell>

      <Table.Cell>
        {license.isActive && <Label color="green">Active</Label>}
        {moment(license.expirationDate).diff(Date.now()) <= 0 && (
          <Label color="red">Expired</Label>
        )}
      </Table.Cell>

      <Table.Cell textAlign="right">
        <Button.Group size="tiny">
          {!license.isActive &&
            moment(license.expirationDate).diff(Date.now()) > 0 && (
              <Button
                onClick={() => handleSetActiveLicense(license.id)}
                basic
                loading={
                  selectedLicenseId === license.id && isActivatingLicense
                }
                color="blue"
                size={"tiny"}
              >
                Set Active
              </Button>
            )}
          <Button
            loading={
              selectedLicenseIdForDownload === license.id && isFetchingLicense
            }
            onClick={() => handleDownloadLicense(license.id)}
            basic
            size={"tiny"}
            color="green"
          >
            Download
          </Button>
          <Button
            loading={
              selectedLicenseIdForDelete === license.id && isRemovingLicense
            }
            onClick={() => handleDeleteLicense(license.id)}
            basic
            size={"tiny"}
            color="red"
          >
            Remove
          </Button>
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

LicenseItem.propTypes = {
  license: PropTypes.object.isRequired,
  downloadLicense: PropTypes.func.isRequired,
  removeLicense: PropTypes.func.isRequired,
  isRemovingLicense: PropTypes.bool,
  setActiveLicense: PropTypes.func,
  isCustomer: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isFetchingLicense: PropTypes.bool,
  isActivatingLicense: PropTypes.bool
};

export default LicenseItem;
