import React from "react";
import { Loader, Dimmer } from "semantic-ui-react";

// eslint-disable-next-line react/prop-types
const LoaderPlaceholder = ({ custumText = "Loading" }) => (
  <Dimmer active inverted>
    <Loader content={custumText} />
  </Dimmer>
);

export default LoaderPlaceholder;
