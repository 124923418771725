/* eslint-disable react/prop-types */
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Table, Grid } from "semantic-ui-react";
// eslint-disable-next-line no-unused-vars

import { connect } from "react-redux";

import CustomScrollbars from "../../../../../util/CustomScrollbars";
import Loader from "../../../../../components/Loader";
import PackageItem from "./PackageItem";
import {
  fetchCustomerPrvPackages,
  onRemoveCustomerPUpload,
  onDownloadFIle,
  openModalService
} from "../../../../../actions";
import Pagination from "../../../../../components/Pagination";
import RemoteInstallForm from "../../components/RemoteInstallForm";

const pageLimit = 13;
class PrvPackages extends React.Component {
  componentDidMount() {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id")
    ) {
      this.props.fetchCustomerPrvPackages({
        id: this.props.selectedCustomer.id,
        Limit: pageLimit
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id") &&
      prevProps.selectedCustomer !== this.props.selectedCustomer
    ) {
      this.props.fetchCustomerPrvPackages({
        id: this.props.selectedCustomer.id,
        Limit: pageLimit
      });
    }
  }

  componentWillUnmount() {}

  onDeleteItem = _package =>
    this.props.onRemoveCustomerPUpload(this.props.selectedCustomer, _package);

  onDownloadItem = file => this.props.onDownloadFIle(file);

  handleInstall = packageId =>
    this.props.openModalService({
      children: (
        <RemoteInstallForm
          installationItem={packageId}
          type="private"
          userId={this.props.selectedCustomer.id}
        />
      ),
      size: "tiny"
    });

  get isAdmin() {
    return (
      this.props.user.profile.role.includes("Admin") ||
      this.props.user.profile.role.includes("SuperAdmin") ||
      this.props.user.profile.role.includes("Normal")
    );
  }

  get isCustomer() {
    return this.props.user.profile.role.includes("Customer");
  }

  render() {
    const { prvPackages, isFetchingCustomerPrvPackages } = this.props;

    return (
      <Grid.Row>
        <Grid.Column>
          {isFetchingCustomerPrvPackages ? <Loader /> : null}
          <CustomScrollbars
            className=""
            style={{
              height: "calc(100vh - 348px)"
            }}
          >
            <Table selectable padded basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Package Name</Table.HeaderCell>
                  <Table.HeaderCell>size</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {prvPackages.items.map(_package => (
                  <PackageItem
                    key={_package.id}
                    _package={_package}
                    isCustomer={this.isCustomer}
                    isAdmin={this.isAdmin}
                    onDeleteItem={this.onDeleteItem}
                    handleInstall={this.handleInstall}
                    onDownloadItem={this.onDownloadItem}
                  />
                ))}
              </Table.Body>
            </Table>
          </CustomScrollbars>
          <Pagination
            totalPages={prvPackages.totalPages}
            limit={pageLimit}
            handlePaginationChange={filters =>
              this.props.fetchCustomerPrvPackages({
                id:
                  this.props.selectedCustomer &&
                  Object.prototype.hasOwnProperty.call(
                    this.props.selectedCustomer,
                    "id"
                  ) &&
                  this.props.selectedCustomer.id,
                ...filters
              })
            }
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

const mapStateToProps = ({ settings, customerData, oidc }) => {
  const { width } = settings;
  const { user } = oidc;
  const {
    isFetchingCustomerPrvPackages,
    selectedCustomer,
    prvPackages
  } = customerData;
  return {
    width,
    user,
    isFetchingCustomerPrvPackages,
    selectedCustomer,
    prvPackages
  };
};
export default connect(mapStateToProps, {
  fetchCustomerPrvPackages,
  onRemoveCustomerPUpload,
  onDownloadFIle,
  openModalService
})(PrvPackages);
