import queryString from "query-string";
import { API_URL_PACKAGES } from "../constants/Api";
import API from "./api";

export const getPackages = (token, filters) => {
  const params = queryString.stringify(filters);
  return {
    url: API_URL_PACKAGES + `?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const findPackage = (token, packageId) => {
  return {
    url: API_URL_PACKAGES + `${packageId}`,
    opt: API.requestOptions("GET", token)
  };
};

export const createPackage = (token, packageData) => {
  const body = JSON.stringify(packageData);
  return {
    url: API_URL_PACKAGES,
    opt: API.requestOptions("POST", token, null, { body })
  };
};

export const updatePackage = (token, packageData) => {
  const body = JSON.stringify(packageData);
  return {
    url: API_URL_PACKAGES + packageData.id,
    opt: API.requestOptions("PUT", token, null, { body })
  };
};

export const deletePackage = (token, id) => {
  return {
    url: API_URL_PACKAGES + id,
    opt: API.requestOptions("DELETE", token)
  };
};

export default {
  findPackage,
  getPackages,
  createPackage,
  updatePackage,
  deletePackage
};
