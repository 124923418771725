/* eslint-disable react/prop-types */
import React from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment";

const SocketItem = ({ session, onSelectItem, selectedSocketItem }) => {
  const end = moment(session.sessionEnd);
  const start = moment(session.sessionStart);
  return (
    <List.Item
      as="a"
      active={
        selectedSocketItem
          ? selectedSocketItem.connectionID === session.connectionID
          : false
      }
      onClick={() => onSelectItem(session)}
    >
      <List.Content>
        <List.Header as="h4">{session.connectionID}</List.Header>
        <List.Description>
          Duration: {end.diff(start, "minutes")} min
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

SocketItem.propTypes = {
  session: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired
};

const SocketList = ({ sessionData, onSelectItem, selectedSocketItem }) => (
  <List animated selection divided verticalAlign="middle">
    <List.Header as="h4">
      Sessions ({(sessionData && sessionData.length) || 0})
    </List.Header>
    {sessionData &&
      sessionData.map(session => {
        return (
          <SocketItem
            key={session.connectionID}
            session={session}
            onSelectItem={onSelectItem}
            selectedSocketItem={selectedSocketItem}
          />
        );
      })}
  </List>
);

SocketList.propTypes = {
  sessionData: PropTypes.array,
  onSelectItem: PropTypes.func
};

export default SocketList;
