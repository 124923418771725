/* eslint-disable react/prop-types */
import React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
export const ITEMS_NAME = [
  ["Software Packages", "/store/packages/pub"],
  ["ESD Packages", "/store/packages/prv"]
];

export const TopTabBar = props => {
  const { pathname } = props;

  return (
    <Menu secondary stackable fluid>
      <Menu.Item
        active={pathname === ITEMS_NAME[0][1]}
        content={ITEMS_NAME[0][0]}
        as={Link}
        to={ITEMS_NAME[0][1]}
        //
      />
      <Menu.Item
        content={ITEMS_NAME[1][0]}
        active={pathname === ITEMS_NAME[1][1]}
        as={Link}
        to={ITEMS_NAME[1][1]}
      />
    </Menu>
  );
};

export default TopTabBar;
