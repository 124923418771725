import {
  CREATE_ORGN,
  CREATE_ORGN_FAILURE,
  CREATE_ORGN_SUCCESS,
  FETCH_ORGN,
  FETCH_ORGN_FAILURE,
  FETCH_ORGN_SUCCESS,
  UPDATE_ORGN,
  UPDATE_ORGN_FAILURE,
  UPDATE_ORGN_SUCCESS,
  DELETE_ORGN,
  DELETE_ORGN_FAILURE,
  DELETE_ORGN_SUCCESS,
  FETCH_ORGN_USERS,
  FETCH_ORGN_USERS_SUCCESS,
  FETCH_ORGN_USERS_FAILURE,
  GET_ORGN,
  GET_ORGN_FAILURE,
  GET_ORGN_SUCCESS
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  orgns: {
    items: [],
    page: 1,
    nextPage: null,
    previousPage: null,
    totalPages: null
  },

  organisation: {},

  users: {
    items: [],
    page: 1,
    nextPage: null,
    previousPage: null,
    totalPages: null
  },
  isProcessingOrgnRequest: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ORGN: {
      return {
        ...state,
        isProcessingOrgnRequest: true
      };
    }
    case GET_ORGN_FAILURE: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }
    case GET_ORGN_SUCCESS: {
      return {
        ...state,
        organisation: action.payload,
        isProcessingOrgnRequest: false
      };
    }

    case CREATE_ORGN: {
      return {
        ...state,
        isProcessingOrgnRequest: true
      };
    }
    case CREATE_ORGN_FAILURE: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }
    case CREATE_ORGN_SUCCESS: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }

    case UPDATE_ORGN: {
      return {
        ...state,
        isProcessingOrgnRequest: true
      };
    }
    case UPDATE_ORGN_FAILURE: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }
    case UPDATE_ORGN_SUCCESS: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }

    case DELETE_ORGN: {
      return {
        ...state,
        isProcessingOrgnRequest: true
      };
    }
    case DELETE_ORGN_FAILURE: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }
    case DELETE_ORGN_SUCCESS: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }
    case FETCH_ORGN: {
      return {
        ...state,
        isProcessingOrgnRequest: true
      };
    }
    case FETCH_ORGN_FAILURE: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }
    case FETCH_ORGN_SUCCESS: {
      return {
        ...state,
        isProcessingOrgnRequest: false,
        orgns: action.payload
      };
    }

    case FETCH_ORGN_USERS: {
      return {
        ...state,
        isProcessingOrgnRequest: true
      };
    }

    case FETCH_ORGN_USERS_SUCCESS: {
      return {
        ...state,
        isProcessingOrgnRequest: false,
        users: action.payload
      };
    }

    case FETCH_ORGN_USERS_FAILURE: {
      return {
        ...state,
        isProcessingOrgnRequest: false
      };
    }

    default:
      return state;
  }
};
