import React from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { connect } from "react-redux";

class ModalService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        size={this.props.size}
        onClose={this.props.onClose}
      >
        {this.props.children}
      </Modal>
    );
  }
}

ModalService.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
  size: PropTypes.string
};

const mapStateToProps = store => {
  const { modalService } = store;

  return {
    isOpen: modalService.isOpen,
    onClose: modalService.onClose,
    children: modalService.children,
    size: modalService.size
  };
};

export default connect(mapStateToProps)(ModalService);
