/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Grid, Button, Segment, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import ActionList from "../../components/ComputerActions";
import InstallationForm from "../../components/InstallationForm";
import InstallationReport from "../../components/InstallationReport";
import ComputerList from "../../components/ComputerList";
import {
  openModalService,
  closeModalService,
  fetchComputerItems,
  sendEsdPackageInstallCmd,
  resetLogs,
  sendEsdReportCommand,
  sendInstallationItemCommandToManager
} from "../../../../../actions";

const actions = [
  { name: "Remote Install", icon: "" },
  { name: "Remote Unistallation", icon: "" },
  { name: "Remote Repair", icon: "" },
  { name: "Delete Installation Item", icon: "delete" }
];

class InstallationDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disableEdit: true
    };
  }

  componentDidMount() {
    if (this.props.authUser) {
      this.props.fetchComputerItems({
        userId: this.props.authUser.id,
        Limit: 1000
      });
    }
  }
  onSubmitForm = formData => {
    console.log("Form", formData);
  };

  showComputerList = command =>
    this.props.openModalService({
      children: (
        <ComputerList
          command={command}
          computers={this.props.computerItems.items}
          selectedInstallationItem={this.props.selectedInstallationItem}
          closeModalService={this.props.closeModalService}
          sendInstallationCmd={this.props.sendEsdPackageInstallCmd}
        />
      ),
      size: "tiny"
    });

  showInstallationItemReport = () =>
    this.props.openModalService({
      children: (
        <InstallationReport closeModalService={this.props.closeModalService} />
      ),
      size: "small"
    });

  onSelectAction = action => {
    const { selectedInstallationItem } = this.props;
    this.props.resetLogs();
    switch (action) {
      case "Edit Installation Item":
        // this.setState({ disableEdit: false });
        break;

      case "Remote Install":
        this.showComputerList("RemoteInstall");

        break;
      case "Remote Unistallation":
        this.showComputerList("RemoteUninstall");
        break;
      case "Rename Installation Item":
        this.props.sendInstallationItemCommandToManager({
          command: "RenameInstallationItem",
          data: selectedInstallationItem
        });
        break;
      case "Delete Installation Item":
        this.props.sendInstallationItemCommandToManager({
          command: "DeleteInstallationItem",
          data: selectedInstallationItem
        });
        break;
      case "Remote Repair":
        this.showComputerList("RemoteRepair");
        break;
      case "Show Report Status":
        this.showInstallationItemReport();

        break;

      default:
        // statements_def
        break;
    }
  };

  clearLog = () => this.props.resetLogs();

  render() {
    const { disableEdit } = this.state;
    const { selectedInstallationItem, esdLogs } = this.props;
    return (
      <Grid stackable padded divided>
        <Grid.Row>
          <Grid.Column width={11}>
            <InstallationForm
              disableEdit={disableEdit}
              onSubmitForm={this.onSubmitForm}
              selectedInstallationItem={selectedInstallationItem}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <ActionList
              actions={actions}
              onSelectAction={this.onSelectAction}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          {esdLogs.length > 0 && (
            <Grid.Column>
              <Segment basic>
                <Button
                  compact
                  size="small"
                  floated="right"
                  onClick={this.clearLog}
                >
                  Clear
                </Button>
                Event Log <Label circular>{esdLogs.length}</Label>
              </Segment>
              <Segment basic secondary>
                <code>
                  {esdLogs
                    .filter(log => log !== "")
                    .map((e, i) => (
                      <div style={{ padding: "5px" }} key={i}>
                        {e}
                      </div>
                    ))}
                </code>
              </Segment>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = ({ esdActivityData, auth }) => {
  const { authUser } = auth;
  const {
    selectedInstallationItem,
    computerItems,
    esdLogs,
    installationItemReport
  } = esdActivityData;
  return {
    selectedInstallationItem,
    authUser,
    computerItems,
    esdLogs,
    installationItemReport
  };
};
export default connect(mapStateToProps, {
  openModalService,
  closeModalService,
  fetchComputerItems,
  sendEsdPackageInstallCmd,
  resetLogs,
  sendEsdReportCommand,
  sendInstallationItemCommandToManager
})(InstallationDetail);
