/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Button, Form, Image, Message, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { push } from "connected-react-router";
// import { Formik } from "formik";
// import * as Yup from "yup";
import userManager from "../../../util/userManager";

class Login extends Component {
  loginRedirectRequest = async () => {
    await Promise.resolve(userManager.signinRedirect())
      .catch(error => {
       // console.log(error);
      })
      .then(() =>{});
  };
  render() {
    return (
      <div className="app-module-login">
        <div className="login-container">
          <div className="login-header">
            <Image src={require("../../../assets/esd.png")} />
          </div>
          <div className="login-form">
            <Header as="h4">
              {/* <Icon name="settings" /> */}
              <Header.Content>
                <Header.Subheader>Cloud Access</Header.Subheader>
                To Package Store
              </Header.Content>
            </Header>
            <br />

            <Button
              size="medium"
              onClick={this.loginRedirectRequest}
              fluid
              type="submit"
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ router, oidc, socketService }) => {
  const { location } = router;
  const { initURL, user } = oidc;

  const { channelStatus } = socketService;
  return { location, initURL, user, channelStatus };
};

export default connect(mapStateToProps, { push })(Login);
