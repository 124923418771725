/* eslint-disable react/prop-types */
import React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const TopTabBar = props => {
  const { pathname, ITEMS_NAME } = props;

  return (
    <Menu secondary stackable fluid pointing>
      {ITEMS_NAME.map(item => (
        <Menu.Item
          // name="Bag Levels"
          key={item[1]}
          style={{
            textAlign: "center",
            width: 200,
            alignContent: "center",
            justifyContent: "center"
          }}
          active={pathname === item[1]}
          content={item[0]}
          as={Link}
          to={item[1]}
          //
        />
      ))}
    </Menu>
  );
};

export default TopTabBar;
