/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
// import { setAtiveMenu } from "../../../../../actions/Setting";
import { connect } from "react-redux";

import {
  Button,
  Grid,
  Menu,
  Item,
  GridColumn,
  Divider,
  Header,
  Message
} from "semantic-ui-react";
import { push } from "connected-react-router";
import UserList from "./UserList";
import AddUser from "./AddUser";
import SearchBox from "../../../components/SearchBox";
import Pagination from "../../../components/Pagination";
import Loader from "../../../components/Loader";
import {
  fetchUsers,
  saveUser,
  updateUser,
  deleteUser,
  openModalService,
  closeModalService,
  setAtiveMenu,
  sendResetLink,
  onUserSelect
} from "../../../actions";

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      activeItem: "home"
    };
  }

  componentDidMount() {
    this.props.setAtiveMenu(this.props.activeMenu);
    this.props.fetchUsers({ Limit: 5 });
    this.props.onUserSelect({});
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  onSearch = search => {
    this.setState({ search }, () => {
      this.props.fetchUsers({ Term: search, Limit: 5 });
    });
  };
  onEdit = user => {
    this.props.onUserSelect(user);
    this.handleAddUser();
  };
  onDelete = user => this.props.deleteUser(user);

  navigateToClientDetail = user => {
    this.props.onUserSelect(user);
    // this.props.push(`/store/profile/${user.id}`);
  };

  sendResetLink = data => this.props.sendResetLink(data);

  handleAddUser = () => {
    this.props.openModalService({
      children: <AddUser onClose={this.handleModalClose} />,
      size: "tiny"
    });
  };

  handleModalClose = e => {
    e.preventDefault();
    this.props.closeModalService();
    this.props.onUserSelect({});
  };

  onAddUser = () => {
    this.handleAddUser();
  };

  render() {
    const { userList, width, updateUser } = this.props;
    const { activeItem } = this.state;
    return (
      <div className="app-wrapper">
        <Grid
          padded
          centered
          style={{
            border: "1px solid rgba(0,0,0,.1)",

            background: "#fff"
          }}
        >
          <Grid.Row>
            <Grid.Column width={3}>
              <Button basic color="blue" fluid onClick={this.onAddUser}>
                Add Users
              </Button>
            </Grid.Column>
            <Grid.Column width={7} />
            <Grid.Column width={4}>
              <SearchBox
                placeholder="search by name"
                onChange={evt => this.onSearch(evt.target.value)}
                value={this.state.search}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Divider />
              <Header
                content="Users list"
                subheader="Admin users listed with respective roles"
              />
              <Divider />
              <Message
                error
                hidden={!this.props.auth.errorOccurred}
                list={this.props.auth.alertMessage["reset_error"]}
              />

              <Message
                success
                onDismiss={(e, data) => {
                  e.target.hidden = true;
                }}
                hidden={
                  !this.props.auth.errorOccurred &&
                  this.props.auth.alertMessage === ""
                }
                content={this.props.auth.alertMessage}
              />
              {this.props.isProcessingRequest ||
              this.props.auth.isProcessingRequest ? (
                <Loader />
              ) : null}
              <UserList
                users={userList.items}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                updateUser={updateUser}
                navigateToClientDetail={this.navigateToClientDetail}
                sendLink={this.sendResetLink}
              />
              <Divider />
              <Pagination
                totalPages={
                  (userList &&
                    Object.prototype.hasOwnProperty.call(userList, "items") &&
                    userList.totalPages) ||
                  1
                }
                limit={5}
                handlePaginationChange={filters => {
                  this.props.fetchUsers(filters);
                }}
              />

              <br />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, router, UserData, auth }) => {
  const { width } = settings;
  const { location } = router;
  const { userList, isProcessingRequest } = UserData;

  return { width, location, userList, isProcessingRequest, auth };
};

export default connect(mapStateToProps, {
  fetchUsers,

  saveUser,
  updateUser,
  deleteUser,
  onUserSelect,

  openModalService,
  closeModalService,
  sendResetLink,
  setAtiveMenu,
  push
})(Users);
