/* eslint-disable no-console */
export const PRODUCTION = "PRODUCTION";
export const DEVELOPMENT = "DEVELOPMENT";
export const STAGING = "STAGING";

export const API_URL_SLOTS = {
  DEVELOPMENT: {
    API: "https://192.168.1.36:5003",
    SERVER: "https://192.168.1.36:5005/api"
  },
  STAGING: {
    API: "https://esdtest.easysoftwaredeployment.nl/api",
    SERVER: "https://esdtest.easysoftwaredeployment.nl/oauth2/api"
  },
  PRODUCTION: {
    API: "https://portal.easysoftwaredeployment.nl/api",
    SERVER: "https://portal.easysoftwaredeployment.nl/oauth2/api"
  }
};

export const FRONTEND_HOSTS = {
  DEVELOPMENT: "192.168.1.36",
  STAGING: "esdtest.easysoftwaredeployment.nl",
  PRODUCTION: "portal.easysoftwaredeployment.nl"
};

export const API_MODE =
  window.location.host.replace("www.", "") === FRONTEND_HOSTS.PRODUCTION
    ? PRODUCTION
    : window.location.host.replace("www.", "") === FRONTEND_HOSTS.STAGING
    ? STAGING
    : DEVELOPMENT;

//console.log(`Powered by "${API_MODE}" API`);

export const API_URL = API_URL_SLOTS[API_MODE];
//console.log(`API url "${API_URL.API}"`);
//console.log(`API IDS4 url "${API_URL.SERVER}"`);

export const API_URL_AUTH = `${API_URL.SERVER}/auth/`;
export const API_URL_USERS = `${API_URL.SERVER}/user/`;
export const API_URL_USERS_ID_SERVER = `${API_URL.SERVER}/user/`;
export const API_URL_PACKAGES = `${API_URL.API}/software-package/`;
export const API_URL_PUPLOAD = `${API_URL.API}/esd-package/`;
export const API_URL_CREATE_USER = `${API_URL.SERVER}/account/`;
export const API_URL_CLIENT = `${API_URL.SERVER}/client/`;
export const API_URL_ESD_ACTIVITY = `${API_URL.API}/esd_activity/`;
export const API_URL_INSTALL_ITEMS = `${API_URL.API}/installation-item/`;
export const API_URL_COMPUTER_ITEMS = `${API_URL.API}/computer/`;
export const API_URL_ORGN = `${API_URL.API}/organisation/`;

export default {
  API_URL,
  API_MODE,
  API_URL_AUTH,
  API_URL_PACKAGES,
  API_URL_PUPLOAD,
  API_URL_SLOTS,
  API_URL_USERS,
  API_URL_CREATE_USER,
  API_URL_ESD_ACTIVITY,
  API_URL_COMPUTER_ITEMS,
  API_URL_INSTALL_ITEMS,
  API_URL_ORGN,
  API_URL_CLIENT,
  API_URL_USERS_ID_SERVER
};
