import {
  FETCH_ALL_CUSTOMER_SUCCESS,
  FILTER_CUSTOMERS,
  ON_SELECT_CUSTOMER,
  SAVE_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_PACKAGE_SUCCESS,
  ADD_CUSTOMER_PUPLOAD_SUCCESS,
  REMOVE_CUSTOMER_PUPLOAD_SUCCESS,
  REMOVE_CUSTOMER_PACKAGE_SUCCESS,
  REMOVE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_SUCCESS,
  FETCH_ALL_CUSTOMER,
  FETCH_CUSTOMER_PUB_PACKAGES,
  FETCH_CUSTOMER_PUB_PACKAGES_SUCCESS,
  FETCH_CUSTOMER_PRV_PACKAGES,
  FETCH_CUSTOMER_PRV_PACKAGES_SUCCESS,
  UPDATE_SEARCH_CUSTOMER,
  GET_USER_BY_ID_SUCCESS,
  GET_LICENSE,
  GET_LICENSE_SUCCESS,
  GET_LICENSE_FAILURE,
  SET_ACTIVE_LICENSE,
  SET_ACTIVE_LICENSE_SUCCESS,
  SET_ACTIVE_LICENSE_FAILURE,
  GET_LICENSES,
  GET_LICENSES_SUCCESS,
  GET_LICENSES_FAILURE,
  SEND_INVITE,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_FAILED,
  REMOVE_LICENSE,
  REMOVE_LICENSE_SUCCESS,
  REMOVE_LICENSE_FAILURE
} from "../constants/ActionTypes";

const INIT_STATE = {
  userNotFound: "No Customer found",
  customerList: [],
  selectedCustomer: null,
  searchText: "",
  customers: {
    items: [],
    page: 1,
    nextPage: null,
    previousPage: null,
    totalPages: null
  },
  pubPackages: {
    items: [],
    page: 1,
    nextPage: null,
    previousPage: null,
    totalPages: null
  },
  prvPackages: {
    items: [],
    page: 1,
    nextPage: null,
    previousPage: null,
    totalPages: null
  },
  isFetchingCustomers: false,
  isFetchingCustomerPrvPackages: false,
  isFetchingCustomerPubPackages: false,

  license: {},
  licenses: {
    items: [],
    page: 1,
    nextPage: null,
    previousPage: null,
    totalPages: null
  },
  isFetchingLicenses: false,
  isActivatingLicense: false,
  isFetchingLicense: false,
  isSendingInvite: false,
  isRemovingLicense: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_INVITE: {
      return {
        ...state,
        isSendingInvite: true
      };
    }
    case SEND_INVITE_SUCCESS: {
      return {
        ...state,
        isSendingInvite: false
      };
    }

    case SEND_INVITE_FAILED: {
      return {
        ...state,
        isSendingInvite: false
      };
    }

    case REMOVE_LICENSE: {
      return {
        ...state,
        isRemovingLicense: true
      };
    }
    case REMOVE_LICENSE_SUCCESS: {
      return {
        ...state,
        isRemovingLicense: false
        // license: action.payload
      };
    }

    case REMOVE_LICENSE_FAILURE: {
      return {
        ...state,
        isRemovingLicense: false
      };
    }

    case GET_LICENSE: {
      return {
        ...state,
        isFetchingLicense: true
      };
    }
    case GET_LICENSE_SUCCESS: {
      return {
        ...state,
        isFetchingLicense: false
        // license: action.payload
      };
    }

    case GET_LICENSE_FAILURE: {
      return {
        ...state,
        isFetchingLicense: false
      };
    }

    case SET_ACTIVE_LICENSE: {
      return {
        ...state,
        isActivatingLicense: true
      };
    }

    case SET_ACTIVE_LICENSE_SUCCESS: {
      return {
        ...state,
        isActivatingLicense: false,
        license: action.payload
      };
    }

    case SET_ACTIVE_LICENSE_FAILURE: {
      return {
        ...state,
        isActivatingLicense: false
      };
    }

    case GET_LICENSES: {
      return {
        ...state,
        isFetchingLicenses: true
      };
    }

    case GET_LICENSES_SUCCESS: {
      return {
        ...state,
        isFetchingLicenses: false,
        licenses: action.payload
      };
    }

    case GET_LICENSES_FAILURE: {
      return {
        ...state,
        isFetchingLicenses: false
      };
    }

    case UPDATE_SEARCH_CUSTOMER: {
      return {
        ...state,
        searchText: action.payload
      };
    }

    case GET_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedCustomer: action.payload
      };
    }
    case FETCH_CUSTOMER_PRV_PACKAGES: {
      return {
        ...state,
        isFetchingCustomerPrvPackages: true
      };
    }

    case FETCH_CUSTOMER_PRV_PACKAGES_SUCCESS: {
      return {
        ...state,
        prvPackages: action.payload,
        isFetchingCustomerPrvPackages: false
      };
    }

    case FETCH_CUSTOMER_PUB_PACKAGES: {
      return {
        ...state,
        isFetchingCustomerPubPackages: true
      };
    }

    case FETCH_CUSTOMER_PUB_PACKAGES_SUCCESS: {
      return {
        ...state,
        pubPackages: action.payload,
        isFetchingCustomerPubPackages: false
      };
    }

    case UPDATE_CUSTOMER_SUCCESS: {
      // const { customerData, customerId } = action.payload;
      // const customers = state.customers.map(customer =>
      //   customer.id === customerId
      //     ? {
      //         ...customer,
      //         checkSum: customerData.checkSum,
      //         email: customerData.email,
      //         phoneNumber: customerData.phoneNumber,
      //         firstName: customerData.firstName,
      //         lastName: customerData.lastName,
      //         companyName: customerData.companyName
      //       }
      //     : customer
      // );
      return {
        ...state,
        selectedCustomer: action.payload
      };
    }
    case REMOVE_CUSTOMER_SUCCESS: {
      // const customers = state.customers.filter(
      //   customer => customer.id !== action.payload
      // );
      return {
        ...state
        // customerList: customers,
        // customers: customers
      };
    }
    case FILTER_CUSTOMERS: {
      const { customers } = state;
      if (action.payload === "") {
        return {
          ...state,
          customerList: customers
        };
      } else {
        return {
          ...state,
          customerList: customers.filter(
            customer =>
              customer.firstName
                .toLowerCase()
                .indexOf(action.payload.toLowerCase()) > -1 ||
              customer.email
                .toLowerCase()
                .indexOf(action.payload.toLowerCase()) > -1
          )
        };
      }
    }

    case SAVE_CUSTOMER_SUCCESS: {
      // state.customerList.push(action.payload);
      return {
        ...state,
        // customerList: [...state.customers, action.payload],
        // customers: [...state.customers, action.payload],
        message: "Customer Added Successfully"
      };
    }
    case ON_SELECT_CUSTOMER: {
      let selectedCustomer = state.customerList.items.find(
        c => c.id === action.payload
      );
      if (!selectedCustomer) {
        selectedCustomer = state.customerList.items[0];
      }

      return {
        ...state,
        selectedCustomer: selectedCustomer
      };
    }

    case FETCH_ALL_CUSTOMER: {
      return {
        ...state,
        isFetchingCustomers: true
      };
    }

    case FETCH_ALL_CUSTOMER_SUCCESS: {
      let selectedCustomer = state.selectedCustomer;
      if (
        selectedCustomer &&
        Object.prototype.hasOwnProperty.call(selectedCustomer, "id")
      ) {
        const result = action.payload.items.find(
          c => c.id === selectedCustomer.id
        );
        if (!result) {
          selectedCustomer = action.payload.items[0];
        }
      }
      return {
        ...state,
        customerList: action.payload,
        customers: action.payload,
        selectedCustomer: selectedCustomer,
        isFetchingCustomers: false
      };
    }

    case ADD_CUSTOMER_PACKAGE_SUCCESS: {
      let isNew = true;
      const packageList = state.pubPackages.items.map(_package => {
        if (_package.id === action.payload.id) {
          isNew = false;
          return action.payload;
        } else {
          return _package;
        }
      });
      if (isNew) {
        packageList.push(action.payload);
      }
      return {
        ...state,

        pubPackages: { ...state.pubPackages, items: packageList }
      };
    }

    case REMOVE_CUSTOMER_PACKAGE_SUCCESS: {
      const packageList = state.pubPackages.items.filter(
        _package => _package.id !== action.payload
      );
      return {
        ...state,
        pubPackages: packageList
      };
    }

    case ADD_CUSTOMER_PUPLOAD_SUCCESS: {
      let isNew = true;
      let packageList = state.prvPackages.items.map(_package => {
        if (_package.id === action.payload.id) {
          isNew = false;
          return action.payload;
        } else {
          return _package;
        }
      });
      if (isNew) {
        packageList = [...state.prvPackages, action.payload];
      }

      // console.log(packageList);
      return {
        ...state,
        prvPackages: { ...state.prvPackages, items: packageList }
      };
    }

    case REMOVE_CUSTOMER_PUPLOAD_SUCCESS: {
      // const packageList = state.puploadList.filter(
      //   _package => _package.id !== action.payload
      // );
      return {
        ...state,
        prvPackages: state.prvPackages.items.filter(
          _package => _package.id !== action.payload
        )
      };
    }

    default:
      return state;
  }
};
