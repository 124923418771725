import React from "react";
import { Icon, Button } from "semantic-ui-react";

export const SidebarChevronRightBtn = ({ onToggleNav, isNavCollapsed }) => {
  if (!isNavCollapsed) {
    return null;
  }

  return (
    <Button className="ui icon button " onClick={onToggleNav}>
      <Icon name="chevron right" />
    </Button>
  );
};

export default SidebarChevronRightBtn;
