/* eslint-disable no-unused-vars */
import {
  all,
  call,
  put,
  takeEvery,
  take,
  select,
  delay,
  fork,
  takeLatest
} from "redux-saga/effects";
import { saveAs } from "file-saver";
import { buffers, eventChannel, END } from "redux-saga";
import { push } from "connected-react-router";
import {
  fetchPUploadSuccess,
  updatePUploadSuccess,
  deletePUploadSuccess,
  savePUploadSuccess,
  showPUploadMessage,
  downloadPUploadSuccess,
  uploadProgress,
  uploadSuccess,
  uploadFailure
} from "../actions/PUpload";
import {
  FETCH_ALL_PUPLOAD,
  UPDATE_PUPLOAD,
  SAVE_PUPLOAD,
  DELETE_PUPLOAD,
  FETCH_UN_ASSIGENED_PUPLOADS,
  DOWNLOAD_PUPLOAD,
  UPLOAD_REQUEST,
  SIGNOUT_USER
} from "../constants/ActionTypes";
import { errorhandler } from "./error";
import { needRefreshToken } from "../util/checkTokenExpiration";
import { API_URL_PUPLOAD } from "../constants/Api";
import { API, puploadAPI, customersAPI } from "../api";
import Notify from "../actions/Notify";

const downloadFile = async (auth_token, file) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${auth_token}`,
      "Content-Type": "application/json"
    }
  };
  const response = await fetch(
    `${API_URL_PUPLOAD}download?file=${file}`,
    requestOptions
  );
  return response;
};

const savePUpload = async (authToken, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`
    },
    body: formData
  };
  const response = await fetch(`${API_URL_PUPLOAD}`, requestOptions);
  return response;
};

const createUploadFileChannel = (endpoint, file, authToken) => {
  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    const onProgress = e => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = event => {
      emitter({ err: new Error("Upload failed") });
      emitter(END);
    };
    xhr.upload.addEventListener("progress", event => {
      if (event.lengthComputable) {
        const progress = event.loaded / event.total;
        emitter({ progress });
      }
    });
    xhr.upload.addEventListener("error", onFailure);
    xhr.upload.addEventListener("abort", onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };

    xhr.open("POST", endpoint, true);
    xhr.setRequestHeader("Authorization", `Bearer ${authToken}`);
    //xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(file);
    return () => {
      xhr.upload.removeEventListener("progress", onProgress);
      xhr.upload.removeEventListener("error", onFailure);
      xhr.upload.removeEventListener("abort", onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
};

function* uploadRequestWatcherSaga({ payload }) {
  const token = yield select(({ oidc }) => oidc.user.access_token);
  const fileUpload = payload;
  yield call(uploadFileSaga, fileUpload, token);
}

// Upload the specified file
function* uploadFileSaga(file, authToken) {
  const channel = yield call(
    createUploadFileChannel,
    `${API_URL_PUPLOAD}`,
    file,
    authToken
  );
  while (true) {
    const { progress = 0, err, success } = yield take(channel);
    if (err) {
      yield put(uploadFailure(file, err));
      yield put(showPUploadMessage(`File Upload Failed`));
      yield fork(fetchPUploadRequest, { payload: { Limit: 12 } });
      return;
    }
    if (success) {
      yield put(uploadSuccess(file));
      yield put(showPUploadMessage(`File Uploaded Successfully`));
      yield fork(fetchPUploadRequest, { payload: { Limit: 12 } });
      Notify.success("File Uploaded Successfully");
      return;
    }
    yield put(uploadProgress(file, progress));
  }
}

function* fetchPUploadRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedPUploads = yield call(
        API.request,
        puploadAPI.getPUploads(token, payload)
      );
      yield put(fetchPUploadSuccess(fetchedPUploads));
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

export function* fetchUnAssagnedPUploadRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedPUploads = yield call(
        API.request,
        customersAPI.getUnAssignedPUpload(token, payload)
      );
      yield put(fetchPUploadSuccess(fetchedPUploads));
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* savePUploadRequest({ formData }) {
  const token = yield select(({ oidc }) => oidc.user.access_token);
  try {
    yield put(showPUploadMessage(`File Uploading, wait!!`));
    yield call(delay, 100);
    const response = yield call(savePUpload, token, formData);
    if (response.status >= 200 && response.status < 300) {
      // yield put(savePUploadSuccess());
      yield put(showPUploadMessage("File Uploaded Successfully"));
      yield fork(fetchPUploadRequest, { payload: { limit: 12 } });

      Notify.success("File Uploaded Successfully");
    } else {
      throw response;
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* deletePUploadRequest({ packageId }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      yield call(API.request, puploadAPI.deletePUpload(token, packageId));
      // yield put(deletePUploadSuccess(packageId));

      yield put(showPUploadMessage("File delete Successfully"));
      yield fork(fetchPUploadRequest, { payload: { Limit: 12 } });
      Notify.success("File delete Successfully");
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* downloadFileRequest({ file }) {
  const token = yield select(({ oidc }) => oidc.user.access_token);
  try {
    yield put(showPUploadMessage(`Download started, wait!!`));

    const response = yield call(downloadFile, token, file);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.blob();
      saveAs(data, file);
      yield put(showPUploadMessage(`${file} Downloaded Successfully`));
      yield put(downloadPUploadSuccess({ data, file }));
    } else {
      throw response;
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_ALL_PUPLOAD, fetchPUploadRequest),
    // takeLatest(UPDATE_PUPLOAD, updatePUploadsRequest),
    takeLatest(SAVE_PUPLOAD, savePUploadRequest),
    takeLatest(DELETE_PUPLOAD, deletePUploadRequest),
    takeLatest(FETCH_UN_ASSIGENED_PUPLOADS, fetchUnAssagnedPUploadRequest),
    takeLatest(DOWNLOAD_PUPLOAD, downloadFileRequest),
    //takeLatest(DOWNLOAD_PUPLOAD, downloadRequestWatcherSaga),
    takeLatest(UPLOAD_REQUEST, uploadRequestWatcherSaga)
  ]);
}
