import jwtDecode from "jwt-decode";

export const needRefreshToken = () => {
  const token =
    localStorage.getItem("provolve_auth_user") &&
    (localStorage.getItem("provolve_auth_user") !== undefined ||
      localStorage.getItem("provolve_auth_user") !== null)
      ? JSON.parse(localStorage.getItem("provolve_auth_user"))["auth_token"]
      : null;

  return token && jwtDecode(token).exp < Date.now() / 1000;
};
