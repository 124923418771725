import React from "react";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Table, Button } from "semantic-ui-react";

const PackageItem = ({
  _package,
  onDeleteItem,
  onEditItem,
  user,
  canViewUpdates = true
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <Table.Row className="tbl-pad" error={_package.urlBroken}>
      <Table.Cell>{_package.fileName}</Table.Cell>
      <Table.Cell>{_package.version}</Table.Cell>
      <Table.Cell>{_package.platform ? "x64" : "x86"}</Table.Cell>
      <Table.Cell>{_package.category ? "Generic" : "Specific"}</Table.Cell>

      {!user.profile.role.includes("APIClient") ? (
        <Table.Cell textAlign="right">
          <Button.Group size="mini">
            {canViewUpdates && (
              <Button
                style={{ width: "auto" }}
                onClick={() => history.push(`${url}/${_package.id}`)}
                basic
                color="blue"
              >
                Details
              </Button>
            )}
            {!canViewUpdates && (
              <React.Fragment>
                <Button
                  style={{ width: "auto" }}
                  onClick={() => onEditItem(_package)}
                  basic
                  color="blue"
                >
                  Edit
                </Button>
                <Button
                  style={{ width: "auto" }}
                  onClick={() => onDeleteItem(_package.id)}
                  basic
                  color="red"
                >
                  Delete
                </Button>
              </React.Fragment>
            )}
          </Button.Group>
        </Table.Cell>
      ) : (
        <Table.Cell style={{ height: "36px" }} />
      )}
    </Table.Row>
  );
};

PackageItem.propTypes = {
  _package: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  canViewUpdates: PropTypes.bool,
  user: PropTypes.object
};

export default PackageItem;
