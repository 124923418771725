/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, createRef } from "react";
import { Button, Menu, List, Grid, Table, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  fetchSessionActivityInfo,
  fetchSessionData,
  onSelectCustomer,
  fetchUserNotificationSettings,
  openModalService,
  closeModalService,
  generateXlsxReport
} from "../../../../../actions";
import CustomScrollbars from "../../../../../util/CustomScrollbars";
import Pagination from "../../../../../components/Pagination";
import Loader from "../../../../../components/Loader";
import ComputerList from "./ComputerList";
import SessionList from "./SessionList";
import Settings from "./Settings";
const EntryFormSchema = Yup.object().shape({
  StartDate: Yup.date().required(),
  EndDate: Yup.date().required()
});

const InitialValues = {
  StartDate: "",
  EndDate: "",
  UserId: ""
};

class SessionsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSession: {},
      selectedSessionItem: {},
      filters: {}
    };
  }

  componentDidMount() {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id") &&
      this.isAdmin
    ) {
      this.props.fetchUserNotificationSettings(this.props.selectedCustomer);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !(
        this.props.selectedCustomer &&
        Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id")
      )
    ) {
      this.props.push("/store/customers/");
    } else {
      if (
        prevProps.selectedCustomer !== this.props.selectedCustomer &&
        this.isAdmin
      ) {
        this.props.fetchUserNotificationSettings(this.props.selectedCustomer);
      }
    }
  }

  onSelectSession = session => {
    this.setState({ selectedSession: session });
  };
  onSelectSessionItem = sessionItem => {
    this.setState({ selectedSessionItem: sessionItem });
    this.props.fetchSessionActivityInfo({
      connectionID: sessionItem.connectionID,
      Limit: 15
    });
  };

  showUserSettings = () =>
    this.props.openModalService({
      children: <Settings closeModalService={this.props.closeModalService} />,
      size: "small"
    });

  get isAdmin() {
    return (
      this.props.user.profile.role.includes("Admin") ||
      this.props.user.profile.role.includes("SuperAdmin") ||
      this.props.user.profile.role.includes("Normal")
    );
  }

  get isCustomer() {
    return this.props.user.profile.role.includes("Customer");
  }

  render() {
    const {
      sessionData,
      sessionActivty,
      isFetchingSessioninfo,
      isGeneratingReport,
      isFetchingAgentSessionData
    } = this.props;
    return (
      <React.Fragment>
        <Grid.Row
        //   style={{ borderBottom: "1px solid #e9ecef", marginBotton: "30px" }}
        >
          <Formik
            initialValues={{
              ...InitialValues,
              Limit: 12,
              Page: 1,
              UserId:
                this.props.selectedCustomer && this.props.selectedCustomer.id
            }}
            enableReinitialize
            validationSchema={EntryFormSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                this.setState({ filters: values });
                this.props.fetchSessionData(values);
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Form.Group inline>
                  <Form.Input
                    label="From: "
                    type="date"
                    name="StartDate"
                    size="mini"
                    onBlur={props.handleBlur}
                    value={props.values.StartDate}
                    onChange={props.handleChange}
                    placeholder="2020-09-19"
                    width={4}
                  />
                  <Form.Input
                    label="to: "
                    type="date"
                    name="EndDate"
                    size="mini"
                    value={props.values.EndDate}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    placeholder="2020-09-19"
                    width={4}
                  />
                  <Form.Button
                    basic
                    size="tiny"
                    disabled={props.isSubmitting || isFetchingAgentSessionData}
                    loading={props.isSubmitting || isFetchingAgentSessionData}
                    type="submit"
                    color="grey"
                    content="Generate"
                    width={this.isAdmin ? 4 : 6}
                  />
                  <Button.Group size="tiny" basic>
                    <Form.Button
                      type="button"
                      icon="file excel"
                      loading={isGeneratingReport}
                      disabled={Object.keys(this.state.filters).length === 0}
                      onClick={() =>
                        this.props.generateXlsxReport(this.state.filters)
                      }
                      content="Export .xlsx"
                    />
                    {this.isAdmin && (
                      <Form.Button
                        type="button"
                        icon="setting"
                        onClick={this.showUserSettings}
                        content="Modify Settings"
                      />
                    )}
                  </Button.Group>
                </Form.Group>
              </Form>
            )}
          </Formik>
          <br />
        </Grid.Row>
        <Grid.Row>
          <Grid columns="three" divided>
            <Grid.Column width={this.isAdmin ? 5 : 12}>
              <div className="list-item-container">
                <CustomScrollbars
                  style={{
                    height: "calc(100vh - 348px)"
                  }}
                >
                  {isFetchingAgentSessionData && <Loader />}
                  <ComputerList
                    sessionData={sessionData}
                    onSelectItem={this.onSelectSession}
                    isAdmin={this.isAdmin}
                    selectedComputerItem={this.state.selectedSession.computer}
                  />
                </CustomScrollbars>
                <Pagination
                  totalPages={sessionData.totalPages}
                  limit={12}
                  siblingRange={0}
                  boundaryRange={0}
                  handlePaginationChange={filters =>
                    this.props.fetchSessionData({
                      ...filters,
                      ...this.state.filters
                    })
                  }
                />
              </div>
            </Grid.Column>
            {this.isAdmin === undefined && (
              <Grid.Column width="4">
                <div className="list-item-container">
                  <CustomScrollbars
                    style={{
                      height: "calc(100vh - 348px)"
                    }}
                  >
                    <SessionList
                      selectedSocketItem={this.state.selectedSessionItem}
                      onSelectItem={this.onSelectSessionItem}
                      sessionData={this.state.selectedSession.sessions}
                    />
                  </CustomScrollbars>
                </div>
              </Grid.Column>
            )}
            {this.isAdmin === undefined && (
              <Grid.Column width={7}>
                <CustomScrollbars
                  style={{
                    height: "calc(100vh - 348px)"
                  }}
                >
                  {isFetchingSessioninfo && <Loader />}
                  <Table selectable padded basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan="3">
                          Session Activity
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {sessionActivty.items.map(_activity => (
                        <Table.Row className="tbl-pad" key={_activity.id}>
                          <Table.Cell>{_activity.command}</Table.Cell>
                          {/* <Table.Cell>{_activity.activity}</Table.Cell> */}
                          <Table.Cell>
                            {moment(_activity.createdAt).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </CustomScrollbars>
                <Pagination
                  totalPages={sessionActivty.totalPages}
                  limit={15}
                  siblingRange={0}
                  boundaryRange={0}
                  handlePaginationChange={filters => {
                    return this.props.fetchSessionActivityInfo({
                      ...filters,
                      connectionID: this.state.selectedSessionItem.connectionID
                    });
                  }}
                />
              </Grid.Column>
            )}
          </Grid>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  customerData,

  settings,
  esdActivityData,
  router,
  oidc
}) => {
  const { width } = settings;
  const { user } = oidc;
  const { location } = router;
  const { selectedCustomer } = customerData;
  const {
    sessionData,
    sessionActivty,
    isFetchingSessioninfo,
    isGeneratingReport,
    isFetchingAgentSessionData
  } = esdActivityData;

  return {
    location,
    user,
    width,
    sessionData,
    sessionActivty,
    selectedCustomer,
    isFetchingSessioninfo,
    isGeneratingReport,
    isFetchingAgentSessionData
  };
};

export default connect(mapStateToProps, {
  push,
  fetchSessionActivityInfo,
  fetchSessionData,
  onSelectCustomer,
  fetchUserNotificationSettings,
  openModalService,
  closeModalService,
  generateXlsxReport
})(SessionsInfo);
