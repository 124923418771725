/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Item, Label, Button } from "semantic-ui-react";

const UserItem = ({
  user,
  onEdit,
  onDelete,
  navigateToClientDetail,
  sendLink,
  updateUser
}) => {
  const { firstName, lastName, email, role, isAccessDisabled } = user;
  const fullName = `${firstName} ${lastName}`;
  const authId = useSelector(({ oidc }) => oidc.user.profile.sub);
  return (
    <Item>
      <Item.Content>
        <Item.Header as="h5">
          {role.includes("APIClient") ? (
            <Link
              to={`/store/profile/${user.id}`}
              onClick={() => navigateToClientDetail(user)}
            >
              {fullName}
            </Link>
          ) : (
            fullName
          )}
        </Item.Header>
        <Item.Extra>
          {role.map(_r => (
            <Label key={_r}>{_r}</Label>
          ))}

          <Button.Group floated="right">
            <Button
              style={{ width: "auto" }}
              size="tiny"
              basic
              color="blue"
              onClick={() => onEdit(user)}
            >
              Edit
            </Button>
            {authId !== user.id && (
              <React.Fragment>
                <Button
                  style={{ width: "auto" }}
                  size="tiny"
                  basic
                  color="blue"
                  onClick={() =>
                    updateUser({ ...user, isAccessDisabled: !isAccessDisabled })
                  }
                >
                  {!isAccessDisabled ? "Disable" : "Enable"}
                </Button>

                <Button
                  size="tiny"
                  basic
                  color="red"
                  style={{ width: "auto" }}
                  onClick={() => onDelete(user)}
                >
                  Delete
                </Button>
              </React.Fragment>
            )}
          </Button.Group>
        </Item.Extra>
      </Item.Content>
    </Item>
  );
};

const UserList = ({
  users,
  onEdit,
  onDelete,
  sendLink,
  updateUser,
  navigateToClientDetail
}) => (
  <Item.Group divided>
    {users.map(user => (
      <UserItem
        key={user.id}
        onEdit={onEdit}
        onDelete={onDelete}
        user={user}
        updateUser={updateUser}
        navigateToClientDetail={navigateToClientDetail}
        sendLink={sendLink}
      />
    ))}
  </Item.Group>
);

export default UserList;
