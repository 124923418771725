/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Header, Menu, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

class SidebarMenu extends Component {
  static propTypes = {
    onToggleNav: PropTypes.func.isRequired,
    navCollapsed: PropTypes.bool.isRequired,
    activeMenu: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    menuItems: PropTypes.array
  };

  renderMenuContent = menu => {
    return (
      <Link to={menu.route}>
        <Icon name={menu.icon} />
        <span>{menu.text}</span>
      </Link>
    );
  };

  renderSidebarLogo = (navCollapsed, onToggleNav, width) => {
    if (navCollapsed || width < 1099) {
      return (
        <div className="logo-item">
          <Header as="div">
            <img src="" style={{ width: "27px " }} alt="logo" />
          </Header>
        </div>
      );
    }

    return (
      <div className="logo-item">
        <Header as="div">
          <img src={require("../../assets/esd.png")} alt="provolve-esd" />
        </Header>

        {/*<Button icon className="mini-button" onClick={onToggleNav}>
          <Icon name="chevron left" />
        </Button>*/}
      </div>
    );
  };

  get isAdmin() {
    return (
      this.props.authUser.profile.role.includes("Admin") ||
      this.props.authUser.profile.role.includes("SuperAdmin") ||
      this.props.authUser.profile.role.includes("Normal")
    );
  }

  get isCustomer() {
    return this.props.authUser.profile.role.includes("Customer");
  }

  render() {
    const {
      onToggleNav,
      navCollapsed,
      // setAtiveMenu,
      width,
      menuItems,
      activeMenu,
      authUser
    } = this.props;

    return (
      <div className="app-sidebar">
        <Menu
          // id="sidebar"
          vertical
          text
        >
          <Menu.Item>
            {this.renderSidebarLogo(navCollapsed, onToggleNav, width)}
          </Menu.Item>
          <Menu.Item className="app-sidebar-menu">
            <Menu.Menu>
              {menuItems.map((menu, index) => {
                const canViewOrg =
                  Object.prototype.hasOwnProperty.call(menu, "organisation") &&
                  Number(authUser.profile.org) === 1
                    ? ["default", "any"]
                    : ["any"];

                if (
                  menu.roles.some(r => authUser.profile.role.includes(r)) &&
                  canViewOrg.includes(menu.organisation)
                ) {
                  let customMenu = menu;
                  if (menu.name === "settings")
                    customMenu.route = `/store/settings/${authUser.profile.sub}`;
                  if (
                    menu.name === "customers" &&
                    this.isCustomer &&
                    !this.isAdmin
                  ) {
                    customMenu.text = "Package Store";
                    customMenu.icon = "disk";
                    customMenu.route = `/store/customers/${authUser.profile.sub}/packages/pub`;
                  }

                  return (
                    <Menu.Item
                      as="div"
                      //key={menu.name}
                      // onClick={() => setAtiveMenu(menu.text)}
                      key={index}
                      name={customMenu.name}
                      active={customMenu.text === activeMenu}
                      content={this.renderMenuContent(customMenu)}
                    />
                  );
                }
                return null;
              })}
            </Menu.Menu>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default SidebarMenu;
