/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, createRef } from "react";
import { Button, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import {
  fetchCustomer,
  fetchCustomerPackage,
  saveCustomer,
  updateCustomer,
  removeCustomer,
  filterCustomers,
  updateSearchCustomerPackage,
  filterCustomerPackages,
  onRemoveCustomerPUpload,
  onDownloadFIle,
  updateSearchCustomer,
  onSelectCustomer,
  fetchCustomerPubPackages,
  setAtiveMenu,
  fetchClientDetails,
  fetchUserByID,
  fetchComputerItems,
  onSelectComputer,
  openModalService,
  closeModalService
} from "../../../actions";
import Pagination from "../../../components/Pagination";
import CustomScrollbars from "../../../util/CustomScrollbars";
import CustomerList from "./components/customerList";
import Loader from "../../../components/Loader";
import PageNotFound from "../404";
import CustomerDetails from "./routes";
import SearchFilter from "../../../components/SearchBox";
import { useDecodeLicense } from "../../../util/useDecodeLicense";
import ComputerList from "../computers/components/ComputerList";
import AddCustomerModel from "./components/AddCustomerModel";

const pageLimit = 11;
class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };

    this.inputField = createRef();
  }

  searchTable = search => this.setState({ search });

  componentDidMount() {
    if (this.isAdmin) {
      this.props.setAtiveMenu(this.props.activeMenu);
      this.props.fetchCustomer({ Limit: pageLimit });
    } else {
      this.props.setAtiveMenu("Package Store");
      this.props.push(
        `/store/customers/${this.props.user.profile.sub}/packages/pub`
      );
      this.props.fetchUserByID(this.props.user.profile.sub);
      this.props.fetchComputerItems({
        userId: this.props.user.profile.sub,
        Limit: pageLimit,
        onlineStatus: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname === "/store/customers" &&
      prevProps.location.pathname !== this.props.location.pathname &&
      this.isCustomer
    ) {
      this.props.push(
        `/store/customers/${this.props.user.profile.sub}/packages/pub`
      );
      this.props.fetchUserByID(this.props.user.profile.sub);
      this.props.fetchComputerItems({
        userId: this.props.user.profile.sub,
        Limit: pageLimit,
        onlineStatus: true
      });
    }
  }
  get isCustomer() {
    return this.props.user.profile.role.includes("Customer");
  }

  get isAdmin() {
    return (
      this.props.user.profile.role.includes("Admin") ||
      this.props.user.profile.role.includes("SuperAdmin") ||
      this.props.user.profile.role.includes("Normal")
    );
  }
  updateSearchCustomer = evt => {
    this.props.updateSearchCustomer(evt.target.value);
    this.props.fetchCustomer({ Term: evt.target.value, Limit: pageLimit });
  };

  onSelectCustomer = user => {
    this.props.onSelectCustomer(user.id);
    this.props.push(`/store/customers/${user.id}`);
  };

  onSelectItem = computer => {
    this.props.onSelectComputer(computer);
    this.props.push(`/store/manager/computer_items/${computer.id}`);
  };

  // onClickUploadBtn = () => this.inputField.current.click();

  handleAddCustomer = event => {
    event.preventDefault();
    // eslint-disable-next-line no-useless-escape
    const ext = event.target.value.match(/\.([^\.]+)$/)[1];
    if (event.target.files[0] instanceof File && ext.toString() === "lic") {
      const customerData = useDecodeLicense(event.target.files[0]);

      setTimeout(() => {
        this.props.saveCustomer(customerData);
      }, 500);
    }
  };

  // searchTable = search => {
  //   this.setState({ search }, () => {
  //     this.props.fetchComputerItems({
  //       userId: this.props.user.profile.sub,
  //       Term: search,
  //       onlineStatus: true,
  //       Limit: pageLimit
  //     });
  //   });
  // };

  addCustomerHandler = () => {
    this.props.openModalService({
      children: (
        <AddCustomerModel isEditing={true} onClose={this.handleModalClose} />
      ),
      size: "tiny"
    });
  };

  handleModalClose = () => {
    // e.preventDefault();
    this.props.closeModalService();
  };
  render() {
    const {
      customers,
      selectedCustomer,
      isFetchingCustomers,
      match,
      // location,
      searchText,
      user,
      computerItems,
      selectedComputerItem,
      isFetchingComputerItems
    } = this.props;

    const isAdmin =
      user.profile.role.includes("Admin") ||
      user.profile.role.includes("SuperAdmin") ||
      user.profile.role.includes("Normal");

    return (
      <div className="app-module">
        {isAdmin && (
          <div className="app-module-sidenav">
            <div className="page-sidebar">
              <div className="search-bar">
                <Menu vertical text>
                  <Menu.Item name="inbox">
                    <Button
                      color="blue"
                      fluid
                      onClick={this.addCustomerHandler}
                    >
                      Add Customer
                    </Button>
                    <input
                      accept=".lic"
                      id="edit-customer-file"
                      type="file"
                      style={{ display: "none" }}
                      ref={this.inputField}
                      onChange={this.handleAddCustomer}
                    />
                  </Menu.Item>

                  <Menu.Item>
                    <SearchFilter
                      value={searchText}
                      onChange={this.updateSearchCustomer}
                    />
                  </Menu.Item>
                </Menu>
              </div>
              <div className="list-item-container">
                <CustomScrollbars
                  style={{
                    height: "calc(100vh - 296px)"
                  }}
                >
                  {isFetchingCustomers ? <Loader /> : null}
                  <CustomerList
                    onSelectCustomer={this.onSelectCustomer}
                    customers={customers}
                    selectedCustomer={selectedCustomer}
                  />
                </CustomScrollbars>
                <div style={{ marginLeft: "15px" }}>
                  <Pagination
                    totalPages={customers.totalPages}
                    limit={pageLimit}
                    siblingRange={0}
                    boundaryRange={0}
                    handlePaginationChange={filters =>
                      this.props.fetchCustomer(filters)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <Switch>
          <Route
            path={`${match.url}/:customerId`}
            component={CustomerDetails}
          />

          <Route
            component={() => (
              <PageNotFound
                message="Oops, No Customer selected"
                buttonText="Select Customer"
              />
            )}
          />
        </Switch>
        {!this.isAdmin && this.isCustomer && (
          <div
            className="app-module-sidenav"
            style={{ borderLeft: "1px solid #e9ecef" }}
          >
            <div className="page-sidebar">
              <div className="search-bar">
                <SearchFilter
                  placeholder="Search for online computers"
                  value={this.state.search}
                  onChange={event => this.searchTable(event.target.value)}
                />
              </div>
              <div className="list-item-container">
                {isFetchingComputerItems ? <Loader /> : null}
                <CustomScrollbars
                  style={{
                    height: "calc(100vh - 240px)"
                  }}
                >
                  <ComputerList
                    onSelectItem={this.onSelectItem}
                    computers={computerItems}
                    selectedComputerItem={selectedComputerItem}
                  />
                </CustomScrollbars>
                <div style={{ marginLeft: "15px" }}>
                  <Pagination
                    totalPages={computerItems.totalPages}
                    limit={pageLimit}
                    siblingRange={0}
                    boundaryRange={0}
                    handlePaginationChange={filters =>
                      this.props.fetchComputerItems({
                        ...filters,
                        onlineStatus: true,
                        Term: this.state.search,
                        userId: this.props.user.profile.sub
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  customerData,
  settings,
  oidc,
  router,
  esdActivityData
}) => {
  const { width } = settings;
  const { user } = oidc;
  const { location } = router;
  const {
    customers,
    selectedCustomer,
    isFetchingCustomers,
    searchText
  } = customerData;
  const {
    computerItems,
    selectedComputerItem,
    isFetchingComputerItems
  } = esdActivityData;
  return {
    location,
    width,
    customers,
    selectedCustomer,
    user,
    isFetchingCustomers,
    searchText,
    computerItems,
    selectedComputerItem,
    isFetchingComputerItems
  };
};

export default connect(mapStateToProps, {
  fetchCustomer,
  fetchCustomerPackage,
  saveCustomer,
  updateCustomer,
  removeCustomer,
  filterCustomers,
  updateSearchCustomerPackage,
  filterCustomerPackages,
  onRemoveCustomerPUpload,
  onDownloadFIle,
  updateSearchCustomer,
  onSelectCustomer,
  fetchCustomerPubPackages,
  push,
  setAtiveMenu,
  fetchClientDetails,
  fetchUserByID,
  fetchComputerItems,
  onSelectComputer,
  openModalService,
  closeModalService
})(Customers);
