import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { setAtiveMenu } from "../../../actions";
import { Grid } from "semantic-ui-react";
import PageNotFound from "../../../components/UXModulePlaceholder";
import OrgnList from "./routes/List";
import OrgnDetails from "./routes/Details";
class Organisation extends React.Component {
  componentDidMount() {
    this.props.setAtiveMenu(this.props.activeMenu);
  }

  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <Grid
          padded
          style={{
            border: "1px solid rgba(0,0,0,.1)",

            background: "#fff"
          }}
        >
          <Switch>
            {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/pub`} /> */}
            <Route exact path={match.url} component={OrgnList} />
            <Route
              exact
              path={`${match.url}/:orgnId/view`}
              component={OrgnDetails}
            />

            <Route component={PageNotFound} />
          </Switch>
        </Grid>
      </div>
    );
  }
}

Organisation.propTypes = {
  match: PropTypes.object,
  setAtiveMenu: PropTypes.func,
  activeMenu: PropTypes.string
};

// use selectors instead
const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setAtiveMenu })(Organisation);
