import React, { useState, useEffect } from "react";
import { Form, Button } from "semantic-ui-react";
import PropTypes from "prop-types";

const ComputerForm = ({ selectedComputerItem, disableEdit, onSubmitForm }) => {
  const [computerItem, setComputerItem] = useState({
    computerName: "",
    computerDesc: "",
    ipAddress: ""
  });
  const handleInputChange = event => {
    event.persist();
    const { name, value } = event.target;
    setComputerItem(computerItem => ({ ...computerItem, [name]: value }));
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    onSubmitForm(computerItem);
  };

  useEffect(() => {
    setComputerItem({ ...selectedComputerItem });
  }, [selectedComputerItem]);
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Input
        fluid
        label="Computer Name"
        placeholder="Computer Name"
        name="computerName"
        onChange={handleInputChange}
        value={computerItem.computerName ? computerItem.computerName : ""}
        readOnly={disableEdit}
      />

      <Form.Input
        fluid
        label="Computer Desc"
        name="computerDesc"
        onChange={handleInputChange}
        placeholder="Desc"
        value={computerItem.computerDesc ? computerItem.computerDesc : ""}
        readOnly={disableEdit}
      />

      <Form.Input
        fluid
        label="MAC Address"
        name="ipAddress"
        placeholder="MAC Address"
        onChange={handleInputChange}
        value={computerItem.ipAddress ? computerItem.ipAddress : ""}
        readOnly={disableEdit}
      />

      {!disableEdit && (
        <Button hidden type="submit">
          Update
        </Button>
      )}
    </Form>
  );
};

ComputerForm.propTypes = {
  selectedComputerItem: PropTypes.object.isRequired,
  disableEdit: PropTypes.bool.isRequired,
  onSubmitForm: PropTypes.func.isRequired
};

export default ComputerForm;
