/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createRef } from "react";
import { connect } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import {
  ITEMS_NAME as TopTabBar_ITEMS_NAME,
  TopTabBar
} from "./components/TopTabBar";
import PageNotFound from "../404";
import { Route, Switch, Redirect } from "react-router-dom";
import PubPackages from "./routes/PubPackages";
import PrvPackages from "./routes/PrvPackages";
import SearchFilter from "./components/FilterSection";
import {
  setAtiveMenu,
  openModalService,
  updateSearchPackage,
  filterPackage,
  filterPUpload,
  uploadRequest,
  fetchPackage,
  fetchPUpload,
  onDownloadFIle
} from "../../../actions";
import PackageModalForm from "./components/PackageForm";
import PackageItemDetails from "./routes/PubPackages/PackageItemDetails";
class StorePackages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topTabBarActiveItem: TopTabBar_ITEMS_NAME[0],
      search: "",
      showMenu: true
    };

    this.inputField = createRef();
  }

  componentDidMount() {
    this.props.setAtiveMenu(this.props.activeMenu);
  }

  setShowMenu = value => this.setState({ showMenu: value });

  searchTable = search => {
    const { match, location } = this.props;
    const { pathname } = location;
    this.setState({ search }, () => {
      if (pathname === TopTabBar_ITEMS_NAME[0][1]) {
        this.props.fetchPackage({ Term: search, Limit: 10 });
      } else {
        this.props.fetchPUpload({ Term: search, Limit: 10 });
      }
    });
  };

  handleAddPackage = () => {
    this.props.openModalService({
      children: <PackageModalForm />,
      size: "tiny"
    });
  };

  onClickAdd = (file, name) => {
    //this.props.savePUpload(file, name);
    this.props.uploadRequest(file, name);
  };

  onClickUploadBtn = () => this.inputField.current.click();

  // searchPubPackages = searchText =>

  render() {
    const { showMenu } = this.state;
    const { match, location, user } = this.props;
    const { params } = match;
    const { pathname } = location;

    return (
      <div className="app-wrapper">
        {showMenu && (
          <Grid padded className="header-grid">
            <Grid.Row>
              <Grid.Column computer="10" mobile="16">
                <TopTabBar pathname={pathname} />
              </Grid.Column>
              <Grid.Column computer="6" mobile="16"></Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Grid padded>
          {showMenu && (
            <Grid.Row>
              <Grid.Column computer="10" mobile="16">
                {!user.profile.role.includes("APIClient") ? (
                  <Button
                    basic
                    color="blue"
                    onClick={
                      pathname === TopTabBar_ITEMS_NAME[0][1]
                        ? this.handleAddPackage
                        : this.onClickUploadBtn
                    }
                  >
                    Add Package
                  </Button>
                ) : null}

                <input
                  accept=".zip"
                  id="edit-customer-file"
                  type="file"
                  style={{ display: "none" }}
                  ref={this.inputField}
                  onChange={e =>
                    this.onClickAdd(e.target.files[0], e.target.files[0].name)
                  }
                />
              </Grid.Column>
              <Grid.Column computer="6" mobile="16">
                <SearchFilter
                  updateSearch={this.searchTable}
                  search={this.state.search}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>
              <Switch>
                <Redirect
                  exact
                  from={`${match.url}/`}
                  to={`${match.url}/pub`}
                />

                <Route path={`${match.url}/prv`} component={PrvPackages} />
                <Route
                  exact
                  path={`${match.url}/pub`}
                  component={PubPackages}
                />

                <Route
                  path={`${match.url}/pub/:packageId`}
                  exact
                  render={props => (
                    <PackageItemDetails
                      {...props}
                      setShowMenu={this.setShowMenu}
                    />
                  )}
                />

                <Route component={PageNotFound} />
              </Switch>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, router, oidc }) => {
  const { width } = settings;
  const { location } = router;
  const { user } = oidc;
  return { width, location, user };
};

export default connect(mapStateToProps, {
  setAtiveMenu,
  openModalService,
  updateSearchPackage,
  filterPackage,
  filterPUpload,
  uploadRequest,
  onDownloadFIle,
  fetchPackage,
  fetchPUpload
})(StorePackages);
