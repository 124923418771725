/* eslint-disable no-unused-vars */
import {
  all,
  call,
  put,
  takeEvery,
  select,
  fork,
  takeLatest
} from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  fetchOrgnSuccess,
  fetchOrgnFailure,
  updateOrgnFailure,
  updateOrgnSuccess,
  deleteOrgnFailure,
  deleteOrgnSuccess,
  createOrgnFailed,
  createOrgnSuccess,
  fetchOrgnUsers,
  fetchOrgnUserSuccess,
  fetchOrgnUserFailure,
  createOrgnAdminFailure,
  getOrgnSuccess
} from "../actions";
import {
  FETCH_ORGN,
  UPDATE_ORGN,
  CREATE_ORGN,
  DELETE_ORGN,
  SIGNOUT_USER,
  FETCH_ORGN_USERS,
  CREATE_ORGN_ADMIN,
  GET_ORGN
} from "../constants/ActionTypes";
import Notify from "../actions/Notify";
import { needRefreshToken } from "../util/checkTokenExpiration";
import { API, orgnAPI, usersAPI } from "../api";
import { errorhandler } from "./error";

function* getOrgnRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const orgaization = yield call(
        API.request,
        orgnAPI.getOrganisation(token, payload)
      );

      if (orgaization && orgaization.succeeded) {
        yield put(getOrgnSuccess(orgaization.items));
      }
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(fetchOrgnFailure(error));
    yield call(errorhandler, error);
  }
}
function* fetchOrgnRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedOrganisation = yield call(
        API.request,
        orgnAPI.getOrganisations(token, payload)
      );
      yield put(fetchOrgnSuccess(fetchedOrganisation));
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(fetchOrgnFailure(error));
    yield call(errorhandler, error);
  }
}

function* fetchOrgnUserRequest({ payload }) {
  try {
    const { orgnID, filters } = payload;
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedOrganisation = yield call(
        API.request,
        orgnAPI.getOrganisationUsers(token, orgnID, filters)
      );
      yield put(fetchOrgnUserSuccess(fetchedOrganisation));
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(fetchOrgnUserFailure(error));
    yield call(errorhandler, error);
  }
}

function* createOrgnAdminRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        orgnAPI.createUserInOrg(token, payload, payload.orgnId)
      );
      // yield put(createOrgnSuccess(response));
      // yield put(showPackageMessage("Package Successfully Added"));
      if (response.succeeded) {
        Notify.success("Organization Admin Successfully Added and Invite sent");
        yield fork(fetchOrgnUserRequest, {
          payload: { orgnID: payload.organisationId, filters: { Limit: 10 } }
        });
      } else {
        if (
          Object.prototype.hasOwnProperty.call(response, "errors") &&
          response.errors instanceof Array
        ) {
          response.errors.forEach(e => {
            Notify.error(e);
          });
        } else {
          Notify.error(response.message);
        }
      }
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(createOrgnAdminFailure(error));
    yield call(errorhandler, error);
  }
}

function* updateOrgnRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const res = yield call(
        API.request,
        orgnAPI.updateOrganisation(token, payload)
      );
      // TODO
      yield put(updateOrgnSuccess(res));
      // yield put(showPackageMessage("Package Successfully Updated"));
      Notify.success("Organisation Successfully Updated");
      yield fork(fetchOrgnUserRequest, {
        payload: { orgnID: payload.id, filters: { limit: 10 } }
      });
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(updateOrgnFailure(error));
    yield call(errorhandler, error);
  }
}

function* createOrgnRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        orgnAPI.createOrganisation(token, payload)
      );
      yield put(createOrgnSuccess(response));
      // yield put(showPackageMessage("Package Successfully Added"));
      Notify.success("Organization Successfully Added");
      yield fork(fetchOrgnRequest, { payload: { limit: 10 } });
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(createOrgnFailed(error));
    yield call(errorhandler, error);
  }
}

function* deleteOrgnRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      yield call(API.request, orgnAPI.deleteOrganisation(token, payload));
      //   yield put(showPackageMessage("Package Successfully Deleted"));
      yield put(deleteOrgnSuccess(payload));
      Notify.success("Organization Successfully Deleted");
      // yield fork(fetchOrgnRequest, { payload: { limit: 10 } });

      yield put(push("/store/orgn"));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(deleteOrgnFailure(error));
    yield call(errorhandler, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_ORGN, fetchOrgnRequest),
    takeLatest(UPDATE_ORGN, updateOrgnRequest),
    takeLatest(CREATE_ORGN, createOrgnRequest),
    takeLatest(DELETE_ORGN, deleteOrgnRequest),
    takeLatest(FETCH_ORGN_USERS, fetchOrgnUserRequest),
    takeLatest(CREATE_ORGN_ADMIN, createOrgnAdminRequest),
    takeLatest(GET_ORGN, getOrgnRequest)
  ]);
}
