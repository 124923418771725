import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Pagination from "../Pagination";
// import styles from "./table.module.css";

function CustomTable({
  columns,
  data,
  dataKey,
  handlePaginationChange,
  totalPages
}) {
  return (
    <Table selectable padded basic="very">
      <Table.Header>
        <Table.Row>
          {columns.map(_item => (
            <Table.HeaderCell key={_item.key}>{_item.name}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(_item => (
          <Table.Row className="tbl-pad" key={_item[dataKey]}>
            {columns.map(_columnItem => {
              const defaultValue = Object.prototype.hasOwnProperty.call(
                _columnItem,
                "defaultValue"
              )
                ? _columnItem.defaultValue
                : null;

              const formatedValue = Object.prototype.hasOwnProperty.call(
                _columnItem,
                "format"
              )
                ? _columnItem.format(_item[_columnItem.key])
                : _item[_columnItem.key];
              return (
                <Table.Cell
                  key={_columnItem.name}
                  className={classnames({
                    [_columnItem.styles]: Object.prototype.hasOwnProperty.call(
                      _columnItem,
                      "styles"
                    )
                  })}
                >
                  {Object.prototype.hasOwnProperty.call(
                    _columnItem,
                    "prefix"
                  ) && _columnItem.prefix}{" "}
                  {Object.prototype.hasOwnProperty.call(_columnItem, "custom")
                    ? _columnItem.custom(_item)
                    : formatedValue || defaultValue}{" "}
                  {Object.prototype.hasOwnProperty.call(
                    _columnItem,
                    "suffix"
                  ) && _columnItem.suffix(_item)}
                </Table.Cell>
              );
            })}
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="4">
            <Pagination
              totalPages={totalPages}
              limit={10}
              handlePaginationChange={handlePaginationChange}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  handlePaginationChange: PropTypes.func,
  totalPages: PropTypes.number
};

export default CustomTable;
