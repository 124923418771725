/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Modal, Button, Form, Input, Select, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  saveUser,
  updateUser,
  onUserSelect,
  closeModalService
} from "../../../actions";

import { Formik } from "formik";
import * as Yup from "yup";

const userSchema = Yup.object().shape({
  id: Yup.string(),

  email: Yup.string()
    .label("Email")
    .email()
    .required(),
  firstName: Yup.string()
    .label("FirstName")
    .required(),
  lastName: Yup.string()
    .label("LastName")
    .required(),
  adminRole: Yup.string()
    .label("Role")
    .required(),
  isCustomer: Yup.string().label("is Customer?")
});

class AddUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstName: "",
        lastName: "",
        email: "",

        role: [],
        adminRole: "Normal",
        isCustomer: "no"
      }
    };
  }

  componentDidMount() {
    if (
      this.props.selectedUser &&
      Object.prototype.hasOwnProperty.call(this.props.selectedUser, "id")
    ) {
      let { role } = this.props.selectedUser;
      if (!role.length) {
        role = ["Normal"];
      }

      const roleName = role.find(x => x !== "Customer");

      this.setState({
        user: {
          ...this.props.selectedUser,
          password: "",
          adminRole: roleName === undefined ? "Normal" : roleName,
          isCustomer: !role.includes("Customer") ? "no" : "yes"
        }
      });
    }
  }

  handleSubmit = values => {
    if (values.isCustomer === "yes") {
      values.role = ["Customer", values.adminRole];
    } else {
      values.role = [values.adminRole];
    }
    if (Object.prototype.hasOwnProperty.call(values, "id")) {
      this.props.updateUser(values);
    } else {
      this.props.saveUser(values);
    }
    setTimeout(() => {
      this.onClose();
    }, 500);
  };

  onClose = () => {
    this.props.onUserSelect({});
    this.props.closeModalService();
  };

  render() {
    const { user } = this.state;
    const options = [
      { key: "N", text: "Normal", value: "Normal" },
      { key: "A", text: "Admin", value: "Admin" },
      { key: "S", text: "SuperAdmin", value: "SuperAdmin" },
      { key: "C", text: "API Client", value: "APIClient" }
    ];
    return (
      <React.Fragment>
        <Modal.Header>
          {Object.prototype.hasOwnProperty.call(user, "id")
            ? "Update User"
            : "Add User"}
        </Modal.Header>
        <Modal.Content>
          <Formik
            enableReinitialize={true}
            initialValues={user}
            validationSchema={userSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                this.handleSubmit(values);
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Field
                  control={Input}
                  label="First name"
                  name="firstName"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={
                    errors.firstName && touched.firstName
                      ? { content: errors.firstName }
                      : null
                  }
                  placeholder="First name"
                />
                <Form.Field
                  control={Input}
                  label="Last name"
                  placeholder="Last name"
                  name="lastName"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={
                    errors.lastName && touched.lastName
                      ? { content: errors.lastName }
                      : null
                  }
                />
                <Form.Field
                  control={Input}
                  label="Email"
                  placeholder="email"
                  name="email"
                  required
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.email && touched.email
                      ? { content: errors.email }
                      : null
                  }
                />

                <Form.Field
                  control={Select}
                  label="Role"
                  // name="adminRole"
                  selection
                  onChange={(e, { value }) => setFieldValue("adminRole", value)}
                  // onBlur={handleBlur}
                  value={values.adminRole}
                  error={
                    errors.adminRole ? { content: errors.adminRole } : null
                  }
                  options={options}
                  placeholder="Role"
                />

                <Form.Field
                  control={Select}
                  label="is Customer?"
                  // name="isCustomer"
                  selection
                  onChange={(e, { value }) =>
                    setFieldValue("isCustomer", value)
                  }
                  // onBlur={handleBlur}
                  value={values.isCustomer}
                  error={
                    errors.isCustomer ? { content: errors.isCustomer } : null
                  }
                  options={[
                    { key: "Yes", value: "yes", text: "Yes" },
                    { key: "No", value: "no", text: "No" }
                  ]}
                  placeholder="isCustomer"
                />

                <Divider />
                <Button.Group floated="right">
                  <Button negative onClick={this.onClose}>
                    Cancel
                  </Button>
                  <Button
                    positive
                    size="medium"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Button.Group>
              </Form>
            )}
          </Formik>
        </Modal.Content>
        <Modal.Actions />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ UserData }) => {
  const { selectedUser } = UserData;
  return { selectedUser };
};

export default connect(mapStateToProps, {
  saveUser,
  updateUser,
  onUserSelect,
  closeModalService
})(AddUser);
