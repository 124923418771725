/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import InstallationList from "./components/InstallationList";
import CustomScrollbars from "../../../util/CustomScrollbars";
import InstallationDetails from "./routes";
import Loader from "../../../components/Loader";
import PageNotFound from "../404";
import Pagination from "../../../components/Pagination";
import SearchFilter from "../../../components/SearchBox";
import {
  fetchInstallationItems,
  fetchComputerItems,
  onSelectInstallation,
  setAtiveMenu
} from "../../../actions";

const pageLimit = 17;
class InstallationItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  }

  componentDidMount() {
    this.props.setAtiveMenu(this.props.activeMenu);
    if (this.props.user) {
      this.props.fetchInstallationItems({
        userId: this.props.user.profile.sub,
        Limit: pageLimit
      });
      this.props.fetchComputerItems({
        userId: this.props.user.profile.sub,
        Limit: pageLimit
      });
    }

    //this.props.fetchInstallationItems("6d3a6173-a720-46f6-8b95-efecd6478bc9");
  }
  onSelectItem = installation => {
    this.props.onSelectInstallation(installation);
    this.props.push(`/store/manager/installation_items/${installation.id}`);
  };

  searchTable = search => {
    this.setState({ search }, () => {
      this.props.fetchInstallationItems({
        userId: this.props.user.profile.sub,
        Term: search,
        Limit: pageLimit
      });
    });
  };
  render() {
    const {
      width,
      match,
      installationItems,
      isFetchingInstallationItems,
      selectedInstallationItem
    } = this.props;
    return (
      <div className="app-module">
        <div className="app-module-sidenav">
          <div className="page-sidebar">
            <div className="search-bar">
              <SearchFilter
                placeholder="Search"
                value={this.state.search}
                onChange={event => this.searchTable(event.target.value)}
              />
            </div>
            <div className="list-item-container">
              {isFetchingInstallationItems ? <Loader /> : null}
              <CustomScrollbars
                style={{
                  height: "calc(100vh - 240px)"
                }}
              >
                <InstallationList
                  onSelectItem={this.onSelectItem}
                  installations={installationItems}
                  selectedInstallationItem={selectedInstallationItem}
                />
              </CustomScrollbars>
              <div style={{ marginLeft: "15px" }}>
                <Pagination
                  totalPages={installationItems.totalPages}
                  limit={pageLimit}
                  siblingRange={0}
                  boundaryRange={0}
                  handlePaginationChange={filters =>
                    this.props.fetchInstallationItems({
                      ...filters,
                      Term: this.state.search,
                      userId: this.props.user.profile.sub
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="module-box">
          <Switch>
            <Route
              path={`${match.url}/:computerId`}
              component={InstallationDetails}
            />
            <Route
              component={() => (
                <PageNotFound
                  message="Oops, No Installation Item selected"
                  buttonText="Select Item"
                />
              )}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, oidc, esdActivityData }) => {
  const { width } = settings;
  const { user } = oidc;
  const {
    installationItems,
    selectedInstallationItem,
    isFetchingInstallationItems
  } = esdActivityData;
  return {
    width,
    user,
    installationItems,
    isFetchingInstallationItems,
    selectedInstallationItem
  };
};
export default connect(mapStateToProps, {
  fetchInstallationItems,
  push,
  onSelectInstallation,
  setAtiveMenu,
  fetchComputerItems
})(InstallationItems);
