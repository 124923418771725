import React from "react";
import { Pagination } from "semantic-ui-react";
import PropTypes from "prop-types";

class PaginationView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { activePage: 1 };
  }

  onPaginationChange = activePage => {
    if (activePage === 0) activePage = 1;
    this.props.handlePaginationChange({
      Page: activePage,
      Limit: this.props.limit
    });
    this.setState({ activePage });
  };
  render() {
    const { activePage } = this.state;
    const { boundaryRange, siblingRange, totalPages } = this.props;

    return (
      <Pagination
        activePage={activePage}
        boundaryRange={boundaryRange}
        onPageChange={(e, { activePage }) =>
          this.onPaginationChange(activePage)
        }
        size="mini"
        siblingRange={siblingRange}
        totalPages={totalPages || 0}
        // ellipsisItem={true}
        // firstItem={true}
        // lastItem={true}
        // prevItem={true}
        // nextItem={true}
      />
    );
  }
}
PaginationView.defaultProps = {
  siblingRange: 1,
  boundaryRange: 1
};
PaginationView.propTypes = {
  handlePaginationChange: PropTypes.func.isRequired,
  limit: PropTypes.number,
  totalPages: PropTypes.number,
  siblingRange: PropTypes.number,
  boundaryRange: PropTypes.number
};

export default PaginationView;
