/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createRef } from "react";
import { Menu, Header, Grid, Button, Dropdown } from "semantic-ui-react";
import { Route, Switch, Redirect } from "react-router-dom";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import PageNotFound from "../../404";
import PubPackages from "./PubPackages";
import PrvPackages from "./PrvPackages";
import SessionInfoPage from "./sessions";
import { TopTabBar } from "../components/TopTabBar";
import SearchFilter from "../components/FilterSection";

import {
  onSelectCustomer,
  fetchCustomerPubPackages,
  fetchCustomerPrvPackages,
  openModalService,
  closeModalService,
  updateCustomer,
  removeCustomer,
  updateSearchCustomer,
  removeAdminCustomer,
  fetchUserNotificationSettings,
  generateCredentails,
  updateUser
} from "../../../../actions";

import AddPubPackageModal from "../components/AddPubPackageModal";
import AddPrvPackageModal from "../components/AddPrvPackageModal";
import Licenses from "./License";
import AddLicense from "../components/AddLicense";
import ManageAccount from "./Account";

class CustomerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = createRef();
    this.state = { search: "" };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customerList !== this.props.customerList) {
      this.props.onSelectCustomer(this.props.match.params.customerId);

      if (
        this.props.selectedCustomer &&
        Object.prototype.hasOwnProperty.call(
          this.props.selectedCustomer,
          "id"
        ) &&
        this.props.selectedCustomer.id !== this.props.match.params.customerId
      ) {
        this.props.push(`/store/customers/${this.props.selectedCustomer.id}`);
      }
    }
  }

  handleCreateLicense = () => {
    this.props.openModalService({
      children: <AddLicense onClose={this.handleModalClose} />,
      size: "tiny"
    });
  };

  handleAssignPackage = () => {
    if (this.props.location.pathname.includes("pub")) {
      this.props.openModalService({
        children: <AddPubPackageModal onClose={this.handleModalClose} />,
        size: "small"
      });
    } else {
      this.props.openModalService({
        children: <AddPrvPackageModal onClose={this.handleModalClose} />,
        size: "small"
      });
    }
  };

  handleModalClose = () => {
    // e.preventDefault();
    this.props.closeModalService();
  };

  onDropDownMenuClick = data => {
    switch (data.value) {
      case 1:
        this.props.push(
          `/store/customers/${this.props.match.params.customerId}/packages/pub`
        );
        break;
      case 2:
        this.props.push(
          `/store/customers/${this.props.match.params.customerId}/licenses`
        );

        break;
      case 3:
        this.props.push(
          `/store/customers/${this.props.match.params.customerId}/account`
        );
        // this.props.generateCredentails(this.props.match.params.customerId);
        break;
      case 4:
        this.props.push(
          `/store/customers/${this.props.match.params.customerId}/agent/sessions`
        );
        // this.props.generateCredentails(this.props.match.params.customerId);
        break;
      default:
        break;
    }
  };

  get isAdmin() {
    return (
      this.props.user.profile.role.includes("Admin") ||
      this.props.user.profile.role.includes("SuperAdmin") ||
      this.props.user.profile.role.includes("Normal")
    );
  }

  render() {
    const { selectedCustomer, location, match } = this.props;
    const { pathname } = location;
    const ITEMS_NAME = [
      [
        "Software Packages",
        `/store/customers/${match.params.customerId}/packages/pub`
      ],
      [
        "ESD Packages",
        `/store/customers/${match.params.customerId}/packages/prv`
      ]
    ];

    const options = [
      { key: 1, text: "Packages", value: 1, visible: true },
      { key: 2, text: "Licenses ", value: 2, visible: this.isAdmin },
      { key: 3, text: "Account", value: 3, visible: this.isAdmin },
      { key: 4, text: "Reports", value: 4, visible: this.isAdmin }
    ];
    return (
      <div className="module-box">
        <div className="module-box-header">
          <Menu secondary>
            <Menu.Item>
              <Header as="h3">
                {selectedCustomer ? selectedCustomer.companyName : null}
                <Header.Subheader style={{ marginTop: "2px" }}>
                  {selectedCustomer
                      ? selectedCustomer.firstName + " " + selectedCustomer.lastName
                      : null}
                </Header.Subheader>
                <Header.Subheader style={{ marginTop: "2px" }}>
                  {selectedCustomer
                    ? "Tel: " +
                      selectedCustomer.phoneNumber +
                      " ,  email:  " +
                      selectedCustomer.email
                    : null}
                </Header.Subheader>
              </Header>
            </Menu.Item>

            <Menu.Menu position="right">
              {pathname.includes("packages") && this.isAdmin && (
                <Button
                  basic
                  size="tiny"
                  color="grey"
                  as={Menu.Item}
                  onClick={this.handleAssignPackage}
                >
                  Assign Packages
                </Button>
              )}
              {pathname.includes("licenses") && this.isAdmin && (
                <Button
                  basic
                  size="tiny"
                  color="grey"
                  as={Menu.Item}
                  onClick={this.handleCreateLicense}
                >
                  Create License
                </Button>
              )}
              {this.isAdmin && (
                <Dropdown
                  item
                  text=" Manage "
                  as={Button}
                  basic
                  size="tiny"
                  color="grey"
                >
                  <Dropdown.Menu>
                    {/*<Dropdown.Header>Customer</Dropdown.Header>*/}
                    {options
                      .filter(x => x.visible)
                      .map(option => (
                        <Dropdown.Item
                          key={option.key}
                          value={option.value}
                          onClick={(evt, data) => {
                            this.onDropDownMenuClick(data);
                          }}
                        >
                          {option.text}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Menu.Menu>
          </Menu>
        </div>
        <div className="module-box-content">
          <Grid padded>
            {!(
              pathname === `${match.url}/agent/sessions` ||
              pathname === `${match.url}/licenses` ||
              pathname.includes("account")
            ) ? (
              <Grid.Row>
                <Grid.Column computer="10" mobile="16">
                  <TopTabBar pathname={pathname} ITEMS_NAME={ITEMS_NAME} />
                </Grid.Column>
                <Grid.Column computer="6" mobile="16">
                  <SearchFilter
                    updateSearch={search =>
                      this.searchTable(search, ITEMS_NAME[0][1])
                    }
                    search={this.state.search}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Column>
                <Switch>
                  <Redirect
                    exact
                    from={`${match.url}/`}
                    to={`${match.url}/packages/pub`}
                  />
                  <Route
                    exact
                    path={`${match.url}/packages/prv`}
                    component={PrvPackages}
                  />
                  <Route
                    exact
                    path={`${match.url}/packages/pub`}
                    component={PubPackages}
                  />
                  <Route
                    exact
                    path={`${match.url}/agent/sessions`}
                    component={SessionInfoPage}
                  />
                  <Route
                    exact
                    path={`${match.url}/licenses`}
                    component={Licenses}
                  />
                  <Route
                    path={`${match.url}/account`}
                    component={ManageAccount}
                  />
                  <Route component={PageNotFound} />
                </Switch>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ customerData, settings, router, oidc }) => {
  const { selectedCustomer, customerList } = customerData;
  const { width } = settings;
  const { location } = router;
  const { user } = oidc;
  return {
    selectedCustomer,
    width,
    user,
    location,
    customerList
  };
};
export default connect(mapStateToProps, {
  onSelectCustomer,
  fetchCustomerPubPackages,
  fetchCustomerPrvPackages,
  openModalService,
  closeModalService,
  updateCustomer,
  removeCustomer,
  push,
  updateUser,
  updateSearchCustomer,
  removeAdminCustomer,
  fetchUserNotificationSettings,
  generateCredentails
})(CustomerDetail);
