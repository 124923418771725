import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { setAtiveMenu } from "../../../actions";
import { Grid } from "semantic-ui-react";
import PageNotFound from "../../../components/UXModulePlaceholder";
import ProfileSummary from "./routes/Profile";
// import OrgnDetails from "./routes/Details";
class UserInfo extends React.Component {
  componentDidMount() {
    this.props.setAtiveMenu(this.props.activeMenu);
  }

  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <Grid
          padded
          style={{
            border: "1px solid rgba(0,0,0,.1)",

            background: "#fff"
          }}
        >
          <Switch>
            <Route
              exact
              path={match.url}
              render={props => (
                <ProfileSummary {...props} userId={match.params.userId} />
              )}
            />

            <Route component={PageNotFound} />
          </Switch>
        </Grid>
      </div>
    );
  }
}

UserInfo.propTypes = {
  match: PropTypes.object,
  setAtiveMenu: PropTypes.func,
  activeMenu: PropTypes.string
};

// use selectors instead
const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setAtiveMenu })(UserInfo);
