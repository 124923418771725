/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";

import { Route, Switch, Redirect } from "react-router-dom";
// import jwtDecode from "jwt-decode";

import "../styles/style.css";
// import  "../styles/index.css";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";

import MainApp from "../app";
import Login from "../app/routes/login";
import Loader from "../components/Loader";
// import PasswordReset from "../app/routes/password-reset";
import { setInitUrl, startChannel } from "../actions";
// import forgotPassword from "../app/routes/forgot-password";
import OauthCallbackPage from "../app/routes/callback";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => {
  const isTokenValid = authUser && authUser.expires_at > Date.now() / 1000;
  return (
    <Route
      {...rest}
      render={props =>
        isTokenValid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

class App extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.location.pathname);
    }
  }

  render() {
    const { location, initURL, user, match, isLoadingUser } = this.props;
    if (location.pathname === "/") {
      if (user === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/store/customers"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    if (isLoadingUser)
      return <Loader custumText="Loading authenticated user ..." />;

    return (
      <div id="app">
        <Switch>
          <RestrictedRoute
            path={`${match.url}store`}
            authUser={user}
            component={MainApp}
          />
          <Route path="/signin" component={Login} />
          {/* <Route path="/pwd-reset" component={PasswordReset} />
          <Route path="/forgot-pwd" component={forgotPassword} /> */}
          <Route path="/callback" component={OauthCallbackPage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ router, oidc, socketService }) => {
  const { location } = router;
  const { initURL, user, isLoadingUser } = oidc;
  const { channelStatus } = socketService;
  return { location, initURL, user, channelStatus, isLoadingUser };
};

export default connect(mapStateToProps, { setInitUrl, startChannel })(App);
