import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { init } from "./middlewares/signalr";
import store, { history } from "./store";
import App from "./containers";
import { ToastContainer } from "react-toastify";
import { OidcProvider } from "redux-oidc";
import userManager from "./util/userManager";

init(store);
const MainApp = () => (
  <Provider store={store}>
    <OidcProvider userManager={userManager} store={store}>
      <div>
        <ToastContainer
          position="bottom-right"
          hideProgressBar={false}
          autoClose={10000}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ConnectedRouter>
      </div>
    </OidcProvider>
  </Provider>
);

export default MainApp;
