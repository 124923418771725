/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Button, Divider, Form, Input, Modal, Select } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  closeModalService,
  createtLicense,
  onSelectCustomer
} from "../../../../actions";

import { Formik } from "formik";
import * as Yup from "yup";

const licenseSchema = Yup.object().shape({
  id: Yup.string(),
  numberOfClients: Yup.number()
    .label("NumberOfClients")
    .required(),
  expirationDate: Yup.date()
    .label("ExpirationDate")
    .required(),
  licenseType: Yup.number()
    .label("LicenseType")
    .required()
});

class AddLicense extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      license: {
        userId: "",
        numberOfClients: 10,
        expirationDate: "",
        licenseType: 0
      }
    };
  }

  componentDidMount() {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id")
    ) {
      this.setState({
        license: {
          ...this.state.license,
          userId: this.props.selectedCustomer.id
        }
      });
    }
  }

  handleSubmit = values => {
    this.props.createtLicense({
      ...values,
      userId: this.props.selectedCustomer.id
    });

    setTimeout(() => {
      this.onClose();
    }, 500);
  };

  onClose = () => {
    this.props.closeModalService();
  };

  render() {
    const { license } = this.state;

    return (
      <React.Fragment>
        <Modal.Header>Add License</Modal.Header>
        <Modal.Content>
          <Formik
            enableReinitialize={true}
            initialValues={license}
            validationSchema={licenseSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                this.handleSubmit(values);
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Field
                  control={Input}
                  label="How many client Computers"
                  name="numberOfClients"
                  required
                  type={"number"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.numberOfClients}
                  error={
                    errors.numberOfClients && touched.numberOfClients
                      ? { content: errors.numberOfClients }
                      : null
                  }
                  placeholder="100"
                />
                <Form.Field
                  control={Input}
                  label="Expires At"
                  placeholder="2021-02-21"
                  name="expirationDate"
                  type={"date"}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.expirationDate}
                  error={
                    errors.expirationDate && touched.expirationDate
                      ? { content: errors.expirationDate }
                      : null
                  }
                />

                <Form.Field
                  control={Select}
                  label="License Type"
                  name="licenseType"
                  selection
                  onChange={(e, { value }) =>
                    setFieldValue("licenseType", value)
                  }
                  onBlur={handleBlur}
                  value={values.licenseType}
                  error={
                    errors.licenseType ? { content: errors.licenseType } : null
                  }
                  options={[
                    { key: 0, value: 0, text: "Live" },
                    { key: 1, value: 1, text: "Trial" }
                  ]}
                  placeholder="Live / Trial"
                />

                <Divider />
                <Button.Group floated="right">
                  <Button negative onClick={this.onClose}>
                    Cancel
                  </Button>
                  <Button
                    positive
                    size="medium"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Button.Group>
              </Form>
            )}
          </Formik>
        </Modal.Content>
        <Modal.Actions />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ customerData }) => {
  const { selectedCustomer } = customerData;
  return { selectedCustomer };
};

export default connect(mapStateToProps, {
  createtLicense,
  onSelectCustomer,
  closeModalService
})(AddLicense);
