/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import TopNav from "../components/TopNav";
import Sidebar from "../components/Sidebar";
import menuItems from "../components/Sidebar/menu";

import Footer from "../components/Footer";

import { Route, Switch, withRouter } from "react-router-dom";
import {
  onToggleSidebar,
  updateWindowWidth,
  setAtiveMenu,
  userSignOut
} from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import InstallationItems from "./routes/installations";
import ComputerItems from "./routes/computers";
import Customers from "./routes/customers";
import UserProfile from "./routes/profile";
import StorePackages from "./routes/storePackages";
import Users from "./routes/users";
import PageNotFound from "./routes/404";
import ModalService from "../components/ModalService";
import { Responsive } from "semantic-ui-react";
import { PageTitles } from "../constants/options";
import Organisation from "./routes/orgn";
import userManager from "../util/userManager";

class App extends Component {
  static propTypes = {
    onToggleSidebar: PropTypes.func.isRequired,
    navCollapsed: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    updateWindowWidth: PropTypes.func.isRequired
  };

  state = {};

  onToggleSidebar = () => {
    const { onToggleSidebar, navCollapsed } = this.props;
    onToggleSidebar(!navCollapsed);
  };

  handlewidthChange = (e, { width }) => this.props.updateWindowWidth(width);

  logoutHandler = () => {
    this.props.userSignOut();
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      navCollapsed,

      width,
      activeMenu,
      match,

      user
    } = this.props;

    const routes = [
      {
        path: `${match.url}/manager/installation_items`,
        render: props => (
          <InstallationItems
            {...props}
            activeMenu={PageTitles.installationItems}
          />
        ),
        organisation: "any",
        roles: ["Customer"]
      },
      {
        path: `${match.url}/customers`,
        render: props => (
          <Customers {...props} activeMenu={PageTitles.customers} />
        ),
        organisation: "any",
        roles: ["Admin", "SuperAdmin", "Normal", "Customer"]
      },
      {
        path: `${match.url}/manager/computer_items`,
        render: props => (
          <ComputerItems {...props} activeMenu={PageTitles.computerItems} />
        ),
        organisation: "any",
        roles: ["Customer"]
      },
      {
        path: `${match.url}/packages`,
        render: props => (
          <StorePackages {...props} activeMenu={PageTitles.packages} />
        ),
        organisation: "default",
        roles: ["Admin", "SuperAdmin", "Normal", "APIClient", "Customer"]
      },
      {
        path: `${match.url}/users`,
        render: props => <Users {...props} activeMenu={PageTitles.admin} />,
        organisation: "any",
        roles: ["SuperAdmin"]
      },
      {
        path: `${match.url}/orgn`,
        render: props => (
          <Organisation {...props} activeMenu={PageTitles.orgn} />
        ),
        organisation: "default",
        roles: ["Admin", "SuperAdmin"]
      },
      {
        path: `${match.url}/settings/:userId`,
        render: props => (
          <UserProfile {...props} activeMenu={PageTitles.settings} />
        ),
        organisation: "any",
        roles: ["APIClient", "SuperAdmin", "Customer"]
      }
    ];
    return (
      <Responsive
        as="div"
        id="app-container"
        fireOnMount
        onUpdate={this.handlewidthChange}
      >
        <Sidebar
          onToggleNav={this.onToggleSidebar}
          navCollapsed={navCollapsed}
          activeMenu={activeMenu}
          width={width}
          menuItems={menuItems}
          authUser={user}
          // setAtiveMenu={this.props.setAtiveMenu}
        />
        <div
          id="app-main-content-wrapper"
          className={`app-main-nav ${navCollapsed ? "toggled " : ""}`}
        >
          <TopNav
            onToggleNav={this.onToggleSidebar}
            navCollapsed={navCollapsed}
            width={width}
            logoutHandler={this.logoutHandler}
            visible={this.state.visible}
            userSignOut={() => userManager.signoutRedirect()}
            activeMenu={activeMenu}
          />
          <div className={`main-content ${navCollapsed ? "m-rl " : "m-r"}`}>
            {/* put routes here  */}
            <div className="app-module-content">
              <Switch>
                {routes.map(_route => {
                  const canViewOrg =
                    Object.prototype.hasOwnProperty.call(
                      _route,
                      "organisation"
                    ) && Number(user.profile.org) === 1
                      ? ["default", "any"]
                      : ["any"];

                  if (
                    _route.roles.some(r => user.profile.role.includes(r)) &&
                    canViewOrg.includes(_route.organisation)
                  ) {
                    return (
                      <Route
                        key={_route.path}
                        path={_route.path}
                        render={_route.render}
                      />
                    );
                  }
                  return null;
                })}
                <Route component={PageNotFound} />
              </Switch>
              <ModalService />
            </div>
          </div>
          <Footer />
        </div>
      </Responsive>
    );
  }
}

const mapStateToProps = ({ settings, router, oidc }) => {
  // TODO: use username and other stuff in navbar
  const { navCollapsed, width, activeMenu } = settings;
  const { user } = oidc;
  const { location } = router;

  return {
    navCollapsed,
    location,
    width,
    activeMenu,
    user
  };
};
export default withRouter(
  connect(mapStateToProps, {
    onToggleSidebar,
    updateWindowWidth,
    setAtiveMenu,
    userSignOut
  })(App)
);
