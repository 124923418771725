import {
  FETCH_ALL_USER,
  FETCH_ALL_USER_SUCCESS,
  FILTER_USER,
  GET_ALL_USER,
  GET_UNSELECTED_ALL_USER,
  HANDLE_REQUEST_CLOSE,
  HIDE_USER_LOADER,
  ON_ADD_USER,
  ON_ALL_USER_SELECT,
  ON_USER_CLOSE,
  ON_USER_SELECT,
  ON_DELETE_USER,
  ON_DELETE_SELECTED_USER,
  ON_FILTER_OPTION_SELECT,
  ON_SAVE_USER,
  ON_TOGGLE_DRAWER,
  UPDATE_SEARCH_USER,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  SAVE_JOB,
  FETCH_CLIENT_DETAILS,
  FETCH_CLIENT_DETAILS_SUCCESS,
  FETCH_CLIENT_DETAILS_FAILURE,
  UPDATE_CLIENT_DETAILS,
  UPDATE_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_DETAILS_FAILURE,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  REMOVE_ADMIN_CUSTOMER,
  REMOVE_ADMIN_CUSTOMER_SUCCESS,
  REMOVE_ADMIN_CUSTOMER_FAILURE
} from "../constants/ActionTypes";

export const fetchUsers = filters => {
  return {
    type: FETCH_ALL_USER,
    payload: filters
  };
};

export const saveJobs = jobData => {
  return {
    type: SAVE_JOB,
    payload: jobData
  };
};

export const fetchUserSuccess = user => {
  return {
    type: FETCH_ALL_USER_SUCCESS,
    payload: user
  };
};

export const updateUser = (user, reloadCustomers = false) => {
  return {
    type: UPDATE_USER,
    user,
    reloadCustomers
  };
};

export const updateUserSuccess = () => {
  return {
    type: UPDATE_USER_SUCCESS
  };
};

export const deleteUser = user => {
  return {
    type: DELETE_USER,
    user
  };
};

export const deleteUserSuccess = id => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: id
  };
};

export const saveUser = user => {
  return {
    type: SAVE_USER,
    user
  };
};

export const saveUserSuccess = userData => {
  return {
    type: SAVE_USER_SUCCESS,
    payload: userData
  };
};

export const onUserSelect = data => {
  return {
    type: ON_USER_SELECT,
    payload: data
  };
};

export const onAddUser = () => {
  return {
    type: ON_ADD_USER,
    payload: ""
  };
};
export const onUserClose = () => {
  return {
    type: ON_USER_CLOSE,
    payload: ""
  };
};
export const onFilterOptionSelect = option => {
  return {
    type: ON_FILTER_OPTION_SELECT,
    payload: option
  };
};
export const onSaveUser = data => {
  return {
    type: ON_SAVE_USER,
    payload: data
  };
};
export const onDeleteUser = data => {
  return {
    type: ON_DELETE_USER,
    payload: data
  };
};
export const onDeleteSelectedUser = () => {
  return {
    type: ON_DELETE_SELECTED_USER,
    payload: ""
  };
};
export const filterUser = userName => {
  return {
    type: FILTER_USER,
    payload: userName
  };
};
export const getAllUser = () => {
  return {
    type: GET_ALL_USER,
    payload: ""
  };
};
export const getUnselectedAllUser = () => {
  return {
    type: GET_UNSELECTED_ALL_USER,
    payload: ""
  };
};
export const onAllUserSelect = () => {
  return {
    type: ON_ALL_USER_SELECT,
    payload: ""
  };
};
export const updateSearchUser = userName => {
  return {
    type: UPDATE_SEARCH_USER,
    payload: userName
  };
};
export const onToggleDrawer = () => {
  return {
    type: ON_TOGGLE_DRAWER
  };
};
export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE
  };
};
export const hideUserLoader = () => {
  return {
    type: HIDE_USER_LOADER
  };
};

export const fetchClientDetails = userId => {
  return {
    type: FETCH_CLIENT_DETAILS,
    payload: userId
  };
};

export const fetchClientDetailSuccess = client => {
  return {
    type: FETCH_CLIENT_DETAILS_SUCCESS,
    payload: client
  };
};

export const fetchClientDetailFailure = () => {
  return {
    type: FETCH_CLIENT_DETAILS_FAILURE
  };
};

export const updateClientDetails = apiClient => {
  return {
    type: UPDATE_CLIENT_DETAILS,
    payload: apiClient
  };
};

export const updateClientDetailSuccess = client => {
  return {
    type: UPDATE_CLIENT_DETAILS_SUCCESS,
    payload: client
  };
};

export const updateClientDetailFailure = () => {
  return {
    type: UPDATE_CLIENT_DETAILS_FAILURE
  };
};

export const fetchUserByID = userId => {
  return {
    type: GET_USER_BY_ID,
    payload: userId
  };
};

export const fetchUserByIDSuccess = user => {
  return {
    type: GET_USER_BY_ID_SUCCESS,
    payload: user
  };
};

export const fetchUserByIDFailure = () => {
  return {
    type: GET_USER_BY_ID_FAILURE
  };
};

export const removeAdminCustomer = userId => {
  return {
    type: REMOVE_ADMIN_CUSTOMER,
    payload: userId
  };
};

export const fremoveAdminCustomerSuccess = userId => {
  return {
    type: REMOVE_ADMIN_CUSTOMER_SUCCESS,
    payload: userId
  };
};

export const fremoveAdminCustomerFailure = () => {
  return {
    type: REMOVE_ADMIN_CUSTOMER_FAILURE
  };
};
