/* eslint-disable no-console */
/* eslint-disable no-empty */
import { all, call, put, takeLatest, select, fork } from "redux-saga/effects";
// import { delay } from "redux-saga";
import {
  fetchUserSuccess,
  fetchClientDetailSuccess,
  fetchClientDetailFailure,
  updateClientDetailSuccess,
  updateClientDetailFailure,
  fetchUserByIDSuccess,
  fetchUserByIDFailure
} from "../actions/User";
import {
  FETCH_ALL_USER,
  UPDATE_USER,
  SAVE_USER,
  DELETE_USER,
  SAVE_JOB,
  SIGNOUT_USER,
  FETCH_CLIENT_DETAILS,
  UPDATE_CLIENT_DETAILS,
  OauthSalt2,
  OauthSalt1,
  GENERATE_CREDENTIALS,
  GET_USER_BY_ID
} from "../constants/ActionTypes";
import { sha512 } from "js-sha512";
import { needRefreshToken } from "../util/checkTokenExpiration";
import { API, usersAPI } from "../api";
import { errorhandler } from "./error";
import {
  generateCredentailsSuccess,
  generateCredentailsFailure
} from "../actions";
import Notify from "../actions/Notify";
import { fetchCustomerRequest } from "./Customer";

// import { UserManager } from "oidc-client";
function* fetchUserRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const users = yield call(API.request, usersAPI.getAdmins(token, payload));

      yield put(fetchUserSuccess(users));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* generateCredentailsRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      yield call(API.request, usersAPI.generateCredentails(token, payload));

      yield put(generateCredentailsSuccess());

      Notify.success("Credentails Created!");
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    Notify.error("Create Credentails request failed!");
    yield put(generateCredentailsFailure());
    yield call(errorhandler, error);
  }
}

function* fetchClientDetailsRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    //const role = yield select(({ oidc }) => oidc.user.profile.role);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const selectedUser = yield select(
        ({ UserData }) => UserData.selectedUser
      );

      if (!selectedUser) {
        const userResponse = yield call(
          API.request,
          usersAPI.getUser(token, payload)
        );

        if (userResponse && userResponse.succeeded) {
          yield put(fetchUserByIDSuccess(userResponse.items));
        }
      }

      const checksumResponse = yield call(
        API.request,
        usersAPI.fetchChecksum(token, payload)
      );

      let client = {};
      if (checksumResponse && checksumResponse.succeeded) {
        var checksumPadded = OauthSalt2 + checksumResponse.items + OauthSalt1;
        const result = sha512(checksumPadded).toString();
        const clientId = result.substr(0, 25).toUpperCase();
        const clientSecret = result.substr(20, 45).toUpperCase();

        client = { ...client, clientId, clientSecret };
      }
      yield put(fetchClientDetailSuccess(client));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(fetchClientDetailFailure());
    yield call(errorhandler, error);
  }
}

function* updatelientDetailsRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const client = yield call(
        API.request,
        usersAPI.updateClientDetails(token, payload)
      );

      yield put(updateClientDetailSuccess(client));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(updateClientDetailFailure());
    yield call(errorhandler, error);
  }
}

function* updateUsersRequest({ user, reloadCustomers }) {
  try {
    console.log("Saga Update: ", user);
    delete user["adminRole"];
    delete user["isCustomer"];
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      yield call(API.request, usersAPI.updateUser(token, user));
      // yield put(updateUserSuccess());
      // yield fetchUserRequest();
      if (reloadCustomers) {
        yield fork(fetchCustomerRequest, {
          payload: { limit: 12 }
        });
      }
      const currentPage = yield select(
        ({ UserData }) => UserData.userList.page
      );
      yield fork(fetchUserRequest, {
        payload: { limit: 5, Page: currentPage }
      });
      Notify.success("User Details Successfully Updated");
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* saveUserRequest({ user }) {
  try {
    delete user["adminRole"];
    delete user["isCustomer"];
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const data = yield call(API.request, usersAPI.createUser(token, user));
      // yield put(saveUserSuccess(data));
      if (!data) new Error("Failed to save user");
      yield fork(fetchUserRequest, {
        payload: { limit: 5 }
      });
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* saveJobReqeust({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      yield call(API.request, usersAPI.createJob(token, payload));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* deleteUserRequest({ user }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      yield call(API.request, usersAPI.deleteUser(token, user.id));
      // yield put(deleteUserSuccess(user));
      yield fork(fetchUserRequest, {
        payload: { limit: 5 }
      });
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* findUserRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        usersAPI.findUser(token, payload)
      );

      if (response && response.succeeded) {
        yield put(fetchUserByIDSuccess(response.items));
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(fetchUserByIDFailure());
    yield call(errorhandler, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_ALL_USER, fetchUserRequest),
    takeLatest(UPDATE_USER, updateUsersRequest),
    takeLatest(SAVE_USER, saveUserRequest),
    takeLatest(DELETE_USER, deleteUserRequest),
    takeLatest(SAVE_JOB, saveJobReqeust),
    takeLatest(FETCH_CLIENT_DETAILS, fetchClientDetailsRequest),
    takeLatest(UPDATE_CLIENT_DETAILS, updatelientDetailsRequest),
    takeLatest(GENERATE_CREDENTIALS, generateCredentailsRequest),
    takeLatest(GET_USER_BY_ID, findUserRequest)
  ]);
}
