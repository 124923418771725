/* eslint-disable react/prop-types */
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Table, Grid, Button, Segment, Progress } from "semantic-ui-react";
// eslint-disable-next-line no-unused-vars

import { connect } from "react-redux";
import Pagination from "../../../../../components/Pagination";
import CustomScrollbars from "../../../../../util/CustomScrollbars";
import Loader from "../../../../../components/Loader";
import PackageItem from "./PackageItem";
import {
  fetchPUpload,
  onDownloadFIle,
  deletePUpload
} from "../../../../../actions";

class PrvPackages extends React.Component {
  componentDidMount() {
    this.props.fetchPUpload({ Limit: 12 });
  }

  componentWillUnmount() {}

  onDownload = fileName => {
    this.props.onDownloadFIle(fileName);
  };

  onDeleteItem = packageId => this.props.deletePUpload(packageId);
  render() {
    const {
      width,
      packages,
      isFetchingPrivPackages,
      progress,
      user
    } = this.props;

    return (
      <Grid.Row>
        <Grid.Column>
          {isFetchingPrivPackages ? <Loader /> : null}
          {progress > 0 ? (
            <Progress
              percent={Math.round(progress * 100, 2)}
              inverted
              indicating
              progress
            />
          ) : null}
          <CustomScrollbars
            className=""
            style={{
              height:
                width >= 1200 ? "calc(100vh - 334px)" : "calc(100vh - 354px)"
            }}
          >
            <Table padded basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Package Name</Table.HeaderCell>
                  <Table.HeaderCell>size</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {packages &&
                  Object.prototype.hasOwnProperty.call(packages, "items") &&
                  packages.items.map(_package => (
                    <PackageItem
                      key={_package.id}
                      _package={_package}
                      user={user}
                      onDeleteItem={this.onDeleteItem}
                      onDownload={this.onDownload}
                    />
                  ))}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan="5">
                    <Pagination
                      totalPages={
                        (packages &&
                          Object.prototype.hasOwnProperty.call(
                            packages,
                            "items"
                          ) &&
                          packages.totalPages) ||
                        1
                      }
                      limit={12}
                      handlePaginationChange={filters =>
                        this.props.fetchPUpload(filters)
                      }
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </CustomScrollbars>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

const mapStateToProps = ({ settings, pUploadData, oidc }) => {
  const { width } = settings;
  const { user } = oidc;
  const { isFetchingPrivPackages, packages, progress } = pUploadData;
  return {
    width,
    isFetchingPrivPackages,
    packages,
    progress,
    user
  };
};
export default connect(mapStateToProps, {
  fetchPUpload,
  onDownloadFIle,
  deletePUpload
})(PrvPackages);
