/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { Table, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { fetchComputerInstallations } from "../../../../../actions";
import Pagination from "../../../../../components/Pagination";
import CustomScrollbars from "../../../../../util/CustomScrollbars";
import Loader from "../../../../../components/Loader";
class ComputerInstallations extends React.Component {
  componentDidMount() {
    if (
      this.props.selectedComputerItem &&
      Object.prototype.hasOwnProperty.call(
        this.props.selectedComputerItem,
        "id"
      )
    )
      this.props.fetchComputerInstallations({
        computerId: this.props.selectedComputerItem.id,
        Limit: 12
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedComputerItem !== this.props.selectedComputerItem) {
      if (
        this.props.selectedComputerItem &&
        Object.prototype.hasOwnProperty.call(
          this.props.selectedComputerItem,
          "id"
        )
      )
        this.props.fetchComputerInstallations({
          computerId: this.props.selectedComputerItem.id,
          Limit: 12
        });
    }
  }
  render() {
    const {
      installationItems,
      isFetchingInstallationItems,
      width
    } = this.props;
    return (
      <Grid padded>
        <Grid.Column>
          {isFetchingInstallationItems ? <Loader /> : null}

          <Table selectable padded basic="very">
            <Table.Header>
              <Table.HeaderCell>InstallationName</Table.HeaderCell>
              <Table.HeaderCell>InstallationType</Table.HeaderCell>
              <Table.HeaderCell>SourceDir</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {installationItems &&
                Object.prototype.hasOwnProperty.call(
                  installationItems,
                  "items"
                ) &&
                installationItems.items.map(_installation => (
                  <Table.Row
                    className="tbl-pad"
                    style={{ padding: "20px" }}
                    key={_installation.id}
                  >
                    <Table.Cell>{_installation.installationName}</Table.Cell>
                    <Table.Cell>{_installation.installationType}</Table.Cell>
                    <Table.Cell>{_installation.sourceDir}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Pagination
                    totalPages={
                      installationItems &&
                      Object.prototype.hasOwnProperty.call(
                        installationItems,
                        "totalPages"
                      ) &&
                      installationItems.totalPages
                    }
                    limit={12}
                    handlePaginationChange={filters =>
                      this.props.fetchComputerInstallations({
                        ...filters,
                        computerId: this.props.selectedComputerItem.id
                      })
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = ({ esdActivityData, settings }) => {
  const { width } = settings;
  const {
    selectedComputerItem,
    installationItems,
    isFetchingInstallationItems
  } = esdActivityData;
  return {
    selectedComputerItem,
    installationItems,
    isFetchingInstallationItems,
    width
  };
};

export default connect(mapStateToProps, { fetchComputerInstallations })(
  ComputerInstallations
);
