import {
  HIDE_MESSAGE,
  INIT_URL,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SIGNIN_CUSTOMER_SUCCESS,
  SEND_RESET_LINK,
  SEND_RESET_LINK_SUCCESS,
  SEND_RESET_LINK_FAILED,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SIGNIN_USER_FAILED,
  SIGNIN_USER
} from "../constants/ActionTypes";

const INIT_STATE = {
  isProcessingRequest: false,
  errorOccurred: false,
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser:
    localStorage.getItem("provolve_auth_user") &&
    (localStorage.getItem("provolve_auth_user") !== undefined ||
      localStorage.getItem("provolve_auth_user") !== null)
      ? JSON.parse(localStorage.getItem("provolve_auth_user"))
      : null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      return { ...state, errorOccurred: false, alertMessage: "" };
    }

    case SEND_RESET_LINK: {
      return {
        ...state,
        isProcessingRequest: true
      };
    }

    case SEND_RESET_LINK_SUCCESS: {
      return {
        ...state,
        isProcessingRequest: false,
        errorOccurred: false,
        alertMessage: "Password reset link sent to email address"
      };
    }

    case SEND_RESET_LINK_FAILED: {
      return {
        ...state,
        isProcessingRequest: false,
        errorOccurred: true,
        alertMessage: action.payload
      };
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        isProcessingRequest: true
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isProcessingRequest: false
      };
    }

    case RESET_PASSWORD_FAILED: {
      return {
        ...state,
        isProcessingRequest: false,
        errorOccurred: true,
        alertMessage: action.payload
      };
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }

    case SIGNIN_USER: {
      return {
        ...state,
        isProcessingRequest: true
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        isProcessingRequest: false,
        authUser: action.payload,
        initURL: "/store/customers"
      };
    }

    case SIGNIN_USER_FAILED: {
      return {
        ...state,
        isProcessingRequest: false,
        alertMessage: action.payload,
        errorOccurred: true
      };
    }

    case SIGNIN_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        initURL: "/store/packages/private"
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: "/signin",
        loader: false,
        showMessage: false
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false
      };
    }

    // case ON_SHOW_LOADER: {
    //   return {
    //     ...state,
    //     loader: true
    //   };
    // }
    // case ON_HIDE_LOADER: {
    //   return {
    //     ...state,
    //     loader: false
    //   };
    // }
    default:
      return state;
  }
};
