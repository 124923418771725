import React from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";

const ActionItem = ({ action, onSelectAction }) => (
  <List.Item onClick={() => onSelectAction(action.name)}>
    <List.Icon name={action.icon} size="small" verticalAlign="middle" />
    <List.Content>
      <List.Header>{action.name}</List.Header>
    </List.Content>
  </List.Item>
);

ActionItem.propTypes = {
  action: PropTypes.object.isRequired,
  onSelectAction: PropTypes.func.isRequired
};
const ActionList = ({ actions, onSelectAction }) => (
  <List animated selection divided relaxed>
    {actions.map(action => (
      <ActionItem
        key={action.name}
        action={action}
        onSelectAction={onSelectAction}
      />
    ))}
  </List>
);

ActionList.propTypes = {
  actions: PropTypes.array.isRequired,
  onSelectAction: PropTypes.func.isRequired
};

export default ActionList;
