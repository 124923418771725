import queryString from "query-string";
import { API_URL_PUPLOAD } from "../constants/Api";
import API from "./api";

export const getPUploads = (token, filters) => {
  const params = queryString.stringify(filters);
  return {
    url: API_URL_PUPLOAD + `?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const downloadPupload = (token, file) => {
  return {
    url: API_URL_PUPLOAD + `download?file=${file}`,
    opt: API.requestOptions("GET", token)
  };
};

export const createPUpload = (token, formData) => {
  return {
    url: API_URL_PUPLOAD,
    opt: API.requestOptions("POST", token, null, { body: formData }, true)
  };
};

// export const updatePackage = (token, packageData) => {
//   const body = JSON.stringify(packageData);
//   return {
//     url: API_URL_PUPLOAD + packageData.id,
//     opt: API.requestOptions("PUT", token, null, { body })
//   };
// };

export const deletePUpload = (token, id) => {
  return {
    url: API_URL_PUPLOAD + id,
    opt: API.requestOptions("DELETE", token)
  };
};

export default {
  getPUploads,
  deletePUpload,
  downloadPupload,
  createPUpload
};
