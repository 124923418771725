/* eslint-disable react/prop-types */
import React from "react";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
// import IntlMessages from "../../../util/IntlMessages";

const Error404 = ({
  message = "Oops, Page not found!",
  buttonText = "Go to Home"
}) => (
  <Segment basic placeholder className="pageNotFound">
    <Header icon>
      <Icon name="search" />
      {message}
    </Header>
    <Button>{buttonText}</Button>
  </Segment>
);

export default Error404;
