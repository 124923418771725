//import Moment from "moment";
import {
  FETCH_ALL_PUPLOAD,
  FETCH_ALL_PUPLOAD_SUCCESS,
  DELETE_PUPLOAD_SUCCESS,
  UPDATE_PUPLOAD_SUCCESS,
  SAVE_PUPLOAD_SUCCESS,
  FILTER_PUPLOADS,
  ON_HIDE_LOADER,
  SHOW_MESSAGE,
  ON_EDIT_PUPLOAD,
  ON_OPEN_PUPLOAD_MODAL,
  ON_CLOSE_PUPLOAD_MODAL,
  UPDATE_UNASSIGNED_PUPLOAD_STATE,
  DOWNLOAD_PUPLOAD_SUCCESS,
  UPDATE_SEARCH_PUPLOAD,
  HANDLE_REQUEST_CLOSE,
  UPLOAD_PROGRESS,
  UPLOAD_FAILURE,
  UPLOAD_SUCCESS
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  packageNotFound: "No PUpload found",
  searchPUpload: "",
  alertMessage: "",
  showMessage: false,
  packages: { items: [], nextPage: null, previousPage: null, totalPages: null },
  packageList: {
    items: [],
    nextPage: null,
    previousPage: null,
    totalPages: null
  },
  addPUploadState: false,
  selectedPUpload: {},
  progress: 0,
  isFetchingPrivPackages: false
};

// var saveData = (function() {
//   var a = document.createElement("a");
//   document.body.appendChild(a);
//   a.style = "display: none";
//   return function(data, fileName) {
//     var url = window.URL.createObjectURL(data);
//     a.href = url;
//     a.download = fileName;
//     a.click();
//     window.URL.revokeObjectURL(url);
//   };
// })();

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_EDIT_PUPLOAD: {
      return {
        ...state,
        selectedPUpload: state.packageList.items.find(
          _package => _package.id === action.payload
        )
      };
    }

    case HANDLE_REQUEST_CLOSE: {
      return { ...state, showMessage: false };
    }

    case ON_OPEN_PUPLOAD_MODAL: {
      return {
        ...state,
        addPUploadState: true
      };
    }

    case ON_CLOSE_PUPLOAD_MODAL: {
      return {
        ...state,
        selectedPUpload: {},
        addPUploadState: false
      };
    }

    case UPLOAD_PROGRESS: {
      return {
        ...state,
        progress: action.payload
      };
    }
    case UPLOAD_FAILURE: {
      return {
        ...state,
        progress: 0,
        alertMessage: action.payload
      };
    }
    case UPLOAD_SUCCESS: {
      return {
        ...state,
        progress: 0
      };
    }
    case FILTER_PUPLOADS: {
      const { packageList } = state;
      if (action.payload === "") {
        return {
          ...state,
          packages: packageList
        };
      } else {
        return {
          ...state,
          packages: packageList.filter(
            _package =>
              _package.fileName
                .toLowerCase()
                .indexOf(action.payload.toLowerCase()) > -1
          )
        };
      }
    }

    case UPDATE_SEARCH_PUPLOAD: {
      return { ...state, searchPUpload: action.payload };
    }

    case SAVE_PUPLOAD_SUCCESS: {
      return {
        ...state,
        packageList: [...state.packageList, action.payload],
        packages: [...state.packageList, action.payload]
      };
    }

    case UPDATE_PUPLOAD_SUCCESS: {
      return {
        ...state,
        packageList: state.packageList,
        packages: state.packageList
      };
    }

    case UPDATE_UNASSIGNED_PUPLOAD_STATE: {
      let pUploads = state.packages.items.filter(
        _package => _package.id !== action.payload
      );
      return {
        ...state,
        packageList: { ...state.packageList, items: pUploads },
        packages: { ...state.packageList, items: pUploads }
      };
    }

    case DELETE_PUPLOAD_SUCCESS: {
      return {
        ...state,
        alertMessage: "PUpload Deleted Successfully",
        showMessage: true,
        packages: state.packages.filter(
          _package => _package.id !== action.payload
        ),
        packageList: state.packages.filter(
          _package => _package.id !== action.payload
        )
      };
    }

    case FETCH_ALL_PUPLOAD: {
      return {
        ...state,
        isFetchingPrivPackages: true
      };
    }

    case FETCH_ALL_PUPLOAD_SUCCESS: {
      return {
        ...state,
        packageList: action.payload,
        packages: action.payload,
        isFetchingPrivPackages: false
      };
    }

    // case FETCH_CUSTOMER_PUPLOADS_SUCCESS: {
    //   return {
    //     ...state,
    //     packageList: action.payload,
    //     packages: action.payload,
    //     alertMessage: "PUploads Listed Successfully",
    //     showMessage: true,
    //     loader: false
    //   };
    // }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }

    case ON_HIDE_LOADER: {
      return { ...state, loader: false };
    }

    case DOWNLOAD_PUPLOAD_SUCCESS: {
      // const { data, file } = action.payload;
      // saveData(data, file);
      return { ...state };
    }

    default:
      return state;
  }
};
