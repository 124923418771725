import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import {
  fetchOrgnUsers,
  openModalService,
  closeModalService,
  updateOrgn,
  deleteOrgn,
  getOrgn
} from "../../../../../actions";
import {
  Grid,
  Header,
  Button,
  Confirm,
  Divider,
  Segment
} from "semantic-ui-react";
import CustomTable from "../../../../../components/Table";
import Loader from "../../../../../components/Loader";
import EntryForm from "../../components/EntryForm";
import SearchBox from "../../../../../components/SearchBox";
import UserForm from "../../components/UserForm";

class OrgnDetails extends React.Component {
  state = {
    open: false,
    search: ""
  };
  componentDidMount() {
    // console.log("ID", this.props.match.params.orgnId);

    this.props.fetchOrgnUsers({
      orgnID: Number(this.props.match.params.orgnId),
      filters: { Limit: 10 }
    });

    this.props.getOrgn(this.props.match.params.orgnId);
  }

  onSearch = search => {
    this.setState({ search }, () => {
      this.props.fetchOrgnUsers({
        orgnID: Number(this.props.match.params.orgnId),
        filters: { Term: search, Limit: 10 }
      });
    });
  };

  navigateToView = id => {
    this.props.push(`/store/customers/${id}`);
  };

  handleAddOrganisation = orgn => {
    this.props.openModalService({
      children: (
        <EntryForm
          onSubmit={this.props.updateOrgn}
          closeModalService={this.props.closeModalService}
          organisation={orgn}
        />
      ),
      size: "tiny"
    });
  };

  handleAddOrganisationAdmin = () => {
    this.props.openModalService({
      children: <UserForm orgnId={Number(this.props.match.params.orgnId)} />,
      size: "tiny"
    });
  };

  show = () => this.setState({ open: true });
  handleConfirm = id =>
    this.setState({ open: false }, () => this.props.deleteOrgn(id));
  handleCancel = () => this.setState({ open: false });

  render() {
    const { navigateToView } = this;
    const columnnData = [
      { key: "firstName", name: "FirstName" },
      { key: "lastName", name: "LastName" },
      { key: "phoneNumber", name: "PhoneNumber" },
      { key: "email", name: "Email" },
      {
        key: "actions",
        name: "",
        custom(_item) {
          // console.log("_item", _item);
          return (
            <Button.Group size="small">
              <Button
                basic
                style={{ width: "auto" }}
                onClick={() => navigateToView(_item.id)}
                color="blue"
              >
                Details
              </Button>
            </Button.Group>
          );
        }
      }
    ];
    const { users, isProcessingOrgnRequest, organisation } = this.props;

    // console.log("results: ", result, isProcessingOrgnRequest);
    // const { items, totalPages } = result;
    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column>
            {isProcessingOrgnRequest && this.state.search === "" ? (
              <Loader />
            ) : null}
            <Segment basic clearing>
              <Header floated="left">
                {organisation && organisation.name}
                <Header.Subheader>
                  {organisation && organisation.address}
                </Header.Subheader>
                <Header.Subheader>
                  {organisation && organisation.contact}
                </Header.Subheader>
                <Header.Subheader>
                  {organisation && organisation.email}
                </Header.Subheader>
              </Header>
              <Header floated="right">
                <Button.Group size="small">
                  <Button
                    basic
                    color="orange"
                    style={{ width: "auto" }}
                    onClick={this.handleAddOrganisationAdmin}
                  >
                    Attach Admin
                  </Button>
                  <Button
                    basic
                    style={{ width: "auto" }}
                    color="blue"
                    onClick={() => this.handleAddOrganisation(organisation)}
                  >
                    Edit
                  </Button>

                  <Button
                    basic
                    style={{ width: "auto" }}
                    color="red"
                    disabled={
                      users &&
                      Object.prototype.hasOwnProperty.call(users, "items") &&
                      users.items.length > 0
                    }
                    onClick={this.show}
                  >
                    Delete
                  </Button>
                  <Confirm
                    open={this.state.open}
                    size="mini"
                    confirmButton="Delete"
                    onCancel={this.handleCancel}
                    onConfirm={() => this.handleConfirm(organisation.id)}
                  />
                </Button.Group>
              </Header>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {/*
        <Divider /> */}

        <Grid.Row>
          <Grid.Column width="16">
            {isProcessingOrgnRequest ? <Loader /> : null}
            <Divider />
            <Segment basic clearing>
              <Header
                floated="left"
                content="All registered users"
                subheader="List contains all users irrespective of the roles"
              />
              <Header as="div" floated="right">
                <SearchBox
                  placeholder="search by name"
                  onChange={evt => this.onSearch(evt.target.value)}
                  value={this.state.search}
                  style={{ fontSize: "16px" }}
                  inputStyles={{ width: "auto" }}
                />
              </Header>
            </Segment>
            <Divider />
            <Segment basic clearing>
              {users && Object.prototype.hasOwnProperty.call(users, "items") && (
                <CustomTable
                  columns={columnnData}
                  data={users.items}
                  dataKey="id"
                  totalPages={users.totalPages}
                  handlePaginationChange={filters =>
                    this.props.fetchOrgnUsers({
                      orgnID: Number(this.props.match.params.orgnId),
                      filters
                    })
                  }
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

OrgnDetails.propTypes = {
  match: PropTypes.object,
  fetchOrgnUsers: PropTypes.func,
  users: PropTypes.object,
  organisation: PropTypes.object,
  isProcessingOrgnRequest: PropTypes.bool,
  push: PropTypes.func,
  openModalService: PropTypes.func.isRequired,
  closeModalService: PropTypes.func.isRequired,
  updateOrgn: PropTypes.func.isRequired,
  deleteOrgn: PropTypes.func.isRequired,
  getOrgn: PropTypes.func.isRequired
};

const mapStateToProps = ({ orgnData }) => {
  const { isProcessingOrgnRequest, users, organisation } = orgnData;
  return { isProcessingOrgnRequest, users, organisation };
};

export default connect(mapStateToProps, {
  fetchOrgnUsers,
  push,
  openModalService,
  closeModalService,
  updateOrgn,
  deleteOrgn,
  getOrgn
})(OrgnDetails);
