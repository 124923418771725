/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Header, Icon, Segment } from "semantic-ui-react";

export const UXModulePlaceholder = () => {
  return (
    <Segment basic placeholder className="pageNotFound">
      <Header icon>
        <br />
        <br />
        <Icon name="warning" />
        <br />
        This page cannot be reached, either you're are not granted access or
        something went wrong!
        <br />
        <br />
        {/* This module is not available in the meantime, either work is in progress or something went wrong! */}
      </Header>
    </Segment>
  );
};

export default UXModulePlaceholder;
