// Customizer const
// Customizer const

export const OauthSalt1 = "KzVbXQgfgUutUTvbIAquug";
export const OauthSalt2 = "hiwfFjxjq02xQGY8bClLPA";

export const SWITCH_LANGUAGE = "switch-language";
export const TOGGLE_SIDEBAR = "on_toggle_sidebar";
export const WINDOW_WIDTH = "window-width";
export const ON_HIDE_LOADER = "on_hide_loader";

export const SHOW_MESSAGE = "show_message";
export const SET_ACTIVE_MENU = "set_active_menu";

//Contact Module const

// export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//User Module const
export const GET_USER_BY_ID = "get_user_by_id";
export const GET_USER_BY_ID_SUCCESS = "get_user_by_id_success";
export const GET_USER_BY_ID_FAILURE = "get_user_by_id_failure";

export const FETCH_ALL_USER = "fetch_all_user";
export const FETCH_ALL_USER_SUCCESS = "fetch_all_user_success";
export const UPDATE_USER = "update_user";
export const UPDATE_USER_SUCCESS = "update_user_success";
export const SAVE_USER = "save_user";
export const SAVE_USER_SUCCESS = "save_user_sucess";
export const DELETE_USER = "delete_user";
export const DELETE_USER_SUCCESS = "delete_user_sucess";
export const ON_USER_SELECT = "on_user_select";
export const ON_ADD_USER = "on_add_user";
export const ON_USER_CLOSE = "on_user_close";
export const ON_SAVE_USER = "on_save_user";
export const ON_DELETE_USER = "on_delete_user";
export const ON_DELETE_SELECTED_USER = "on_delete_selected_user";
export const FILTER_USER = "filter_user";
export const GET_ALL_USER = "get_all_user";
export const GET_UNSELECTED_ALL_USER = "get_unselected_all_user";
export const ON_ALL_USER_SELECT = "on_all_user_select";
export const HIDE_USER_LOADER = "hide_user_loader";
export const SAVE_JOB = "save_job";
export const SAVE_JOB_SUCCESS = "save_job_succes";

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNIN_USER_FAILED = "signin_user_failed";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const SIGNIN_CUSTOMER = "signin_customer";
export const SIGNIN_CUSTOMER_SUCCESS = "signin_customer_success";
export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCESS = "reset_password_success";
export const RESET_PASSWORD_FAILED = "reset_password_failed";
export const FETCH_USER_INFO = "fetch_user_info";
export const FETCH_USER_INFO_SUCCESS = "fetch_user_info_success";
export const FETCH_USER_INFO_FAILED = "fetch_user_info_failed";
export const SEND_RESET_LINK = "send_reset_link";
export const SEND_RESET_LINK_SUCCESS = "send_reset_link_success";
export const SEND_RESET_LINK_FAILED = "send_reset_link_failed";

export const ON_SELECT_USER = "on_select_user";

// Customer Module const

export const FETCH_ALL_CUSTOMER = "fetch_all_customer";
export const FETCH_ALL_CUSTOMER_PACKAGE = "fetch_all_customer_package";
export const FETCH_ALL_CUSTOMER_SUCCESS = "fetch_all_customer_success";
export const FETCH_ALL_CUSTOMER_PACKAGE_SUCCESS =
  "fetch_all_customer_package_success";

export const FETCH_CUSTOMER_PUB_PACKAGES = "fetch_customer_pub_packages";
export const FETCH_CUSTOMER_PUB_PACKAGES_SUCCESS =
  "fetch_customer_pub_packages_success";
export const FETCH_CUSTOMER_PRV_PACKAGES = "fetch_customer_prv_packages";
export const FETCH_CUSTOMER_PRV_PACKAGES_SUCCESS =
  "fetch_customer_prv_packages_success";

export const FILTER_CUSTOMERS = "filter_customers";
export const ON_SELECT_CUSTOMER = "on_select_customer";
export const CUSTOMER_INFO_STATE = "customer_info_state";
export const UPDATE_SEARCH_CUSTOMER = "update_search_customer";
export const SAVE_CUSTOMER = "save_customer";
export const SAVE_CUSTOMER_SUCCESS = "save_customer_sucess";
export const ADD_CUSTOMER_PACKAGE = "add_customer_package";
export const REMOVE_CUSTOMER_PACKAGE = "remove_customer_package";
export const ADD_CUSTOMER_PACKAGE_SUCCESS = "add_customer_package_success";
export const REMOVE_CUSTOMER_PACKAGE_SUCCESS =
  "remove_customer_package_success";
export const FETCH_UN_ASSIGENED_PACKAGES = "fetch_un_assigned_packages";
export const SWITCH_TABS = "switch_package_List_tabs";

export const ADD_CUSTOMER_PUPLOAD = "add_customer_pupload";
export const REMOVE_CUSTOMER_PUPLOAD = "remove_customer_pupload";
export const REMOVE_CUSTOMER_PUPLOAD_SUCCESS =
  "remove_customer_pupload_success";
export const ADD_CUSTOMER_PUPLOAD_SUCCESS = " add_customer_pupload_success";
export const UPDATE_SEARCH_CUSTOMER_PACKAGE = "update_search_customer_package";
export const UPDATE_SEARCH_CUSTOMER_PUPLOAD = "update_search_customer_pupload";
export const FILTER_CUSTOMER_PACKAGES = "filter_customer_packages";
export const FILTER_CUSTOMER_PUPLOADS = "filter_customer_puploads";
export const UPDATE_CUSTOMER = "update_customer";
export const UPDATE_CUSTOMER_SUCCESS = "update_customer_success";
export const REMOVE_CUSTOMER = "remove_customer";
export const REMOVE_CUSTOMER_SUCCESS = "remove_customer_success";

// Package Module const
export const FETCH_ALL_PACKAGE = "fetch_all_package";
export const FETCH_ALL_PACKAGE_SUCCESS = "fetch_all_package_success";
export const FILTER_PACKAGES = "filter_packages";
export const UPDATE_PACKAGE = "update_package";
export const UPDATE_PACKAGE_SUCCESS = "update_package_success";
export const SAVE_PACKAGE = "save_package";
export const SAVE_PACKAGE_SUCCESS = "save_package_sucess";
export const DELETE_PACKAGE = "delete_package";
export const DELETE_PACKAGE_SUCCESS = "delete_package_sucess";
export const ON_OPEN_PACKAGE_MODAL = "on_open_package_modal";
export const ON_CLOSE_PACKAGE_MODAL = "on_close_package_modal";
export const ON_EDIT_PACKAGE = "on_edit_package";
export const VIEW_PACKAGE_DETAIL = "view_package_detail";
export const UPDATE_UNASSIGNED_PACKAGE_STATE =
  "update_unassigned_packages_list";
//Customers
export const ON_SELECT_STORE = "on_select_store";
export const UPDATE_SEARCH_PACKAGE = "update_search_package";

// PUpload Module const
export const FETCH_ALL_PUPLOAD = "fetch_all_pupload";
export const FETCH_ALL_PUPLOAD_SUCCESS = "fetch_all_pupload_success";
export const FILTER_PUPLOADS = "filter_puploads";
export const UPDATE_PUPLOAD = "update_pupload";
export const UPDATE_PUPLOAD_SUCCESS = "update_pupload_success";
export const SAVE_PUPLOAD = "save_pupload";
export const SAVE_PUPLOAD_SUCCESS = "save_pupload_sucess";
export const DELETE_PUPLOAD = "delete_pupload";
export const DELETE_PUPLOAD_SUCCESS = "delete_pupload_sucess";
export const ON_OPEN_PUPLOAD_MODAL = "on_open_pupload_modal";
export const ON_CLOSE_PUPLOAD_MODAL = "on_close_pupload_modal";
export const ON_EDIT_PUPLOAD = "on_edit_pupload";
export const UPDATE_UNASSIGNED_PUPLOAD_STATE =
  "update_unassigned_puploads_list";
export const FETCH_UN_ASSIGENED_PUPLOADS = "fetch_unassigned_puploads_list";
export const DOWNLOAD_PUPLOAD = "download_pupload";
export const DOWNLOAD_PUPLOAD_SUCCESS = "download_pupload_success";
export const FETCH_CUSTOMER_PUPLOADS = "fetch_customer_puploads";
export const FETCH_CUSTOMER_PUPLOADS_SUCCESS =
  "fetch_customer_puploads_success";
export const UPDATE_SEARCH_PUPLOAD = "update_search_pupload";
export const UPLOAD_REQUEST = "UPLOAD_REQUEST";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAILURE = "UPLOAD_FAILURE";

// Modal service
export const OPEN_MODAL_SERVICE = "OPEN_MODAL_SERVICE";
export const CLOSE_MODAL_SERVICE = "CLOSE_MODAL_SERVICE";

// Confirm Alert Service
export const OPEN_CONFIRM_ALERT = "open_confirm_alert";
export const CLOSE_CONFIRM_ALERT = "close_confirm_alert";

// managing computer items
export const FETCH_COMPUTER_ITEMS = "fetch_computer_items";
export const FETCH_COMPUTER_ITEMS_SUCCESS = "fetch_computer_items_success";
export const UPDATE_COMPUTER_ITEM = "update_computer_item";
export const UPDATE_COMPUTER_ITEM_SUCCESS = "update_computer_items_success";
export const GET_COMPUTERS_WITH_INSTALLATION =
  "get_computers_with_installation";
export const GET_COMPUTERS_WITH_INSTALLATION_SUCCESS =
  "get_computers_with_installation_success";
export const FETCH_COMPUTER_INSTALLATIONS = "fetch_computer_installations";
export const FETCH_COMPUTER_INSTALLATIONS_SUCCESS =
  "fetch_computer_installations_success";
export const FETCH_INSTALLATION_ITEMS = "fetch_installation_items";
export const FETCH_INSTALLATION_ITEMS_SUCCESS =
  "fetch_installation_items_success";
export const ON_SELECT_COMPUTER = "on_select_computer";
export const ON_SELECT_INSTALLATION = "on_select_installation";

// Socket events
export const RECEIVED_MESSAGE = "GetBroadCastMessage";
export const GET_TEXT_STRING = "GetTextString";
export const GET_ESD_LOGS = "GetEsdLogs";
export const SEND_ESD_COMMAND = "SendComputerItemCommand";
export const SEND_INSTALLATION_ITEM_COMMAND_TO_MANAGER =
  "SendInstallationItemCommandToManager";
export const GET_ESD_COMMAND = "GetESDCommand";
export const RESET_ESD_LOGS = "reset_esd_logs";
export const SEND_ESD_PACKAGE_INSTALL_CMD = "SendEsdPackageInstallCommand";
export const GET_INSTALLATION_ITEM_REPORT = "GetInstallationItemReport";
export const SEND_ESD_REPORT_COMMAND = "SendEsdReportCommand";
export const GET_COMPUTER_ITEM_REPORT = "GetComputerItemReport";
export const GET_COMPUTER_STATUS = "GetComputerStatus";
export const GET_IP_LOOKUP_RESTULT = "GetIpLookupResult";
export const GET_USER_LOOKUP_RESULT = "GetUserLookupResult";

export const GET_SYNCED_COMPUTER_ITEM = "GetSyncedComputerItem";
export const GET_UPDATED_COMPUTER_ITEM = "GetUpdatedComputerItem";
export const REMOVE_DELETED_COMPUTER_ITEM = "RemoveDeletedComputerItem";
export const GET_SYNCED_INSTALLATION_ITEM = "GetSyncedInstallationItem";
export const GET_UPDATED_INSTALLATION_ITEM = "GetUpdatedInstallationItem";
export const REMOVE_DELETED_INSTALLATION_ITEM = "RemoveDeletedInstallationItem";
// Socket Service
export const START_CHANNEL = "start_channel";
export const STOP_CHANNEL = "stop_channel";
export const CHANNEL_ON = "channel_on";
export const CHANNEL_OFF = "channel_off";
export const SERVER_ON = "server_on";
export const SERVER_OFF = "server_off";

// orgaization
export const CREATE_ORGN = "create_orgn";
export const CREATE_ORGN_SUCCESS = "create_orgn_success";
export const CREATE_ORGN_FAILURE = "create_orgn_failure";

export const CREATE_ORGN_ADMIN = "create_orgn_admin";
export const CREATE_ORGN_ADMIN_SUCCESS = "create_orgn_admin_success";
export const CREATE_ORGN_ADMIN_FAILURE = "create_orgn_admin_failure";

export const FETCH_ORGN = "fetch_orgn";
export const FETCH_ORGN_SUCCESS = "fetch_orgn_success";
export const FETCH_ORGN_FAILURE = "fetch_orgn_failure";

export const UPDATE_ORGN = "update_orgn";
export const UPDATE_ORGN_SUCCESS = "update_orgn_success";
export const UPDATE_ORGN_FAILURE = "update_orgn_failure";

export const DELETE_ORGN = "delete_orgn";
export const DELETE_ORGN_SUCCESS = "delete_orgn_success";
export const DELETE_ORGN_FAILURE = "delete_orgn_failure";

export const FETCH_ORGN_USERS = "fetch_orgn_users";
export const FETCH_ORGN_USERS_SUCCESS = "fetch_orgn_users_success";
export const FETCH_ORGN_USERS_FAILURE = "fetch_orgn_users_failure";

export const RESET_ERRORS = " reset_errors";

export const FETCH_CLIENT_DETAILS = "fetch_client_details";
export const FETCH_CLIENT_DETAILS_SUCCESS = "fetch_client_details_success";
export const FETCH_CLIENT_DETAILS_FAILURE = "fetch_client_details_failure";

export const UPDATE_CLIENT_DETAILS = "update_client_details";
export const UPDATE_CLIENT_DETAILS_SUCCESS = "update_client_details_success";
export const UPDATE_CLIENT_DETAILS_FAILURE = "update_client_details_failure";

export const REMOVE_ADMIN_CUSTOMER = "remove_admin_customer";
export const REMOVE_ADMIN_CUSTOMER_SUCCESS = "remove_admin_customer_success";
export const REMOVE_ADMIN_CUSTOMER_FAILURE = "remove_admin_customer_failure";

// Remote Install
export const SEND_REMOTE_INSTALL = "send_remote_install";
export const SEND_REMOTE_INSTALL_SUCCESS = "send_remote_install_success";
export const SEND_REMOTE_INSTALL_FAILURE = "send_remote_install_failure";

export const FETCH_AGENT_SESSION_DATA = "fetch_agent_session_data";
export const FETCH_AGENT_SESSION_DATA_SUCCESS =
  "fetch_agent_session_data_success";
export const FETCH_AGENT_SESSION_DATA_FAILURE =
  "fetch_agent_session_data_failure";

export const FETCH_SESSION_ACTIVITY_INFO = "fetch_session_activity_info";
export const FETCH_SESSION_ACTIVITY_INFO_SUCCESS =
  "fetch_session_activity_info_success";
export const FETCH_SESSION_ACTIVITY_INFO_FAILURE =
  "fetch_session_activity_info_failure";

export const FETCH_USER_NOTIFICATION_SETTINGS =
  "fetch_user_notification_setting";
export const FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS =
  "fetch_user_notification_setting_success";
export const FETCH_USER_NOTIFICATION_SETTINGS_FAILURE =
  "fetch_user_notification_setting_failure";

export const UPDATE_USER_NOTIFICATION_SETTINGS =
  "update_user_notification_setting";
export const UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS =
  "update_user_notification_setting_success";
export const UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE =
  "update_user_notification_setting_failure";

export const GENERATE_SESSION_REPORT = "generate_xlsx_report";
export const GENERATE_SESSION_REPORT_SUCCESS = "generate_xlsx_report_success";
export const GENERATE_SESSION_REPORT_FAILURE = "generate_xlsx_report_failure";

export const GENERATE_CREDENTIALS = "generate_credentails";
export const GENERATE_CREDENTIALS_SUCCESS = "generate_credentails_success";
export const GENERATE_CREDENTIALS_FAILURE = "generate_credentails_failure";

export const SEND_REMOTE_INSTALL_REQUEST = "send_remote_install_request";
export const SEND_REMOTE_INSTALL_REQUEST_SUCCESS =
  "send_remote_install_request_success";
export const SEND_REMOTE_INSTALL_REQUEST_FAILURE =
  "send_remote_install_request_failure";

export const SEND_REMOTE_COMPUTER_REQUEST = "send_remote_computer_request";
export const SEND_REMOTE_COMPUTER_REQUEST_SUCCESS =
  "send_remote_computer_request_success";
export const SEND_REMOTE_COMPUTER_REQUEST_FAILURE =
  "send_remote_computer_request_failure";

export const SET_ACTIVE_LICENSE = "set_active_license";
export const SET_ACTIVE_LICENSE_SUCCESS = "set_active_license_success";
export const SET_ACTIVE_LICENSE_FAILURE = "set_active_license_failure";

export const GET_LICENSE = "get_license";
export const GET_LICENSE_SUCCESS = "get_license_success";
export const GET_LICENSE_FAILURE = "get_license_failure";

export const CREATE_LICENSE = "create_license";
export const CREATE_LICENSE_SUCCESS = "create_license_success";
export const CREATE_LICENSE_FAILURE = "create_license_failure";

export const GET_LICENSES = "get_licenses";
export const GET_LICENSES_SUCCESS = "get_licenses_success";
export const GET_LICENSES_FAILURE = "get_licenses_failure";

export const REMOVE_LICENSE = "remove_license";
export const REMOVE_LICENSE_SUCCESS = "remove_license_success";
export const REMOVE_LICENSE_FAILURE = "remove_license_failure";

export const GET_ORGN = "ger_orgn";
export const GET_ORGN_SUCCESS = "ger_orgn_success";
export const GET_ORGN_FAILURE = "ger_orgn_failure";

export const SEND_INVITE = "send_invite";
export const SEND_INVITE_SUCCESS = "send_invite_success";
export const SEND_INVITE_FAILED = "send_invite_failed";

export const FiND_PACKAGE_BY_ID = "find_package_by_id";
export const FiND_PACKAGE_BY_ID_SUCCESS = "find_package_by_id_success";
export const FiND_PACKAGE_BY_ID_FAILURE = "find_package_by_id_failure";

export const BULK_ASSIGN_PACKAGES = "bulkAssignPackages";
export const BULK_ASSIGN_PACKAGES_SUCCESS = "bulkAssignPackagesSuccess";
export const BULK_ASSIGN_PACKAGES_FAILURE = "bulkAssignPackagesFailure";

export const BULK_DETACH_PACKAGES = "bulkDetachPackages";
export const BULK_DETACH_PACKAGES_SUCCESS = "bulkDetachPackagesSuccess";
export const BULK_DETACH_PACKAGES_FAILURE = "bulkDetachPackagesFailure";
