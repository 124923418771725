/* eslint-disable no-unused-vars */
import { all, call, put, select, fork, takeLatest } from "redux-saga/effects";
import {
  fetchPackageSuccess,
  findPackageFailure,
  findPackageSuccess,
  showPackageMessage
} from "../actions";
import {
  FETCH_ALL_PACKAGE,
  UPDATE_PACKAGE,
  SAVE_PACKAGE,
  DELETE_PACKAGE,
  FETCH_UN_ASSIGENED_PACKAGES,
  SIGNOUT_USER,
  FiND_PACKAGE_BY_ID
} from "../constants/ActionTypes";
import Notify from "../actions/Notify";
import { needRefreshToken } from "../util/checkTokenExpiration";
import { API, packagesAPI, customersAPI } from "../api";
import { errorhandler } from "./error";
import { getActivePage } from "../selectors/customer";
import {
  getPackageListActivePage,
  getSelectedPackageSelector
} from "../selectors/package";
function* fetchPackageRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedPackages = yield call(
        API.request,
        packagesAPI.getPackages(token, payload)
      );
      yield put(fetchPackageSuccess(fetchedPackages));
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

export function* fetchUnAssagnedPackageRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedPackages = yield call(
        API.request,
        customersAPI.getUnAssignedPackages(token, payload)
      );
      yield put(fetchPackageSuccess(fetchedPackages));
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* updatePackagesRequest({ _package }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const updateResponse = yield call(
        API.request,
        packagesAPI.updatePackage(token, _package)
      );
      // TODO
      // yield put(updatePackageSuccess(res));
      // yield put(showPackageMessage("Package Successfully Updated"));

      if (updateResponse.succeeded) {
        Notify.success("Package Successfully Updated");
        const selectedPackage = yield select(getSelectedPackageSelector);

        if (
          selectedPackage &&
          Object.prototype.hasOwnProperty.call(selectedPackage, "id")
        ) {
          yield fork(findPackageRequest, {
            payload: selectedPackage.id
          });
        } else {
          const currentPage = yield select(
            ({ packageData }) => packageData.packageList.page
          );

          yield fork(fetchPackageRequest, {
            payload: { limit: 10, Page: currentPage }
          });
        }
      } else {
        Notify.success("Package Update Request failed!");
      }
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* savePackageRequest({ _package }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        packagesAPI.createPackage(token, _package)
      );
      // yield put(savePackageSuccess(response.value));
      // yield put(showPackageMessage("Package Successfully Added"));

      if (response.succeeded) {
        const selectedPackage = yield select(getSelectedPackageSelector);

        if (
          selectedPackage &&
          Object.prototype.hasOwnProperty.call(selectedPackage, "id")
        ) {
          yield fork(findPackageRequest, {
            payload: selectedPackage.id
          });
        } else {
          const currentPage = yield select(
            ({ packageData }) => packageData.packageList.page
          );

          yield fork(fetchPackageRequest, {
            payload: { limit: 10, Page: currentPage }
          });
        }
        Notify.success("Package Successfully Added");
      } else {
        Notify.success("Added Package Request failed");
      }
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* deletePackageRequest({ packageId }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      yield call(API.request, packagesAPI.deletePackage(token, packageId));
      yield put(showPackageMessage("Package Successfully Deleted"));
      // yield put(deletePackageSuccess(packageId));
      Notify.success("Package Successfully Deleted");

      const selectedPackage = yield select(getSelectedPackageSelector);

      if (
        selectedPackage &&
        Object.prototype.hasOwnProperty.call(selectedPackage, "id")
      ) {
        yield fork(findPackageRequest, {
          payload: selectedPackage.id
        });
      } else {
        const currentPage = yield select(
          ({ packageData }) => packageData.packageList.page
        );

        yield fork(fetchPackageRequest, {
          payload: { limit: 10, Page: currentPage }
        });
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* findPackageRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const packageResponse = yield call(
        API.request,
        packagesAPI.findPackage(token, payload)
      );

      if (packageResponse.succeeded) {
        yield put(findPackageSuccess(packageResponse.items));
      } else {
        yield put(findPackageFailure());
      }
    }

    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_ALL_PACKAGE, fetchPackageRequest),
    takeLatest(UPDATE_PACKAGE, updatePackagesRequest),
    takeLatest(SAVE_PACKAGE, savePackageRequest),
    takeLatest(DELETE_PACKAGE, deletePackageRequest),
    takeLatest(FETCH_UN_ASSIGENED_PACKAGES, fetchUnAssagnedPackageRequest),
    takeLatest(FiND_PACKAGE_BY_ID, findPackageRequest)
  ]);
}
