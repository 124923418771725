/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal, Input, Select } from "semantic-ui-react";
import {
  fetchComputerItems,
  sendRemoteInstallRequest,
  closeModalService
} from "../../../../actions";

import CustomInput from "../../../../components/InputField";

import { Formik } from "formik";
import * as Yup from "yup";

const entryScheme = Yup.object().shape({
  computerId: Yup.number()
    .required()
    .label("Computer"),
  user: Yup.string().nullable(),
  group: Yup.string().nullable()
});

const RemoteInstallForm = ({ installationItem, type, userId }) => {
  const dispatch = useDispatch();
  const { computerItems, isSendingInstallRequest } = useSelector(
    ({ esdActivityData }) => esdActivityData
  );

  useEffect(() => {
    dispatch(fetchComputerItems({ userId, Limit: 10000 }));
    return () => {};
  }, [dispatch, userId]);

  const handleSubmit = useCallback(
    data => {
      dispatch(sendRemoteInstallRequest(data));
    },
    [dispatch]
  );

  const computerOptions = computerItems.items
    .filter(_computer => _computer.onlineStatus === true)
    .map(_computer => ({
      key: _computer.id,
      text: _computer.computerName,
      value: _computer.id
    }));
  const handleClose = useCallback(() => {
    dispatch(closeModalService());
  }, [dispatch]);
  return (
    <React.Fragment>
      <Modal.Header>Remote Install</Modal.Header>
      <Modal.Content>
        <Formik
          // enableReinitialize={true}
          initialValues={{
            computerId: "",
            domainUser: "",
            domainUserGroup: ""
          }}
          validationSchema={entryScheme}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              if (type === "public") {
                handleSubmit({
                  ...values,
                  softwarePackageId: installationItem
                });
              } else {
                handleSubmit({ ...values, esdPackageId: installationItem });
              }

              actions.setSubmitting(false);
            }, 1000);
          }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <CustomInput
                id="computerId"
                name="computerId"
                label="Online Computers*"
                control={Select}
                options={computerOptions}
                onChange={(evt, data) =>
                  props.setFieldValue("computerId", data.value)
                }
                search
                placeholder="select computer"
              />
              <CustomInput
                name="domainUser"
                label="User (optional)"
                control={Input}
                placeholder="user (optional)"
              />
              <CustomInput
                name="domainUserGroup"
                control={Input}
                label="Group (optional)"
                placeholder="group (optional)"
              />
              <Button
                loading={isSendingInstallRequest}
                disabled={props.isSubmitting}
                type="submit"
              >
                Submit
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </React.Fragment>
  );
};

export default RemoteInstallForm;
