/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Modal, Button, Form, Input, Divider } from "semantic-ui-react";
import { connect } from "react-redux";

import { Formik } from "formik";
import * as Yup from "yup";
import { closeModalService, createOrgnAdmin } from "../../../../../actions";
import TextField from "../../../../../components/InputField";
const userSchema = Yup.object().shape({
  email: Yup.string()
    .label("Email")
    .email()
    .required(),
  firstName: Yup.string()
    .label("FirstName")
    .required(),
  lastName: Yup.string()
    .label("LastName")
    .required(),
  organisationId: Yup.number().required()
});

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        organisationId: ""
      }
    };
  }

  componentDidMount() {
    this.setState({
      user: {
        ...this.state.user,
        organisationId: this.props.orgnId
      }
    });
  }

  handleSubmit = values => {
    this.props.createOrgnAdmin(values);

    setTimeout(() => {
      this.onClose();
    }, 500);
  };

  onClose = () => {
    this.props.closeModalService();
  };

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <Modal.Header>Add Organisation Admin</Modal.Header>
        <Modal.Content>
          <Formik
            enableReinitialize={true}
            initialValues={user}
            validationSchema={userSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                this.handleSubmit(values);
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {props => (
              <Form
                onSubmit={props.handleSubmit}
                loading={this.props.isSubmitting}
              >
                <TextField
                  name="firstName"
                  type="text"
                  label="First name"
                  control={Input}
                  fluid
                />

                <TextField
                  name="lastName"
                  type="text"
                  label="Last name"
                  control={Input}
                  fluid
                />

                <TextField
                  name="email"
                  type="text"
                  label="Email"
                  control={Input}
                  fluid
                />

                <Divider />
                <Button.Group floated="right">
                  <Button negative onClick={this.onClose}>
                    Cancel
                  </Button>
                  <Button
                    positive
                    size="medium"
                    type="submit"
                    disabled={props.isSubmitting}
                  >
                    Submit
                  </Button>
                </Button.Group>
              </Form>
            )}
          </Formik>
        </Modal.Content>
        <Modal.Actions />
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  createOrgnAdmin,

  closeModalService
})(UserForm);
