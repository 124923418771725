import { toast, cssTransition } from "react-toastify";

const AnimationConfig = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  // default to 750ms, can be omitted
  duration: [400, 600]
});

const toastProps = { transition: AnimationConfig, autoClose: 2000 };

const Notify = {
  success: msg => {
    return toast.success(msg, toastProps);
  },
  error: msg => {
    return toast.error(msg, toastProps);
  },
  info: msg => {
    return toast.info(msg, toastProps);
  },
  dismiss: ref => {
    return toast.dismiss(ref, toastProps);
  }
};

export default Notify;
