import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { List, Modal, Button, Icon, Checkbox } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { selectCustomerData } from "../../../../../selectors/customer";
import {
  BulkAssignPackages,
  BulkDetachPackages,
  closeModalService,
  fetchCustomer
} from "../../../../../actions";
import { selectAuthUser } from "../../../../../selectors/auth";
import Pagination from "../../../../../components/Pagination";
import Loader from "../../../../../components/Loader";
import SearchFilter from "../../../../../components/SearchBox";

const textTruncate = (str, length, ending) => {
  if (length == null) {
    length = 35;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const CustomerListItem = ({ customer, isSelected, onSelectItem }) => {
  return (
    <List.Item
      as="a"
      active={isSelected}
      onClick={evt => onSelectItem(evt, customer.id)}
    >
      <List.Content>
        <List.Header as="h4">
          {customer.companyName && textTruncate(customer.companyName)}
        </List.Header>
        <List.Description as="p">
          {customer.firstName + " " + customer.lastName}
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

CustomerListItem.propTypes = {
  customer: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onSelectItem: PropTypes.func
};

const ItemCountPerPage = 8;

const CustomerList = ({ packageId, packageType, isAttaching = true }) => {
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { customers, isFetchingCustomers } = useSelector(selectCustomerData);
  const user = useSelector(selectAuthUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomer({ Limit: ItemCountPerPage }));
    return () => {};
  }, [dispatch, user]);

  useEffect(() => {
    setChecked(selected.length == 0);
  }, [selected]);

  useEffect(() => {
    if (checked) {
      setSelected([]);
    }
  }, [checked]);

  useEffect(() => {
    dispatch(fetchCustomer({ Limit: ItemCountPerPage, Term: searchText }));
  }, [searchText, dispatch]);

  const onSelectItem = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const onSubmit = useCallback(() => {
    if (isAttaching) {
      dispatch(
        BulkAssignPackages({ packageId, packageType, customerIds: selected })
      );
    } else {
      dispatch(
        BulkDetachPackages({ packageId, packageType, customerIds: selected })
      );
    }
  }, [isAttaching, dispatch, packageId, packageType, selected]);

  const onClose = () => dispatch(closeModalService());

  return (
    <React.Fragment>
      <Modal.Header>
        {isAttaching ? "Assign to" : "Detach from"} Customers
      </Modal.Header>
      <Modal.Content scrolling>
        {isFetchingCustomers && <Loader />}
        <SearchFilter
          value={searchText}
          onChange={evt => setSearchText(evt.target.value)}
        />

        <br />
        <Checkbox
          checked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
          fitted
          label="All Customers"
        />

        <List animated selection divided verticalAlign="middle">
          {customers.items.map(customer => {
            const customerSelected = isSelected(customer.id);
            return (
              <CustomerListItem
                key={customer.id}
                customer={customer}
                isSelected={customerSelected}
                onSelectItem={onSelectItem}
              />
            );
          })}
        </List>

        <Pagination
          totalPages={customers.totalPages}
          limit={ItemCountPerPage}
          handlePaginationChange={filters =>
            dispatch(fetchCustomer({ ...filters, Term: searchText }))
          }
        />
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button onClick={onClose}>Close</Button>
          <Button primary onClick={onSubmit}>
            Proceed <Icon name="right chevron" />
          </Button>
        </Button.Group>
      </Modal.Actions>
    </React.Fragment>
  );
};

CustomerList.propTypes = {
  packageId: PropTypes.number,
  packageType: PropTypes.number,
  isAttaching: PropTypes.bool
};
export default CustomerList;
