/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { put } from "redux-saga/effects";
// import { refreshToken } from "./auth";
import Notify from "../actions/Notify";
import { SIGNOUT_USER } from "../constants/ActionTypes";
export const errorhandler = function*(errorResponse, nextAction = null) {
  try {
    if (errorResponse && Object.keys(errorResponse)) {
      if (errorResponse instanceof Array) {
        Object.keys(errorResponse).forEach(key => {
          errorResponse[key].forEach(element => {
            let errMsg = element.toString();
            // Notify.error(errMsg);
            //console.log("errorResponse instanceof Array", errMsg);
          });
        });
      } else if (errorResponse instanceof Error) {
        // Notify.error(errorResponse.message);
      } else if (errorResponse instanceof Object) {
        if (
          Object.prototype.hasOwnProperty.call(errorResponse, "status") &&
          errorResponse.status === 401
        ) {
          yield put({ type: SIGNOUT_USER });
          //yield call(refreshToken, { nextAction });
        } else {
          if (Object.keys(errorResponse).length > 0) {
            Object.keys(errorResponse).forEach(key => {
              if (typeof errorResponse[key] === "string") {
                // Notify.error(errorResponse[key]);
              } else if (Array.isArray(errorResponse[key])) {
                errorResponse[key].forEach(msg => {
                  Notify.error(`${key}: ${msg}`);
                });
              }
            });
          } else {
            Notify.error(errorResponse);
          }
        }
      } else {
        // Notify.error("Unknown Error");
        // Notify.error("Oops, server didn't respond correctly!");
      }
    } 

    // if (errorResponse instanceof Array) {
    //   // console.log('Array responee', errorResponse);
    //   Object.keys(errorResponse).forEach(key => {
    //     errorResponse[key].forEach(element => {
    //       let errMsg = element.toString();
    //       if (process.env.NODE_ENV === "development") {
    //         errMsg = key + " : " + errMsg;
    //       }
    //       Notify.error(errMsg);
    //     });
    //   });
    // } else if (errorResponse instanceof Error) {
    //   Notify.error(errorResponse.message);
    // } else if (errorResponse instanceof Object) {
    //   if (
    //     Object.prototype.hasOwnProperty.call(errorResponse, "status") &&
    //     errorResponse.status === 401
    //   ) {
    //     yield put({ type: SIGNOUT_USER });
    //     //yield call(refreshToken, { nextAction });
    //   } else {
    //     if (Object.keys(errorResponse).length > 0) {
    //       Object.keys(errorResponse).forEach(key => {
    //         Notify.error(errorResponse[key]);
    //       });
    //     } else {
    //       Notify.error(errorResponse);
    //     }
    //   }
    // } else {
    //   Notify.error("Oops, server didn't respond correctly!");
    // }
  } catch (error) {
    // Notify.error(error);
  }
};
