import React from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";

const InstallationItem = ({
  installation,
  onSelectItem,
  selectedInstallationItem
}) => (
  <List.Item
    as="a"
    active={
      selectedInstallationItem
        ? selectedInstallationItem.id === installation.id
        : false
    }
    onClick={() => onSelectItem(installation)}
  >
    {/* <List.Icon
      name="installation"
      size="large"
      verticalAlign="middle"
      color={installation.onlineStatus ? "green" : "red"}
    />*/}
    <List.Content>
      <List.Header as="h4">{installation.installationName}</List.Header>
      {/*<List.Description>{installation.installationDesc}</List.Description>*/}
    </List.Content>
  </List.Item>
);

InstallationItem.propTypes = {
  installation: PropTypes.object,
  onSelectItem: PropTypes.func.isRequired,
  selectedInstallationItem: PropTypes.object
};

const InstallationList = ({
  installations,
  onSelectItem,
  selectedInstallationItem
}) => (
  <List animated selection divided verticalAlign="middle">
    {installations &&
      Object.prototype.hasOwnProperty.call(installations, "items") &&
      installations.items.map(installation => (
        <InstallationItem
          key={installation.id}
          installation={installation}
          onSelectItem={onSelectItem}
          selectedInstallationItem={selectedInstallationItem}
        />
      ))}
  </List>
);

InstallationList.propTypes = {
  installations: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  selectedInstallationItem: PropTypes.object
};

export default InstallationList;
