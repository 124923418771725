import {
  OPEN_MODAL_SERVICE,
  CLOSE_MODAL_SERVICE
} from "../constants/ActionTypes";

export function openModalService(props) {
  return {
    type: OPEN_MODAL_SERVICE,
    children: props.children,
    size: props.size,
    onClose: props.onClose
  };
}

export function closeModalService() {
  return {
    type: CLOSE_MODAL_SERVICE
  };
}
