import React from "react";
import PropTypes from "prop-types";
import { Icon, Input } from "semantic-ui-react";

const SearchBox = ({ placeholder, onChange, value, inputStyles, ...rest }) => {
  return (
    <Input
      iconPosition="left"
      placeholder={placeholder}
      onChange={onChange}
      icon
      fluid={inputStyles === undefined}
      className="search"
      {...rest}
    >
      <Icon name="search" />
      <input value={value} style={inputStyles} />
    </Input>
  );
};

SearchBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputStyles: PropTypes.object
};

SearchBox.defaultProps = {
  value: ""
};

export default SearchBox;
