export const PageTitles = {
  customers: "Customers",
  packages: "Packages",
  computerItems: "Computer Items",
  installationItems: "Installation Items",
  admin: "Admins",
  orgn: "Organisations",
  settings: "Settings"
};

export const roles = {
  admin: "Admin",
  superadmin: "SuperAdmin",
  normal: "Normal",
  customer: "Customer",
  apiClient: "APIClient"
};
