/* eslint-disable react/prop-types */
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Table, Grid } from "semantic-ui-react";
// eslint-disable-next-line no-unused-vars

import { connect } from "react-redux";

import CustomScrollbars from "../../../../../util/CustomScrollbars";
import Loader from "../../../../../components/Loader";
import Pagination from "../../../../../components/Pagination";
import PackageItem from "./PackageItem";
import {
  fetchCustomerPubPackages,
  onRemoveCustomerPackage,
  openModalService
} from "../../../../../actions";
import RemoteInstallForm from "../../components/RemoteInstallForm";

const pageLimit = 13;
class PubPackages extends React.Component {
  componentDidMount() {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id")
    ) {
      this.props.fetchCustomerPubPackages({
        id: this.props.selectedCustomer.id,
        Limit: pageLimit
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id") &&
      prevProps.selectedCustomer !== this.props.selectedCustomer
    ) {
      this.props.fetchCustomerPubPackages({
        id: this.props.selectedCustomer.id,
        Limit: pageLimit
      });
    }
  }

  componentWillUnmount() {}

  onDeleteItem = packageId =>
    this.props.onRemoveCustomerPackage(this.props.selectedCustomer, packageId);

  handleInstall = packageId =>
    this.props.openModalService({
      children: (
        <RemoteInstallForm
          installationItem={packageId}
          type="public"
          userId={this.props.selectedCustomer.id}
        />
      ),
      size: "tiny"
    });

  get isAdmin() {
    return (
      this.props.user.profile.role.includes("Admin") ||
      this.props.user.profile.role.includes("SuperAdmin") ||
      this.props.user.profile.role.includes("Normal")
    );
  }

  get isCustomer() {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id")
    ) {
      return (
        this.props.user.profile.role.includes("Customer") &&
        this.props.user.profile.sub === this.props.selectedCustomer.id
      );
    }
    return false;
  }
  render() {
    const { pubPackages, isFetchingCustomerPubPackages } = this.props;

    return (
      <Grid.Row>
        <Grid.Column>
          {isFetchingCustomerPubPackages ? <Loader /> : null}
          <CustomScrollbars
            className=""
            style={{
              height: "calc(100vh - 348px)"
            }}
          >
            <Table selectable padded basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Package Name</Table.HeaderCell>
                  <Table.HeaderCell>Version</Table.HeaderCell>
                  <Table.HeaderCell>Platform</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {pubPackages.items.map(_package => (
                  <PackageItem
                    key={_package.id}
                    _package={_package}
                    isCustomer={this.isCustomer}
                    isAdmin={this.isAdmin}
                    handleInstall={this.handleInstall}
                    onDeleteItem={this.onDeleteItem}
                  />
                ))}
              </Table.Body>
            </Table>
          </CustomScrollbars>
          <Pagination
            totalPages={pubPackages.totalPages}
            limit={pageLimit}
            handlePaginationChange={filters =>
              this.props.fetchCustomerPubPackages({
                id:
                  this.props.selectedCustomer &&
                  Object.prototype.hasOwnProperty.call(
                    this.props.selectedCustomer,
                    "id"
                  ) &&
                  this.props.selectedCustomer.id,
                ...filters
              })
            }
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

const mapStateToProps = ({ settings, customerData, oidc }) => {
  const { width } = settings;
  const { user } = oidc;
  const {
    isFetchingCustomerPubPackages,
    selectedCustomer,
    pubPackages
  } = customerData;
  return {
    user,
    width,
    isFetchingCustomerPubPackages,
    selectedCustomer,
    pubPackages
  };
};
export default connect(mapStateToProps, {
  fetchCustomerPubPackages,
  onRemoveCustomerPackage,
  openModalService
})(PubPackages);
