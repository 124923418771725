/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Menu, Header, Button, Icon } from "semantic-ui-react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import PageNotFound from "../../404";
import ComputerInstallations from "./ComputerInstallations";
import ComputerDetails from "./ComputerDetails";
import { onSelectComputer } from "../../../../actions";
import CustomScrollbars from "../../../../util/CustomScrollbars";
class ComputerDetail extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.computerItems !== this.props.computerItems) {
      const selectedComputer = this.props.computerItems.items.find(
        _computer => _computer.id === Number(this.props.match.params.computerId)
      );
      this.props.onSelectComputer(selectedComputer);
    }
  }
  render() {
    const { match, selectedComputerItem, width, location } = this.props;
    const { pathname } = location;
    return (
      <React.Fragment>
        <div className="module-box-header">
          <Menu secondary>
            <Menu.Item>
              <Header as="h3">
                {selectedComputerItem ? selectedComputerItem.computerName : ""}
                <Header.Subheader>
                  {selectedComputerItem
                    ? selectedComputerItem.computerDesc
                    : ""}
                </Header.Subheader>
              </Header>
            </Menu.Item>

            <Menu.Menu position="right">
              <Menu.Item>
                <Button.Group basic>
                  {/* <Button>Rename</Button>*/}
                  {pathname ===
                  `/store/manager/computer_items/${
                    selectedComputerItem ? selectedComputerItem.id : ""
                  }/inst_items` ? (
                    <Button
                      as={Link}
                      basic
                      color="blue"
                      to={`/store/manager/computer_items/${
                        selectedComputerItem ? selectedComputerItem.id : ""
                      }/details`}
                    >
                      <Icon name="computer" />
                      Computer Details
                    </Button>
                  ) : (
                    <Button
                      as={Link}
                      basic
                      color="blue"
                      to={`/store/manager/computer_items/${
                        selectedComputerItem ? selectedComputerItem.id : ""
                      }/inst_items`}
                    >
                      <Icon name="archive" />
                      Installation Items
                    </Button>
                  )}
                </Button.Group>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        <div className="module-box-content">
          <CustomScrollbars
            style={{
              height:
                width >= 1200 ? "calc(100vh - 220px)" : "calc(100vh - 205px)"
            }}
          >
            <Switch>
              <Redirect
                exact
                from={`${match.url}/`}
                to={`${match.url}/details`}
              />
              <Route
                exact
                path={`${match.url}/details`}
                component={ComputerDetails}
              />
              <Route
                exact
                path={`${match.url}/inst_items`}
                component={ComputerInstallations}
              />
              <Route component={PageNotFound} />
            </Switch>
          </CustomScrollbars>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ esdActivityData, settings, router }) => {
  const { width } = settings;
  const { location } = router;
  const { selectedComputerItem, computerItems } = esdActivityData;
  return { selectedComputerItem, computerItems, width };
};

export default connect(mapStateToProps, { onSelectComputer })(ComputerDetail);
