/* eslint-disable react/prop-types */
import React from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";

const textTruncate = (str, length, ending) => {
  if (length == null) {
    length = 33;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

const CustomerItem = ({ customer, onSelectCustomer, selectedCustomer }) => (
  <List.Item
    as="a"
    active={
      selectedCustomer &&
      Object.prototype.hasOwnProperty.call(selectedCustomer, "id")
        ? selectedCustomer.id === customer.id
        : false
    }
    onClick={() => onSelectCustomer(customer)}
  >
    <List.Content>
      <List.Header as="h4">
        {customer.companyName && textTruncate(customer.companyName)}
      </List.Header>
      <List.Description as="p">
        {customer.firstName + " " + customer.lastName}
      </List.Description>
    </List.Content>
  </List.Item>
);

CustomerItem.propTypes = {
  customer: PropTypes.object.isRequired,
  onSelectCustomer: PropTypes.func.isRequired
};
export default CustomerItem;
