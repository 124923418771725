import React from "react";
import PropTypes from "prop-types";
import { Table, Button } from "semantic-ui-react";

const PackageItem = ({
  _package,
  onDeleteItem,
  handleInstall,
  isCustomer,
  isAdmin
}) => {
  return (
    <Table.Row className="tbl-pad">
      <Table.Cell>{_package.fileName}</Table.Cell>
      <Table.Cell>{_package.version}</Table.Cell>
      <Table.Cell>{_package.platform ? "x64" :  "x86"}</Table.Cell>
      <Table.Cell>{_package.category ? "Generic" : "Specific"}</Table.Cell>

      <Table.Cell textAlign="right">
        <Button.Group size="tiny">
          {isCustomer && (
            <Button
              onClick={() => handleInstall(_package.id)}
              basic
              color="blue"
            >
              Remote Install
            </Button>
          )}
          {isAdmin && (
            <Button onClick={() => onDeleteItem(_package.id)} basic color="red">
              Delete
            </Button>
          )}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );
};

PackageItem.propTypes = {
  _package: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  handleInstall: PropTypes.func,
  isCustomer: PropTypes.bool,
  isAdmin: PropTypes.bool
};

export default PackageItem;
