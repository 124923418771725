/* eslint-disable react/display-name */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";

import { Form } from "semantic-ui-react";

const TextField = React.forwardRef(({ label, control, ...props }, ref) => {
  const [field, meta] = useField(props);

  const inputRef = useRef(null).current;

  useEffect(() => {
    if (!ref) return;
    typeof ref === "function" ? ref(inputRef) : (ref.current = inputRef);
    return () => (typeof ref === "function" ? ref(null) : (ref.current = null));
  }, [inputRef, ref]);

  return (
    <Form.Field
      ref={inputRef}
      {...field}
      {...props}
      control={control}
      label={label}
      error={meta.error && meta.touched ? { content: meta.error } : null}
      //   style={{ backgroundColor: "#F8F7F2" }}
    />
  );
});

TextField.propTypes = {
  label: PropTypes.string,
  //   isRow: PropTypes.bool,
  control: PropTypes.any
};

export default TextField;
