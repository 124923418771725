import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Segment,
  Table,
  Input,
  Header,
  Select
} from "semantic-ui-react";
import moment from "moment";
import { updateUserNotificationSettings } from "../../../../../actions";

const frequencyOptions = [
  { key: 0, text: "Daily", value: 0 },
  { key: 1, text: "Weekly", value: 1 },
  { key: 2, text: "Monthy", value: 2 },
  { key: 3, text: "Yearly", value: 3 }
];

// eslint-disable-next-line react/prop-types
const SettingsPage = ({ closeModalService }) => {
  const { notificationSettings, isUpdatingNotificationSettings } = useSelector(
    ({ esdActivityData }) => esdActivityData
  );
  const [settings, setSettings] = useState(notificationSettings);
  const [isEditting, setIsEditing] = useState(false);

  const toggleEdit = useCallback(() => setIsEditing(!isEditting), [isEditting]);

  useEffect(() => {
    setSettings(notificationSettings);
    return () => {};
  }, [notificationSettings, toggleEdit]);
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (field, value) => {
      setSettings({ ...settings, [field]: value });
    },
    [settings]
  );

  const handleSubmit = useCallback(
    evt => {
      evt.preventDefault();
      dispatch(updateUserNotificationSettings(settings));
    },
    [settings, dispatch]
  );

  return (
    <Segment padded="very" textAlign="center">
      <Table basic="very" singleLine collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Settings</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                onClick={() => toggleEdit()}
                basic
                size="mini"
                icon="edit"
                content="Edit"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>Email Address: </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell />
            <Table.Cell>
              {isEditting ? (
                <Input
                  name="recipientEmail"
                  type="email"
                  required
                  value={settings.recipientEmail}
                  onChange={evt =>
                    handleChange("recipientEmail", evt.target.value)
                  }
                />
              ) : (
                settings.recipientEmail
              )}{" "}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>
                  Notification Frequency:
                  <Header.Subheader>
                    Options: Daily, Weekly, Monthly and Yearly
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell />
            <Table.Cell>
              {isEditting ? (
                <Select
                  required
                  name="emailSendOutFrequency"
                  options={frequencyOptions}
                  placeholder="Frequency"
                  value={settings.emailSendOutFrequency}
                  onChange={(evt, data) =>
                    handleChange("emailSendOutFrequency", data.value)
                  }
                />
              ) : (
                frequencyOptions.find(
                  i => i.value === settings.emailSendOutFrequency
                ).text
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>Next trigger Date: </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell />
            <Table.Cell>
              {isEditting ? (
                <Input
                  name="nextTrigger"
                  type="date"
                  required
                  value={settings.nextTrigger}
                  onChange={evt =>
                    handleChange("nextTrigger", evt.target.value)
                  }
                />
              ) : (
                moment(settings.nextTrigger).format("YYYY-MM-DD HH:mm")
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>Previous trigger Date:</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell />
            <Table.Cell>
              {moment(settings.prevTrigger).format("YYYY-MM-DD HH:mm")}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <Button.Group basic>
        <Button
          onClick={handleSubmit}
          loading={isUpdatingNotificationSettings}
          basic
          color="blue"
        >
          Save
        </Button>
        <Button basic color="red" onClick={closeModalService}>
          Close
        </Button>
      </Button.Group>
    </Segment>
  );
};

export default SettingsPage;
