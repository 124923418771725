import React from "react";
import PropTypes from "prop-types";
import { Table, Button } from "semantic-ui-react";

const bytesToSize = bytes => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
};

const PackageItem = ({
  _package,
  onDeleteItem,
  onDownloadItem,
  handleInstall,
  isCustomer,
  isAdmin
}) => (
  <Table.Row className="tbl-pad">
    <Table.Cell>{_package.fileName}</Table.Cell>
    <Table.Cell>{bytesToSize(parseInt(_package.size))}</Table.Cell>
    <Table.Cell textAlign="right">
      <Button.Group size="tiny">
        {isCustomer && (
          <Button onClick={() => handleInstall(_package.id)} basic color="blue">
            Remote Install
          </Button>
        )}
        <Button
          basic
          color="orange"
          onClick={() => onDownloadItem(_package.fileName)}
        >
          Download
        </Button>
        {isAdmin && (
          <Button onClick={() => onDeleteItem(_package)} basic color="red">
            Delete
          </Button>
        )}
      </Button.Group>
    </Table.Cell>
  </Table.Row>
);

PackageItem.propTypes = {
  _package: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onDownloadItem: PropTypes.func.isRequired,
  handleInstall: PropTypes.func,
  isAdmin: PropTypes.bool,
  isCustomer: PropTypes.bool
};

export default PackageItem;
