import queryString from "query-string";
import {
  API_URL_USERS,
  API_URL_CREATE_USER,
  API_URL_CLIENT,
  API_URL_USERS_ID_SERVER,
  API_URL
} from "../constants/Api";
import API from "./api";

export const getAdmins = (token, filters) => {
  const params = queryString.stringify(filters);
  return {
    url: `${API_URL_USERS}admins?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const getUser = (token, userId) => {
  return {
    url: API_URL.SERVER + "/user/" + userId,
    opt: API.requestOptions("GET", token)
  };
};

export const findUser = (token, userId) => {
  return {
    url: API_URL.SERVER + "/user/" + userId,
    opt: API.requestOptions("GET", token)
  };
};

export const generateCredentails = (token, userId) => {
  return {
    url: API_URL_USERS_ID_SERVER + "credentails/" + userId,
    opt: API.requestOptions("GET", token)
  };
};

export const createJob = (token, jobs) => {
  const body = JSON.stringify(jobs);
  return {
    url: API_URL_USERS + "createJob",
    opt: API.requestOptions("POST", token, null, { body })
  };
};

export const createUser = (token, userData) => {
  const body = JSON.stringify(userData);
  return {
    url: API_URL.SERVER + "/user",
    opt: API.requestOptions("POST", token, null, { body })
  };
};

export const createUserInOrg = (token, userData, orgnId) => {
  delete userData.orgnId;
  const body = JSON.stringify(userData);
  return {
    url: API_URL_CREATE_USER + "user/" + orgnId,
    opt: API.requestOptions("POST", token, null, { body })
  };
};

export const updateUser = (token, userData) => {
  const body = JSON.stringify({ ...userData });
  return {
    url: API_URL_USERS_ID_SERVER + userData.id,
    opt: API.requestOptions("PUT", token, {}, { body })
  };
};

export const deleteUser = (token, id) => {
  return {
    url: API_URL_USERS_ID_SERVER + id,
    opt: API.requestOptions("DELETE", token)
  };
};

export const deleteAdminUser = (token, id) => {
  return {
    url: API_URL_USERS_ID_SERVER + "removeAdminCustomer/" + id,
    opt: API.requestOptions("DELETE", token)
  };
};

export const fetchClientDetails = (token, userId) => {
  return {
    url: API_URL_CLIENT + userId,
    opt: API.requestOptions("GET", token)
  };
};

export const updateClientDetails = (token, clientObj) => {
  const body = JSON.stringify({ ...clientObj });
  return {
    url: API_URL_CLIENT + clientObj.id,
    opt: API.requestOptions("PUT", token, {}, { body })
  };
};

export const fetchChecksum = (token, userId) => {
  return {
    url: `${API_URL.SERVER}/license/${userId}/checksum`,
    opt: API.requestOptions("GET", token)
  };
};

// export const getAdminUsers = token => {
//   return {
//     url: API_URL_USERS,
//     opt: API.requestOptions("GET", token)
//   };
// };

export default {
  getUser,
  getAdmins,
  createJob,
  createUser,
  deleteUser,
  updateUser,
  createUserInOrg,
  fetchClientDetails,
  updateClientDetails,
  deleteAdminUser,
  generateCredentails,
  findUser,
  fetchChecksum
};
