/* eslint-disable no-console */
import { all, call, put, takeLatest, select, fork } from "redux-saga/effects";
import { push } from "connected-react-router";
import { saveAs } from "file-saver";
import {
  fetchComputerItemsSuccess,
  fetchInstallationItemsSuccess,
  fetchComputerInstallationsSuccess,
  getComputersWithInstallationSuccess,
  fetchSessionDataSuccess,
  fetchSessionActivityInfoSuccess,
  fetchUserNotificationSettingsSuccess,
  updateUserNotificationSettingsSuccess,
  generateXlsxReportSuccess,
  generateXlsxReportFailure,
  updateUserNotificationSettingsFailure,
  fetchSessionActivityInfoFailure,
  fetchSessionDataFailure,
  sendRemoteInstallRequestSuccess,
  sendRemoteInstallRequestFailure,
  sendRemoteComputerRequestFailure,
  sendRemoteComputerRequestSuccess
} from "../actions/esdActivity";
import {
  SIGNOUT_USER,
  FETCH_INSTALLATION_ITEMS,
  GET_COMPUTERS_WITH_INSTALLATION,
  FETCH_COMPUTER_INSTALLATIONS,
  FETCH_COMPUTER_ITEMS,
  REMOVE_DELETED_INSTALLATION_ITEM,
  REMOVE_DELETED_COMPUTER_ITEM,
  FETCH_AGENT_SESSION_DATA,
  FETCH_SESSION_ACTIVITY_INFO,
  FETCH_USER_NOTIFICATION_SETTINGS,
  UPDATE_USER_NOTIFICATION_SETTINGS,
  GENERATE_SESSION_REPORT,
  SEND_REMOTE_INSTALL_REQUEST,
  SEND_REMOTE_COMPUTER_REQUEST
} from "../constants/ActionTypes";
import { needRefreshToken } from "../util/checkTokenExpiration";
import { API, esdActivityAPI } from "../api";
import { getSelectedCustomer } from "../selectors/customer";
import { errorhandler } from "./error";
import Notify from "../actions/Notify";
function* fetchComputerItemsRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedComputerItems = yield call(
        API.request,
        esdActivityAPI.getComputerItems(token, payload)
      );

      yield put(fetchComputerItemsSuccess(fetchedComputerItems));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* fetchComputerInstallationsRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedInstallations = yield call(
        API.request,
        esdActivityAPI.getComputerInstallations(token, payload)
      );

      yield put(fetchComputerInstallationsSuccess(fetchedInstallations));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* fetchComputerWithInstallationsRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedComputerItems = yield call(
        API.request,
        esdActivityAPI.getComputerWithInstallations(token, payload)
      );

      yield put(getComputersWithInstallationSuccess(fetchedComputerItems));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* fetchInstallationItemsRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedInstallations = yield call(
        API.request,
        esdActivityAPI.getInstallationItems(token, payload)
      );

      yield put(fetchInstallationItemsSuccess(fetchedInstallations));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

const ReloadComputerItemsRequest = function*() {
  try {
    const userId = yield select(({ oidc }) => oidc.user.profile.sub);
    yield put(push("/store/manager/computer_items"));
    yield fork(fetchComputerItemsRequest, {
      payload: {
        userId: userId,
        Limit: 10000
      }
    });
  } catch (error) {
    yield call(errorhandler, error);
  }
};

const ReloadInstallationItemsRequest = function*() {
  try {
    const userId = yield select(({ oidc }) => oidc.user.profile.sub);
    yield put(push("/store/manager/installation_items"));
    yield fork(fetchInstallationItemsRequest, {
      payload: {
        userId: userId,
        Limit: 10000
      }
    });
  } catch (error) {
    yield call(errorhandler, error);
  }
};

const FetchSessionDataRequest = function*({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedInstallations = yield call(
        API.request,
        esdActivityAPI.fetchSessionData(token, payload)
      );

      yield put(fetchSessionDataSuccess(fetchedInstallations));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(fetchSessionDataFailure());
    yield call(errorhandler, error);
  }
};

const FetchSessionActivityDataRequest = function*({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedInstallations = yield call(
        API.request,
        esdActivityAPI.fetchAgentSessionData(token, payload)
      );

      yield put(fetchSessionActivityInfoSuccess(fetchedInstallations));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(fetchSessionActivityInfoFailure());
    yield call(errorhandler, error);
  }
};

const FetchUserNotificationSettingRequest = function*({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        esdActivityAPI.fetchUserNotificationSettings(token, payload.id)
      );

      if (response.succeeded) {
        yield put(fetchUserNotificationSettingsSuccess(response.items));
      } else {
        Notify.error("Failed for retrieve User Email notification settings!");
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
};

const updateUserNotificationSettingRequest = function*({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        esdActivityAPI.updateUserNotificationSettings(token, payload)
      );

      if (response.succeeded) {
        yield put(updateUserNotificationSettingsSuccess(response.items));
        Notify.success("Settings successfully Updated!");
      } else {
        yield put(updateUserNotificationSettingsFailure());
        Notify.error("Update Request failed");
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
};

function* generateXlsxReportRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const report = yield call(API.request, {
        ...esdActivityAPI.generateXlsxReport(token, payload),
        isFile: true
      });
      var customer = yield select(getSelectedCustomer);
      saveAs(report, `${customer.companyName}-Report.xlsx`);
      yield put(generateXlsxReportSuccess());
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(generateXlsxReportFailure());
    yield call(errorhandler, error);
  }
}

function* sendRemoteComputerApiRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const userID = yield select(({ oidc }) => oidc.user.profile.sub);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        esdActivityAPI.sendRemoteComputerCmd(token, payload, userID)
      );
      console.log(response);
      yield put(sendRemoteComputerRequestSuccess());
      // Notify.success("Install request successfully initiated!");
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(sendRemoteComputerRequestFailure());
    yield call(errorhandler, error);
  }
}

function* sendRemoteInstallApiRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const userID = yield select(({ oidc }) => oidc.user.profile.sub);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        esdActivityAPI.sendRemoteInstallCmd(token, payload, userID)
      );
      console.log(response);
      Notify.success("Install request successfully initiated!");
      yield put(sendRemoteInstallRequestSuccess());
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield put(sendRemoteInstallRequestFailure());
    yield call(errorhandler, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_INSTALLATION_ITEMS, fetchInstallationItemsRequest),
    takeLatest(
      GET_COMPUTERS_WITH_INSTALLATION,
      fetchComputerWithInstallationsRequest
    ),
    takeLatest(FETCH_COMPUTER_INSTALLATIONS, fetchComputerInstallationsRequest),
    takeLatest(FETCH_COMPUTER_ITEMS, fetchComputerItemsRequest),
    takeLatest(REMOVE_DELETED_COMPUTER_ITEM, ReloadComputerItemsRequest),
    takeLatest(
      REMOVE_DELETED_INSTALLATION_ITEM,
      ReloadInstallationItemsRequest
    ),
    takeLatest(FETCH_AGENT_SESSION_DATA, FetchSessionDataRequest),
    takeLatest(FETCH_SESSION_ACTIVITY_INFO, FetchSessionActivityDataRequest),
    takeLatest(
      FETCH_USER_NOTIFICATION_SETTINGS,
      FetchUserNotificationSettingRequest
    ),
    takeLatest(
      UPDATE_USER_NOTIFICATION_SETTINGS,
      updateUserNotificationSettingRequest
    ),
    takeLatest(GENERATE_SESSION_REPORT, generateXlsxReportRequest),
    takeLatest(SEND_REMOTE_INSTALL_REQUEST, sendRemoteInstallApiRequest),
    takeLatest(SEND_REMOTE_COMPUTER_REQUEST, sendRemoteComputerApiRequest)
  ]);
}
