/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Button, Checkbox, Grid, Icon, Modal, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchPackage,
  fetchUnAssignedPackage,
  onAddCustomerPackage,
  onEditPackage,
  saveJobs,
  updateSearchPackage
} from "../../../../actions";
import Pagination from "../../../../components/Pagination";

const PackageItem = ({ _package, onDeleteItem, onSelectItem, isSelected }) => (
  <Table.Row
    className="clickable"
    onClick={evt => onSelectItem(evt, _package.id)}
  >
    <Table.Cell collapsing>
      <Checkbox
        checked={isSelected}
        onChange={evt => onSelectItem(evt, _package.id)}
        fitted
      />
    </Table.Cell>
    <Table.Cell>{_package.fileName}</Table.Cell>
    <Table.Cell>{_package.version}</Table.Cell>
    <Table.Cell>{_package.platform ? "x64" : "x86"}</Table.Cell>
    <Table.Cell>{_package.category ? "Generic" : "Specific"}</Table.Cell>
  </Table.Row>
);

PackageItem.propTypes = {
  _package: PropTypes.object.isRequired
};

class PubPackages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      selected: [],
      packageData: []
    };
  }
  componentDidMount() {
    this.props.fetchUnAssignedPackage({
      id: this.props.selectedCustomer.id,
      Limit: 12
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.packages !== this.props.packages) {
      this.setState({ packageData: this.props.packages.items, checked: false });
    }
  }

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({
        selected: this.state.packageData.map(n => n.id),
        checked: checked
      });

      return;
    }
    this.setState({ selected: [], checked: checked });
  };

  onDeselect = () => this.setState({ selected: [], checked: false });

  onSelectItem = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  onAddCustomerPackage = () => {
    const { packages } = this.props;
    const { selected } = this.state;

    const job = {
      userId: this.props.selectedCustomer.id,
      status: "pending",
      packages: { ...selected }
    };
    // this.props.saveJobs(job);
    selected.forEach(_packageId => {
      const _package = packages.items.find(
        _package => _package.id === _packageId
      );
      this.props.onAddCustomerPackage(this.props.selectedCustomer, _package);
    });
  };

  render() {
    const { width } = this.props;
    return (
      <React.Fragment>
        <Modal.Header>Assign Public Package</Modal.Header>
        <Modal.Content scrolling>
          <Table selectable padded basic="very">
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    onChange={(e, { checked }) =>
                      this.handleSelectAllClick(e, checked)
                    }
                    checked={this.state.checked}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>Package Name</Table.HeaderCell>
                <Table.HeaderCell>Version</Table.HeaderCell>
                <Table.HeaderCell>Platform</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.packageData.map(_package => {
                const isSelected = this.isSelected(_package.id);
                return (
                  <PackageItem
                    key={_package.id}
                    _package={_package}
                    isSelected={isSelected}
                    onSelectItem={this.onSelectItem}
                  />
                );
              })}
            </Table.Body>
          </Table>
          <Pagination
            totalPages={this.props.packages.totalPages}
            limit={12}
            handlePaginationChange={filters => {
              this.props.fetchUnAssignedPackage({
                id: this.props.selectedCustomer.id,
                ...filters
              });

              this.onDeselect();
            }}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button.Group>
            <Button onClick={this.props.onClose}>Close</Button>
            <Button primary onClick={this.onAddCustomerPackage}>
              Proceed <Icon name="right chevron" />
            </Button>
          </Button.Group>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ packageData, customerData, settings }) => {
  const { packages } = packageData;
  const { selectedCustomer } = customerData;
  const { width } = settings;
  return {
    packages,
    selectedCustomer,
    width
  };
};
export default connect(mapStateToProps, {
  fetchPackage,
  saveJobs,
  onEditPackage,
  fetchUnAssignedPackage,
  updateSearchPackage,
  onAddCustomerPackage
})(PubPackages);
