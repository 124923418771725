import { createSelector } from "reselect";

export const selectAuthUser = state => state.oidc.user;

export const selectAuthUserRole = createSelector(
  [selectAuthUser],
  state => state.profile.role
);

export const isMemberOfParentOrganisation = createSelector(
  [selectAuthUser],
  state => Number(state.profile.org) === 1
);
