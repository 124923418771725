/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Grid, Header, Divider, Segment, Table } from "semantic-ui-react";
import Loader from "../../../../../components/Loader";
import {
  fetchClientDetails,
  openModalService,
  closeModalService,
  updateClientDetails
} from "../../../../../actions";
import { fetchUserByID } from "../../../../../actions";
import EntryForm from "../../components/EntryForm";
import { API_URL } from "../../../../../constants/Api";
class OrgnDetails extends React.Component {
  state = {
    open: false,
    search: ""
  };
  componentDidMount() {
    if (this.props.userId) {
      this.props.fetchClientDetails(this.props.userId);
    }
  }

  updateClient = () => {
    this.props.openModalService({
      children: (
        <EntryForm
          apiClient={{
            ...this.props.client,
            redirectUris: this.props.client.redirectUris[0]
          }}
          onSubmit={payload => {
            this.props.updateClientDetails(payload);
            setTimeout(() => {
              this.props.closeModalService();
            }, 200);
          }}
          closeModalService={this.props.closeModalService}
        />
      ),
      size: "tiny"
    });
  };

  render() {
    const { /*selectedUser,*/ isProcessingRequest, client } = this.props;
    const { clientId, clientSecret } = client;

    // const isUserSelected =
    //   selectedUser && Object.prototype.hasOwnProperty.call(selectedUser, "id");
    return (
      <React.Fragment>
        <Grid.Row>
          {isProcessingRequest ? <Loader /> : null}

          <Grid.Column width="16">
            {/*{isUserSelected && <Divider />}*/}
            <Segment basic clearing>
              <Header
                floated="left"
                content="API Details"
                subheader="Api credentails"
              />
            </Segment>
            <Divider />
            <Segment basic clearing>
              <Table basic="very" collapsing padded size="large">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={6} />

                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h4">
                        <pre>ClientID</pre>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <pre>{clientId ? clientId : "Not set"}</pre>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h4">
                        <pre>Client Secret</pre>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <pre>{clientSecret ? clientSecret : "Not Set"}</pre>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h4">
                        <pre>Grant Type</pre>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <pre>client_credentails</pre>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h4">
                        <pre>Token URL</pre>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <pre>{`${API_URL.SERVER.replace(
                        "api",
                        ""
                      )}connect/token `}</pre>
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>
                      <Header as="h4">
                        <pre>API docs</pre>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <pre>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${API_URL.API}/docs`}
                        >
                          {`${API_URL.API}/docs`}
                        </a>
                      </pre>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

OrgnDetails.propTypes = {
  match: PropTypes.object
};

const mapStateToProps = ({ UserData }) => {
  const { selectedUser, isProcessingRequest, client } = UserData;
  return { selectedUser, isProcessingRequest, client };
};

export default connect(mapStateToProps, {
  fetchClientDetails,
  fetchUserByID,
  openModalService,
  closeModalService,
  updateClientDetails
})(OrgnDetails);
