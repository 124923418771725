import React, { useCallback } from "react";
import { Button, Divider, Form, Modal, Select } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { closeModalService, updateCustomer } from "../../../../actions";

import { Formik } from "formik";
import * as Yup from "yup";

import { getSelectedCustomer } from "../../../../selectors/customer";
import CustomInput from "../../../../components/InputField";

const schema = Yup.object().shape({
  adminRole: Yup.string()
    .label("Role")
    .required()
});

const AttachRole = () => {
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(getSelectedCustomer);

  const onClose = useCallback(() => dispatch(closeModalService()), [dispatch]);

  const handleSubmit = useCallback(
    values => {
      if (Object.prototype.hasOwnProperty.call(values, "id")) {
        // ["Customer", data.value]
        const data = { ...values, role: ["Customer", values.adminRole] };
        delete data.adminRole;

        dispatch(updateCustomer(data));
      }
      setTimeout(() => {
        onClose();
      }, 500);
    },
    [dispatch, onClose]
  );

  const options = [
    { key: "N", text: "Normal", value: "Normal" },
    { key: "A", text: "Admin", value: "Admin" },
    { key: "S", text: "SuperAdmin", value: "SuperAdmin" }
    // { key: "C", text: "API Client", value: "APIClient" }
  ];

  const adminRole = () => {
    if (
      selectedCustomer &&
      Object.prototype.hasOwnProperty.call(selectedCustomer, "id")
    ) {
      return selectedCustomer.role instanceof Array
        ? selectedCustomer.role.find(r => r !== "Customer")
        : selectedCustomer.role;
    }
    return "";
  };

  return (
    <React.Fragment>
      <Modal.Header>Attach Admin Role</Modal.Header>
      <Modal.Content>
        <Formik
          enableReinitialize={true}
          initialValues={{ ...selectedCustomer, adminRole: adminRole() || "" }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSubmit(values);
              actions.setSubmitting(false);
            }, 1000);
          }}
        >
          {({ isSubmitting, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <CustomInput
                id="adminRole"
                name="adminRole"
                label="Role*"
                control={Select}
                options={options}
                onChange={(evt, data) => setFieldValue("adminRole", data.value)}
                search
                placeholder="Select Role"
              />

              <Divider />
              <Button.Group floated="right">
                <Button negative onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  positive
                  size="medium"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Button.Group>
            </Form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions />
    </React.Fragment>
  );
};

export default AttachRole;
