import {
  FETCH_COMPUTER_ITEMS,
  FETCH_COMPUTER_ITEMS_SUCCESS,
  GET_COMPUTERS_WITH_INSTALLATION,
  GET_COMPUTERS_WITH_INSTALLATION_SUCCESS,
  UPDATE_COMPUTER_ITEM,
  UPDATE_COMPUTER_ITEM_SUCCESS,
  FETCH_COMPUTER_INSTALLATIONS,
  FETCH_COMPUTER_INSTALLATIONS_SUCCESS,
  FETCH_INSTALLATION_ITEMS,
  FETCH_INSTALLATION_ITEMS_SUCCESS,
  ON_SELECT_COMPUTER,
  ON_SELECT_INSTALLATION,
  SEND_ESD_COMMAND,
  RESET_ESD_LOGS,
  SEND_ESD_PACKAGE_INSTALL_CMD,
  SEND_ESD_REPORT_COMMAND,
  SEND_INSTALLATION_ITEM_COMMAND_TO_MANAGER,
  SEND_REMOTE_INSTALL,
  FETCH_AGENT_SESSION_DATA,
  FETCH_AGENT_SESSION_DATA_SUCCESS,
  FETCH_AGENT_SESSION_DATA_FAILURE,
  FETCH_SESSION_ACTIVITY_INFO,
  FETCH_SESSION_ACTIVITY_INFO_SUCCESS,
  FETCH_SESSION_ACTIVITY_INFO_FAILURE,
  FETCH_USER_NOTIFICATION_SETTINGS,
  FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_USER_NOTIFICATION_SETTINGS_FAILURE,
  UPDATE_USER_NOTIFICATION_SETTINGS,
  UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE,
  GENERATE_SESSION_REPORT,
  GENERATE_SESSION_REPORT_SUCCESS,
  GENERATE_SESSION_REPORT_FAILURE,
  GENERATE_CREDENTIALS,
  GENERATE_CREDENTIALS_SUCCESS,
  GENERATE_CREDENTIALS_FAILURE,
  SEND_REMOTE_INSTALL_REQUEST,
  SEND_REMOTE_INSTALL_REQUEST_SUCCESS,
  SEND_REMOTE_INSTALL_REQUEST_FAILURE,
  SEND_REMOTE_COMPUTER_REQUEST,
  SEND_REMOTE_COMPUTER_REQUEST_FAILURE,
  SEND_REMOTE_COMPUTER_REQUEST_SUCCESS
} from "../constants/ActionTypes";

export const resetLogs = () => ({ type: RESET_ESD_LOGS });
export const onSelectComputer = computer => ({
  type: ON_SELECT_COMPUTER,
  payload: computer
});

export const sendInstallationItemCommandToManager = commandData => ({
  type: SEND_INSTALLATION_ITEM_COMMAND_TO_MANAGER,
  payload: commandData
});

export const sendEsdReportCommand = commandData => ({
  type: SEND_ESD_REPORT_COMMAND,
  payload: commandData
});
export const sendEsdPackageInstallCmd = commandData => ({
  type: SEND_ESD_PACKAGE_INSTALL_CMD,
  payload: commandData
});
export const sendEsdCommand = commandData => ({
  type: SEND_ESD_COMMAND,
  payload: commandData
});
export const onSelectInstallation = installation => ({
  type: ON_SELECT_INSTALLATION,
  payload: installation
});
export const fetchComputerItems = filters => ({
  type: FETCH_COMPUTER_ITEMS,
  payload: filters
});
export const fetchComputerItemsSuccess = computerItems => ({
  type: FETCH_COMPUTER_ITEMS_SUCCESS,
  payload: computerItems
});

export const fetchComputerInstallations = filters => ({
  type: FETCH_COMPUTER_INSTALLATIONS,
  payload: filters
});
export const fetchComputerInstallationsSuccess = installationItems => ({
  type: FETCH_COMPUTER_INSTALLATIONS_SUCCESS,
  payload: installationItems
});

export const getComputersWithInstallation = filters => ({
  type: GET_COMPUTERS_WITH_INSTALLATION,
  payload: filters
});
export const getComputersWithInstallationSuccess = computerItems => ({
  type: GET_COMPUTERS_WITH_INSTALLATION_SUCCESS,
  payload: computerItems
});

export const updateComputerItem = computerItem => ({
  type: UPDATE_COMPUTER_ITEM,
  payload: computerItem
});
export const updateComputerItemSuccess = computerItem => ({
  type: UPDATE_COMPUTER_ITEM_SUCCESS,
  payload: computerItem
});

export const fetchInstallationItems = filters => ({
  type: FETCH_INSTALLATION_ITEMS,
  payload: filters
});
export const fetchInstallationItemsSuccess = installationItems => ({
  type: FETCH_INSTALLATION_ITEMS_SUCCESS,
  payload: installationItems
});

export const updateInstallationItem = installationItem => ({
  type: UPDATE_COMPUTER_ITEM,
  payload: installationItem
});
export const updateInstallationItemSuccess = installationItem => ({
  type: UPDATE_COMPUTER_ITEM_SUCCESS,
  payload: installationItem
});

export const sendRemoteInstallCmd = data => ({
  type: SEND_REMOTE_INSTALL,
  payload: data
});

export const fetchSessionData = filters => ({
  type: FETCH_AGENT_SESSION_DATA,
  payload: filters
});

export const fetchSessionDataSuccess = data => ({
  type: FETCH_AGENT_SESSION_DATA_SUCCESS,
  payload: data
});

export const fetchSessionDataFailure = error => ({
  type: FETCH_AGENT_SESSION_DATA_FAILURE,
  payload: error
});

export const fetchSessionActivityInfo = filters => ({
  type: FETCH_SESSION_ACTIVITY_INFO,
  payload: filters
});

export const fetchSessionActivityInfoSuccess = data => ({
  type: FETCH_SESSION_ACTIVITY_INFO_SUCCESS,
  payload: data
});

export const fetchSessionActivityInfoFailure = error => ({
  type: FETCH_SESSION_ACTIVITY_INFO_FAILURE,
  payload: error
});

export const fetchUserNotificationSettings = filters => ({
  type: FETCH_USER_NOTIFICATION_SETTINGS,
  payload: filters
});

export const fetchUserNotificationSettingsSuccess = data => ({
  type: FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
  payload: data
});

export const fetchUserNotificationSettingsFailure = error => ({
  type: FETCH_USER_NOTIFICATION_SETTINGS_FAILURE,
  payload: error
});

export const updateUserNotificationSettings = filters => ({
  type: UPDATE_USER_NOTIFICATION_SETTINGS,
  payload: filters
});

export const updateUserNotificationSettingsSuccess = data => ({
  type: UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
  payload: data
});

export const updateUserNotificationSettingsFailure = error => ({
  type: UPDATE_USER_NOTIFICATION_SETTINGS_FAILURE,
  payload: error
});

export const generateXlsxReport = filters => ({
  type: GENERATE_SESSION_REPORT,
  payload: filters
});

export const generateXlsxReportSuccess = () => ({
  type: GENERATE_SESSION_REPORT_SUCCESS
});

export const generateXlsxReportFailure = error => ({
  type: GENERATE_SESSION_REPORT_FAILURE,
  payload: error
});

export const generateCredentails = filters => ({
  type: GENERATE_CREDENTIALS,
  payload: filters
});

export const generateCredentailsSuccess = () => ({
  type: GENERATE_CREDENTIALS_SUCCESS
});

export const generateCredentailsFailure = error => ({
  type: GENERATE_CREDENTIALS_FAILURE,
  payload: error
});

export const sendRemoteInstallRequest = filters => ({
  type: SEND_REMOTE_INSTALL_REQUEST,
  payload: filters
});

export const sendRemoteInstallRequestSuccess = () => ({
  type: SEND_REMOTE_INSTALL_REQUEST_SUCCESS
});

export const sendRemoteInstallRequestFailure = error => ({
  type: SEND_REMOTE_INSTALL_REQUEST_FAILURE,
  payload: error
});

export const sendRemoteComputerRequest = filters => ({
  type: SEND_REMOTE_COMPUTER_REQUEST,
  payload: filters
});

export const sendRemoteComputerRequestSuccess = () => ({
  type: SEND_REMOTE_COMPUTER_REQUEST_SUCCESS
});

export const sendRemoteComputerRequestFailure = error => ({
  type: SEND_REMOTE_COMPUTER_REQUEST_FAILURE,
  payload: error
});
