import {
  FETCH_ALL_PACKAGE,
  FETCH_ALL_PACKAGE_SUCCESS,
  SAVE_PACKAGE,
  SAVE_PACKAGE_SUCCESS,
  FILTER_PACKAGES,
  ON_HIDE_LOADER,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
  SHOW_MESSAGE,
  ON_EDIT_PACKAGE,
  ON_OPEN_PACKAGE_MODAL,
  ON_CLOSE_PACKAGE_MODAL,
  FETCH_UN_ASSIGENED_PACKAGES,
  UPDATE_UNASSIGNED_PACKAGE_STATE,
  UPDATE_SEARCH_PACKAGE,
  HANDLE_REQUEST_CLOSE,
  VIEW_PACKAGE_DETAIL,
  FiND_PACKAGE_BY_ID,
  FiND_PACKAGE_BY_ID_SUCCESS,
  FiND_PACKAGE_BY_ID_FAILURE
} from "../constants/ActionTypes";

export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE
  };
};

export const onEditPackage = id => {
  return {
    type: ON_EDIT_PACKAGE,
    payload: id
  };
};
export const openPackageModal = reason => {
  return {
    type: ON_OPEN_PACKAGE_MODAL,
    payload: reason
  };
};

// eslint-disable-next-line no-unused-vars
export const viewPackageDetail = id => {
  return {
    type: VIEW_PACKAGE_DETAIL
  };
};

export const updateUnassignedPackageState = id => {
  return {
    type: UPDATE_UNASSIGNED_PACKAGE_STATE,
    payload: id
  };
};

export const closePackageModal = () => {
  return {
    type: ON_CLOSE_PACKAGE_MODAL
  };
};
export const filterPackage = filterText => {
  return {
    type: FILTER_PACKAGES,
    payload: filterText
  };
};
export const fetchPackage = filters => {
  return {
    type: FETCH_ALL_PACKAGE,
    payload: filters
  };
};

export const fetchPackageSuccess = packages => {
  return {
    type: FETCH_ALL_PACKAGE_SUCCESS,
    payload: packages
  };
};

export const fetchUnAssignedPackage = payload => {
  return {
    type: FETCH_UN_ASSIGENED_PACKAGES,
    payload
  };
};

export const fetchUnAssignedPackageSuccess = packages => {
  return {
    type: FETCH_ALL_PACKAGE_SUCCESS,
    payload: packages
  };
};

export const savePackage = _package => {
  return {
    type: SAVE_PACKAGE,
    _package
  };
};

export const savePackageSuccess = _package => {
  return {
    type: SAVE_PACKAGE_SUCCESS,
    payload: _package
  };
};

export const deletePackage = packageId => {
  return {
    type: DELETE_PACKAGE,
    packageId
  };
};

export const deletePackageSuccess = packageId => {
  return {
    type: DELETE_PACKAGE_SUCCESS,
    payload: packageId
  };
};

export const updatePackage = _package => {
  return {
    type: UPDATE_PACKAGE,
    _package
  };
};

export const updatePackageSuccess = _package => {
  return {
    type: UPDATE_PACKAGE_SUCCESS,
    payload: _package
  };
};

export const showPackageMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};
export const updateSearchPackage = fileName => {
  return {
    type: UPDATE_SEARCH_PACKAGE,
    payload: fileName
  };
};

export const findPackage = packageId => {
  return {
    type: FiND_PACKAGE_BY_ID,
    payload: packageId
  };
};

export const findPackageSuccess = data => {
  return {
    type: FiND_PACKAGE_BY_ID_SUCCESS,
    payload: data
  };
};

export const findPackageFailure = () => {
  return {
    type: FiND_PACKAGE_BY_ID_FAILURE
  };
};
