//import Moment from "moment";
import {
  FETCH_ALL_PACKAGE,
  FETCH_ALL_PACKAGE_SUCCESS,
  DELETE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_SUCCESS,
  SAVE_PACKAGE_SUCCESS,
  FILTER_PACKAGES,
  ON_HIDE_LOADER,
  SHOW_MESSAGE,
  ON_EDIT_PACKAGE,
  ON_OPEN_PACKAGE_MODAL,
  ON_CLOSE_PACKAGE_MODAL,
  UPDATE_UNASSIGNED_PACKAGE_STATE,
  UPDATE_SEARCH_PACKAGE,
  HANDLE_REQUEST_CLOSE,
  FiND_PACKAGE_BY_ID,
  FiND_PACKAGE_BY_ID_SUCCESS,
  FiND_PACKAGE_BY_ID_FAILURE
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  packageNotFound: "No Package found",
  searchPackage: "",
  alertMessage: "",
  showMessage: false,
  packages: { items: [], nextPage: null, previousPage: null, totalPages: null },
  packageList: {
    items: [],
    page: 1,
    nextPage: null,
    previousPage: null,
    totalPages: null
  },
  addPackageState: false,
  openModalReason: "",
  selectedPackage: {},
  isFetchingPubPackages: false,
  isFindingPackage: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_EDIT_PACKAGE: {
      return {
        ...state,
        selectedPackage: state.packageList.items.find(
          _package => _package.id === action.payload
        )
      };
    }

    case ON_OPEN_PACKAGE_MODAL: {
      return {
        ...state,
        addPackageState: true,
        openModalReason: action.payload
      };
    }

    case ON_CLOSE_PACKAGE_MODAL: {
      return {
        ...state,
        selectedPackage: {},
        addPackageState: false
      };
    }
    case FILTER_PACKAGES: {
      const { packageList } = state;
      if (action.payload === "") {
        return {
          ...state,
          packages: packageList
        };
      } else {
        return {
          ...state,
          packages: packageList.items.filter(
            _package =>
              _package.fileName
                .toLowerCase()
                .indexOf(action.payload.toLowerCase()) > -1
          )
        };
      }
    }

    case SAVE_PACKAGE_SUCCESS: {
      //state.packages.push(action.payload);
      return {
        ...state,
        packageList: {
          ...state.packages,
          items: [...state.packages.items, action.payload]
        },
        packages: {
          ...state.packages,
          items: [...state.packages.items, action.payload]
        }
      };
    }

    case UPDATE_PACKAGE_SUCCESS: {
      return {
        ...state,
        packageList: {
          ...state.packages,
          items: state.packages.items.map(_package =>
            _package.id === action.payload.id ? action.payload : _package
          )
        },
        packages: {
          ...state.packages,
          items: state.packages.items.map(_package =>
            _package.id === action.payload.id ? action.payload : _package
          )
        }
      };
    }

    case UPDATE_UNASSIGNED_PACKAGE_STATE: {
      return {
        ...state,
        packageList: {
          ...state.packages,
          items: state.packages.items.filter(
            _package => _package.id !== action.payload
          )
        },
        packages: {
          ...state.packages,
          items: state.packages.items.filter(
            _package => _package.id !== action.payload
          )
        }
      };
    }

    case DELETE_PACKAGE_SUCCESS: {
      return {
        ...state,
        packages: {
          ...state.packages,
          items: state.packages.items.filter(
            _package => _package.id !== action.payload
          )
        },
        packageList: {
          ...state.packages,
          items: state.packages.items.filter(
            _package => _package.id !== action.payload
          )
        }
      };
    }

    case FETCH_ALL_PACKAGE: {
      return {
        ...state,
        isFetchingPubPackages: true
      };
    }

    case FETCH_ALL_PACKAGE_SUCCESS: {
      return {
        ...state,
        packageList: action.payload,
        packages: action.payload,
        isFetchingPubPackages: false
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }

    case ON_HIDE_LOADER: {
      return { ...state, loader: false };
    }

    case UPDATE_SEARCH_PACKAGE: {
      return { ...state, searchPackage: action.payload };
    }

    case HANDLE_REQUEST_CLOSE: {
      return { ...state, showMessage: false };
    }

    case FiND_PACKAGE_BY_ID: {
      return {
        ...state,
        isFindingPackage: true
      };
    }

    case FiND_PACKAGE_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedPackage: action.payload,
        isFindingPackage: false
      };
    }

    case FiND_PACKAGE_BY_ID_FAILURE: {
      return {
        ...state,
        isFindingPackage: false
      };
    }

    default:
      return state;
  }
};
