import React, { useState } from "react";
import { List, Icon, Button, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

const ComputerItem = ({ computer, onSelectItem, isSelected }) => (
  <List.Item as="a" onClick={() => onSelectItem(computer)}>
    {isSelected ? (
      <List.Content floated="right">
        <Icon name="check" />
      </List.Content>
    ) : null}
    <List.Icon
      name="computer"
      size="large"
      verticalAlign="middle"
      color={computer.onlineStatus ? "green" : "red"}
    />
    <List.Content>
      <List.Header as="h4">{computer.computerName}</List.Header>
      <List.Description>{computer.computerDesc}</List.Description>
    </List.Content>
  </List.Item>
);

ComputerItem.propTypes = {
  computer: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
};

const ComputerList = ({
  computers,
  selectedInstallationItem,
  closeModalService,
  sendInstallationCmd,
  command
}) => {
  const [selectedComputerId, setSelectedComputerId] = useState([]);
  const [selectedComputers, setSelectedComputers] = useState([]);
  const onSelectItem = computer => {
    const selectedIndex = selectedComputerId.indexOf(computer.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedComputerId, computer.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedComputerId.slice(1));
    } else if (selectedIndex === selectedComputerId.length - 1) {
      newSelected = newSelected.concat(selectedComputerId.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedComputerId.slice(0, selectedIndex),
        selectedComputerId.slice(selectedIndex + 1)
      );
    }
    setSelectedComputerId(newSelected);
    const _selectedComputers = newSelected.map(id =>
      computers.find(_computer => _computer.id === id)
    );
    setSelectedComputers(_selectedComputers);
  };

  const postInstallationCmd = () => {
    if (selectedComputers.length) {
      sendInstallationCmd({
        data: {
          installationItem: selectedInstallationItem,
          computers: selectedComputers
        },
        command
      });

      setTimeout(() => closeModalService(), 200);
    }
  };

  const isComputerSelected = id => selectedComputerId.indexOf(id) !== -1;
  return (
    <React.Fragment>
      <Modal.Header>
        Select Computers on which to perform action
        {" " + command}
      </Modal.Header>
      <Modal.Content scrolling>
        <List animated selection divided verticalAlign="middle">
          {computers.map(computer => {
            const isSelected = isComputerSelected(computer.id);
            return (
              <ComputerItem
                key={computer.id}
                computer={computer}
                onSelectItem={onSelectItem}
                isSelected={isSelected}
              />
            );
          })}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button onClick={closeModalService}>Close</Button>
          <Button
            disabled={!selectedComputers.length}
            primary
            onClick={postInstallationCmd}
          >
            Proceed <Icon name="right chevron" />
          </Button>
        </Button.Group>
      </Modal.Actions>
    </React.Fragment>
  );
};

ComputerList.propTypes = {
  computers: PropTypes.object.isRequired,
  closeModalService: PropTypes.func.isRequired,
  sendInstallationCmd: PropTypes.func.isRequired,
  selectedInstallationItem: PropTypes.object.isRequired,
  command: PropTypes.string.isRequired
};

export default ComputerList;
