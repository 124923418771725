/* eslint-disable no-console */
import {
  OPEN_MODAL_SERVICE,
  CLOSE_MODAL_SERVICE
} from "../constants/ActionTypes";

const INIT_STATE = {
  isOpen: false,
  children: null,
  size: "small"
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_MODAL_SERVICE: {
      console.log("action ", action);
      return {
        ...INIT_STATE,
        isOpen: true,
        onClose: action.onClose,
        children: action.children,
        size: action.size || state.size
      };
    }

    case CLOSE_MODAL_SERVICE: {
      return {
        ...INIT_STATE
      };
    }

    default:
      return state;
  }
};
