import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form, Modal, Button, Input } from "semantic-ui-react";
import * as Yup from "yup";

import TextField from "../../../../../components/InputField";

const EntryFormSchema = Yup.object().shape({
  contact: Yup.string()
    .label("Contact")
    .required("Required"),
  name: Yup.string()
    .label("Name")
    .required("Required"),
  address: Yup.string()
    .label("Address")
    .required("Required"),
  email: Yup.string()
    .label("Email")
    .email()
    .required("Required")
});

function EntryForm({ onSubmit, closeModalService, organisation }) {
  const formikRef = useRef(null);

  return (
    <>
      <Modal.Header>
        {organisation &&
        Object.prototype.hasOwnProperty.call(organisation, "id")
          ? "Edit Organisation"
          : "Add Organisation"}
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={
            organisation || {
              email: "",
              address: "",
              contact: "",
              name: ""
            }
          }
          innerRef={formikRef}
          enableReinitialize
          validationSchema={EntryFormSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              onSubmit(values);

              actions.setSubmitting(false);

              formikRef.current.resetForm();
            }, 1000);
          }}
          // eslint-disable-next-line no-unused-vars
          onReset={(values, formikBag) => {
            setTimeout(() => {
              closeModalService();
            }, 200);
          }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <TextField
                name="name"
                type="text"
                label="Name"
                placeholder="Organization's name"
                control={Input}
                fluid
              />
              <TextField
                name="address"
                type="text"
                label="Address"
                placeholder="Organization's address"
                control={Input}
                fluid
              />
              <TextField
                name="email"
                type="text"
                label="Email"
                placeholder="orgn@email.nl"
                control={Input}
                fluid
              />
              <TextField
                name="contact"
                type="tel"
                label="Contact"
                placeholder="020 XXX XXX"
                control={Input}
                fluid
              />

              <Button.Group floated="right">
                <Button onClick={closeModalService}>CANCEL</Button>

                <Button
                  color="blue"
                  type="submit"
                  disabled={props.isSubmitting}
                >
                  SAVE
                </Button>
              </Button.Group>
            </Form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions />
    </>
  );
}

EntryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  closeModalService: PropTypes.func.isRequired,

  organisation: PropTypes.object,

  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default EntryForm;
