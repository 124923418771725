/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from "react";
import { Grid, Label, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  sendEsdCommand,
  resetLogs,
  openModalService,
  closeModalService
} from "../../../../../actions";
import ActionList from "../../components/ComputerActions";
import ComputerForm from "../../components/ComputerForm";
import ComputerReport from "../../components/ComputerReport";

const actions = [
  { name: "Agent Report", icon: "newspaper outline" },
  { name: "IP Lookup", icon: "search" },
  { name: "User Lookup", icon: "search" },
  { name: "Remote Reboot", icon: "linkify" },
  { name: "Remote Shutdown", icon: "unlink" },
  { name: "Install Agent", icon: "linkify" },
  { name: "Remove Agent", icon: "unlink" },
  { name: "Check now", icon: "unlink" },
  { name: "Delete Computer Item", icon: "delete" }
];

class ComputerDetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disableEdit: true,
      commands: {}
    };
  }

  onSubmitForm = formData => {
    console.log("Form", formData);
  };

  showComputerItemReport = () =>
    this.props.openModalService({
      children: (
        <ComputerReport closeModalService={this.props.closeModalService} />
      ),
      size: "large"
    });

  onSelectAction = action => {
    const { selectedComputerItem } = this.props;
    this.props.resetLogs();
    switch (action) {
      case "Edit Computer Item":
        // this.setState({ disableEdit: false });
        break;
      case "User Lookup":
        this.props.sendEsdCommand({
          command: "UserLookup",
          data: selectedComputerItem
        });
        break;

      case "IP Lookup":
        this.props.sendEsdCommand({
          command: "IPLookup",
          data: selectedComputerItem
        });
        break;
      case "Check now":
        this.props.sendEsdCommand({
          command: "Checknow",
          data: selectedComputerItem
        });
        break;
      case "Remote Reboot":
        this.props.sendEsdCommand({
          command: "RemoteReboot",
          data: selectedComputerItem
        });
        break;
      case "Remote Shutdown":
        this.props.sendEsdCommand({
          command: "RemoteShutdown",
          data: selectedComputerItem
        });
        break;

      case "Agent Report":
        this.showComputerItemReport();
        break;

      case "Install Agent":
        this.props.sendEsdCommand({
          command: "RemoteInstallAgent",
          data: selectedComputerItem
        });
        break;
      case "Remove Agent":
        this.props.sendEsdCommand({
          command: "RemoteRemoveAgent",
          data: selectedComputerItem
        });
        break;
      case "Delete Computer Item":
        this.props.sendEsdCommand({
          command: "DeleteComputerItem",
          data: selectedComputerItem
        });
        break;
      default:
        // statements_def
        break;
    }
  };

  clearLog = () => this.props.resetLogs();

  render() {
    const { disableEdit } = this.state;
    const { selectedComputerItem, esdLogs } = this.props;

    return (
      <Grid stackable padded divided>
        <Grid.Row>
          <Grid.Column width={11}>
            <ComputerForm
              disableEdit={disableEdit}
              onSubmitForm={this.onSubmitForm}
              selectedComputerItem={selectedComputerItem}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <ActionList
              actions={actions}
              onSelectAction={this.onSelectAction}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          {esdLogs.length > 0 && (
            <Grid.Column>
              <Segment basic>
                <Button
                  compact
                  size="small"
                  floated="right"
                  onClick={this.clearLog}
                >
                  Clear
                </Button>
                Event Log <Label circular>{esdLogs.length}</Label>
              </Segment>
              <Segment
                basic
                secondary
                style={{ backgroundColor: "rgb(36, 36, 36)" }}
              >
                <code>
                  {esdLogs
                    .filter(log => log !== "")
                    .map((e, i) => (
                      <p
                        key={i}
                        style={{
                          padding: "5px",
                          color: e.includes("Error") ? "red" : "white"
                        }}
                        dangerouslySetInnerHTML={{ __html: e }}
                      />
                    ))}
                </code>
                s
              </Segment>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

ComputerDetail.propTypes = {
  selectedComputerItem: PropTypes.object,
  esdLogs: PropTypes.array.isRequired,
  sendEsdCommand: PropTypes.func.isRequired,
  resetLogs: PropTypes.func.isRequired,
  ipLookupResult: PropTypes.string,
  userLookupResult: PropTypes.string
};
const mapStateToProps = ({ esdActivityData }) => {
  const {
    selectedComputerItem,
    esdLogs,
    ipLookupResult,
    userLookupResult
  } = esdActivityData;
  return {
    selectedComputerItem,
    esdLogs,
    ipLookupResult,
    userLookupResult
  };
};
export default connect(mapStateToProps, {
  sendEsdCommand,
  resetLogs,
  openModalService,
  closeModalService
})(ComputerDetail);
