import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import customerSagas from "./Customer";
import packageSagas from "./Package";
import userSagas from "./User";
import pUploadSagas from "./PUpload";
import esdActivitySagas from "./EsdActivity";
import orgnSagas from "./Orgn";
//import signalrHubSagas from "./SignalrHub";

// eslint-disable-next-line no-unused-vars
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    customerSagas(),
    packageSagas(),
    userSagas(),
    pUploadSagas(),
    esdActivitySagas(),
    orgnSagas()
    // signalrHubSagas()
  ]);
}
