/* eslint-disable react/prop-types */
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Table, Grid } from "semantic-ui-react";
// eslint-disable-next-line no-unused-vars

import { connect } from "react-redux";

import CustomScrollbars from "../../../../../util/CustomScrollbars";
import Loader from "../../../../../components/Loader";
import Pagination from "../../../../../components/Pagination";

import PackageItem from "./PackageItem";
import PackageModalForm from "../../components/PackageForm";
import {
  fetchPackage,
  openModalService,
  onEditPackage,
  deletePackage
} from "../../../../../actions";
import RemoteInstallForm from "../../components/RemoteInstallForm";

class PubPackages extends React.Component {
  componentDidMount() {
    this.props.fetchPackage({ Limit: 10 });
  }

  componentWillUnmount() {}

  onDeleteItem = packageId => this.props.deletePackage(packageId);

  handleEditPackage = packageItem => {
    // this.props.onEditPackage(packageId);
    this.props.openModalService({
      children: <PackageModalForm selectedPackage={packageItem} />,
      size: "tiny"
    });
  };

  handleInstall = packageId =>
    this.props.openModalService({
      children: (
        <RemoteInstallForm
          installationItem={packageId}
          type="public"
          userId={this.props.user.profile.sub}
        />
      ),
      size: "tiny"
    });

  render() {
    const { width, packages, isFetchingPubPackages, user } = this.props;

    return (
      <Grid.Row>
        <Grid.Column>
          {isFetchingPubPackages ? <Loader /> : null}
          <CustomScrollbars
            className=""
            style={{
              height:
                width >= 1200 ? "calc(100vh - 334px)" : "calc(100vh - 354px)"
            }}
          >
            <Table selectable padded basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Package Name</Table.HeaderCell>
                  <Table.HeaderCell>Version</Table.HeaderCell>
                  <Table.HeaderCell>Platform</Table.HeaderCell>
                  <Table.HeaderCell>Category</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {packages &&
                  Object.prototype.hasOwnProperty.call(packages, "items") &&
                  packages.items.map(_package => (
                    <PackageItem
                      key={_package.id}
                      _package={_package}
                      onDeleteItem={this.onDeleteItem}
                      user={user}
                      // handleInstall={this.handleInstall}
                      onEditItem={this.handleEditPackage}
                    />
                  ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="5">
                    <Pagination
                      totalPages={packages.totalPages}
                      limit={10}
                      handlePaginationChange={filters =>
                        this.props.fetchPackage(filters)
                      }
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </CustomScrollbars>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

const mapStateToProps = ({ settings, packageData, oidc }) => {
  const { width } = settings;
  const { user } = oidc;
  const { isFetchingPubPackages, packages } = packageData;
  return {
    width,
    isFetchingPubPackages,
    packages,
    user
  };
};
export default connect(mapStateToProps, {
  fetchPackage,
  openModalService,
  onEditPackage,
  deletePackage
})(PubPackages);
