import React, { useCallback, useState } from "react";
import { Button, Confirm, Grid, List } from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedCustomer,
  selectInviteStatus
} from "../../../../../selectors/customer";

import {
  selectAuthUserRole,
  isMemberOfParentOrganisation
} from "../../../../../selectors/auth";
import {
  openModalService,
  closeModalService,
  removeCustomer,
  updateUser,
  sendInvite
} from "../../../../../actions";
import AttachOrganisation from "../../components/AttachOrganisation";
import AttachRole from "../../components/AttachRole";
import CustomScrollbars from "../../../../../util/CustomScrollbars";
import AddCustomerModel from "../../components/AddCustomerModel";

const ManageAccount = () => {
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(getSelectedCustomer);
  const inviteStatus = useSelector(selectInviteStatus);
  const userRole = useSelector(selectAuthUserRole);
  const isParentOrgMember = useSelector(isMemberOfParentOrganisation);
  const [isOpen, setIsOpen] = useState(false);

  const handleModalClose = () => dispatch(closeModalService());

  const updateCustomerHandler = () =>
    dispatch(
      openModalService({
        children: (
          <AddCustomerModel
            selectedCustomer={selectedCustomer}
            onClose={handleModalClose}
          />
        ),
        size: "tiny"
      })
    );

  const attachOrganisationHandler = () =>
    dispatch(
      openModalService({
        children: <AttachOrganisation />,
        size: "tiny"
      })
    );

  const attachRoleHandler = () =>
    dispatch(
      openModalService({
        children: <AttachRole />,
        size: "tiny"
      })
    );

  const disableUserhandler = useCallback(
    () =>
      dispatch(
        updateUser(
          {
            ...selectedCustomer,
            isAccessDisabled: !selectedCustomer.isAccessDisabled
          },
          true
        )
      ),
    [selectedCustomer, dispatch]
  );

  const showConfirmPopup = () => setIsOpen(true);

  const handleConfirm = () => {
    setIsOpen(false);
    dispatch(removeCustomer(selectedCustomer.id));
  };

  const handleCancel = () => setIsOpen(false);

  const sendInviteHandler = useCallback(() => {
    dispatch(sendInvite({ userId: selectedCustomer.id }));
  }, [selectedCustomer, dispatch]);

  const isAdmin = () =>
    userRole.includes("Admin") ||
    userRole.includes("SuperAdmin") ||
    userRole.includes("Normal");

  return (
    <Grid.Row>
      <Grid.Column style={{ paddingLeft: "26px" }}>
        <CustomScrollbars
          className=""
          style={{
            height: "calc(100vh - 248px)"
          }}
        >
          <List divided verticalAlign="middle">
            <List.Header as={"h3"}>Account Settings</List.Header>
            <List.Item>
              <List.Content floated="right">
                <Button
                  compact
                  style={{ width: 100 }}
                  basic
                  size="tiny"
                  color="blue"
                  onClick={updateCustomerHandler}
                >
                  Edit
                </Button>
              </List.Content>

              <List.Content>Update user Details</List.Content>
            </List.Item>
            {isAdmin && isParentOrgMember && (
              <List.Item>
                <List.Content floated="right">
                  <Button
                    compact
                    style={{ width: 100 }}
                    basic
                    size="tiny"
                    color="grey"
                    onClick={attachOrganisationHandler}
                  >
                    Attach
                  </Button>
                </List.Content>

                <List.Content>Change User Organisation</List.Content>
              </List.Item>
            )}
            <List.Item>
              <List.Content floated="right">
                <Button
                  compact
                  style={{ width: 100 }}
                  basic
                  size="tiny"
                  color="grey"
                  onClick={attachRoleHandler}
                >
                  Change
                </Button>
              </List.Content>

              <List.Content>Make User an Admin</List.Content>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                <Button
                  compact
                  style={{ width: 100 }}
                  basic
                  size="tiny"
                  color="grey"
                  onClick={sendInviteHandler}
                  loading={inviteStatus}
                >
                  Invite
                </Button>
              </List.Content>
              <List.Content>Grant access to Package Store</List.Content>
            </List.Item>

            <List.Item>
              <List.Content floated="right">
                <Button
                  compact
                  style={{ width: 100 }}
                  basic
                  size="tiny"
                  color={
                    selectedCustomer &&
                    Object.prototype.hasOwnProperty.call(
                      selectedCustomer,
                      "id"
                    ) &&
                    selectedCustomer.isAccessDisabled
                      ? "blue"
                      : "red"
                  }
                  onClick={disableUserhandler}
                >
                  {selectedCustomer &&
                  Object.prototype.hasOwnProperty.call(
                    selectedCustomer,
                    "id"
                  ) &&
                  selectedCustomer.isAccessDisabled
                    ? "Activate"
                    : "Deactivate"}
                </Button>
              </List.Content>
              <List.Content>Disable User Account</List.Content>
            </List.Item>
            <List.Item>
              <List.Content floated="right">
                <Button
                  compact
                  style={{ width: 100 }}
                  basic
                  size="tiny"
                  color="red"
                  onClick={showConfirmPopup}
                >
                  Delete
                </Button>
                <Confirm
                  open={isOpen}
                  size="mini"
                  confirmButton="Delete"
                  onCancel={handleCancel}
                  onConfirm={handleConfirm}
                />
              </List.Content>
              <List.Content>Completely Delete User Account</List.Content>
            </List.Item>
          </List>
        </CustomScrollbars>
      </Grid.Column>
    </Grid.Row>
  );
};

export default ManageAccount;
