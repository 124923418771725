/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Table, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { getComputersWithInstallation } from "../../../../../actions";
import Pagination from "../../../../../components/Pagination";
import CustomScrollbars from "../../../../../util/CustomScrollbars";
import Loader from "../../../../../components/Loader";
class ComputerInstallations extends React.Component {
  componentDidMount() {
    if (
      this.props.selectedInstallationItem &&
      Object.prototype.hasOwnProperty.call(
        this.props.selectedInstallationItem,
        "id"
      )
    )
      this.props.getComputersWithInstallation({
        installationId: this.props.selectedInstallationItem.id,
        Limit: 12
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedInstallationItem !== this.props.selectedInstallationItem
    ) {
      if (
        this.props.selectedInstallationItem &&
        Object.prototype.hasOwnProperty.call(
          this.props.selectedInstallationItem,
          "id"
        )
      )
        this.props.getComputersWithInstallation({
          installationId: this.props.selectedInstallationItem.id,
          Limit: 12
        });
    }
  }
  render() {
    const { computerItems, isFetchingcomputerItems, width } = this.props;
    return (
      <Grid padded>
        <Grid.Column>
          {isFetchingcomputerItems ? <Loader /> : null}

          <Table selectable padded basic="very">
            <Table.Header>
              <Table.HeaderCell>ComputerName</Table.HeaderCell>
              <Table.HeaderCell>ComputerDesc</Table.HeaderCell>
              <Table.HeaderCell>Mac Address</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {computerItems &&
                Object.prototype.hasOwnProperty.call(computerItems, "items") &&
                computerItems.items.map(_computer => (
                  <Table.Row
                    className="tbl-pad"
                    style={{ padding: "20px" }}
                    key={_computer.id}
                  >
                    <Table.Cell>{_computer.computerName}</Table.Cell>
                    <Table.Cell>{_computer.computerDesc}</Table.Cell>
                    <Table.Cell>{_computer.ipAddress}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Pagination
                    totalPages={
                      computerItems &&
                      Object.prototype.hasOwnProperty.call(
                        computerItems,
                        "totalPages"
                      ) &&
                      computerItems.totalPages
                    }
                    limit={12}
                    handlePaginationChange={filters =>
                      this.props.getComputersWithInstallation({
                        ...filters,
                        installationId: this.props.selectedInstallationItem.id
                      })
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = ({ esdActivityData, settings }) => {
  const { width } = settings;
  const {
    selectedInstallationItem,
    computerItems,
    isFetchingcomputerItems
  } = esdActivityData;
  return {
    selectedInstallationItem,
    computerItems,
    isFetchingcomputerItems,
    width
  };
};

export default connect(mapStateToProps, { getComputersWithInstallation })(
  ComputerInstallations
);
