/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import {
  Modal,
  Button,
  List,
  Form,
  Input,
  Radio,
  Select,
  Divider,
  TextArea
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  savePackage,
  updatePackage,
  closeModalService,
  onEditPackage
} from "../../../../actions";

import { Formik } from "formik";
import * as Yup from "yup";

const packageScheme = Yup.object().shape({
  id: Yup.string(),
  fileName: Yup.string().required(),
  installArgs: Yup.string().nullable(),
  platform: Yup.number().required(),
  url: Yup.string()
    .url()
    .required(),
  version: Yup.string().required(),
  uninstallArgs: Yup.string().nullable(),
  verifyRegistryByUser: Yup.string().nullable(),
  verifyRegistry: Yup.string().nullable(),
  remark: Yup.string().nullable(),
  appLauncherCommand: Yup.string().nullable()
});

class PackageModalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _package: {
        fileName: "",
        installArgs: "",
        platform: 1,
        url: "",
        version: "",
        uninstallArgs: "",
        verifyRegistryByUser: "",
        verifyRegistry: "",
        remark: "",
        appLauncherCommand: ""
      }
    };
  }

  componentDidMount() {
    if (
      this.props.selectedPackage &&
      Object.prototype.hasOwnProperty.call(this.props.selectedPackage, "id")
    ) {
      this.setState({
        _package: {
          ...this.props.selectedPackage
        }
      });
    }

    if (
      this.props.parentPackage &&
      Object.prototype.hasOwnProperty.call(this.props.parentPackage, "id")
    ) {
      this.setState({
        _package: {
          fileName: this.props.parentPackage.fileName,
          installArgs: this.props.parentPackage.installArgs,
          platform: this.props.parentPackage.platform,
          url: "",
          version: "",
          uninstallArgs: this.props.parentPackage.uninstallArgs,
          verifyRegistryByUser: this.props.parentPackage.verifyRegistryByUser,
          verifyRegistry: this.props.parentPackage.verifyRegistry,
          remark: this.props.parentPackage.remark,
          appLauncherCommand:this.props.parentPackage.appLauncherCommand,
        }
      });
    }
  }

  // handleChange = dataOBj => {
  //   this.setState({ _package: { ...dataOBj } });
  // };

  handleSubmit = _package => {
    if (Object.prototype.hasOwnProperty.call(_package, "id")) {
      this.props.updatePackage(_package);
    } else {
      this.props.savePackage(_package);
    }

    // eslint-disable-next-line react/prop-types

    setTimeout(() => {
      this.onClose();
    }, 500);
  };

  onClose = () => {
    // this.props.onUserSelect({});
    // this.props.onEditPackage("");
    this.props.closeModalService();
  };

  render() {
    const { _package } = this.state;

    // let { platform, category } = _package;
    // if (!platform) {
    //   platform = "x86";
    // }

    // if (!category) {
    //   category = "generic";
    // }
    return (
      <React.Fragment>
        <Modal.Header>
          {!Object.prototype.hasOwnProperty.call(_package, "id")
            ? "Add Package"
            : "Update Package"}
        </Modal.Header>
        <Modal.Content>
          <Formik
            enableReinitialize={true}
            initialValues={_package}
            validationSchema={packageScheme}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                this.handleSubmit(values);
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="Filename"
                    name="fileName"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fileName}
                    error={
                      errors.fileName && touched.fileName
                        ? { content: errors.fileName }
                        : null
                    }
                    placeholder="Filename"
                  />

                  <Form.Field
                    control={Input}
                    label="Version"
                    placeholder="Version"
                    name="version"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.version}
                    error={
                      errors.version && touched.version
                        ? { content: errors.version }
                        : null
                    }
                  />
                </Form.Group>
                <Form.Field
                  control={Input}
                  label="Url"
                  placeholder="url"
                  name="url"
                  required
                  value={values.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.url && touched.url ? { content: errors.url } : null
                  }
                />

                <Form.Field
                  control={Input}
                  label="Install Commands"
                  placeholder="Install Commands"
                  name="installArgs"
                  value={values.installArgs ? values.installArgs : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.installArgs && touched.installArgs
                      ? { content: errors.installArgs }
                      : null
                  }
                />

                <Form.Field
                  control={Input}
                  label="Uninstall Commands"
                  placeholder="Uninstall Commands"
                  name="uninstallArgs"
                  value={values.uninstallArgs ? values.uninstallArgs : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.uninstallArgs && touched.uninstallArgs
                      ? { content: errors.uninstallArgs }
                      : null
                  }
                />

                <Form.Field
                  control={Input}
                  label="Verify File/Folder Registry (System or User)"
                  placeholder="Verify File/Folder Registry"
                  name="verifyRegistry"
                  value={values.verifyRegistry ? values.verifyRegistry : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.verifyRegistry && touched.verifyRegistry
                      ? { content: errors.verifyRegistry }
                      : null
                  }
                />

                <Form.Field
                  control={Input}
                  label="Verify File/Folder Registry (User)"
                  placeholder="Verify File/Folder Registry"
                  name="verifyRegistryByUser"
                  value={
                    values.verifyRegistryByUser
                      ? values.verifyRegistryByUser
                      : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.verifyRegistryByUser && touched.verifyRegistryByUser
                      ? { content: errors.verifyRegistryByUser }
                      : null
                  }
                />

                <Form.Field
                  control={Input}
                  label="App Startup Command"
                  placeholder="command"
                  name="appLauncherCommand"
                  value={
                    values.appLauncherCommand
                      ? values.appLauncherCommand
                      : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.appLauncherCommand && touched.appLauncherCommand
                      ? { content: errors.appLauncherCommand }
                      : null
                  }
                />

                <Form.Field
                  control={TextArea}
                  label="Remark"
                  placeholder="desc"
                  name="remark"
                  value={values.remark ? values.remark : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.remark && touched.remark
                      ? { content: errors.remark }
                      : null
                  }
                />
                <Form.Group widths={6}>
                  {/* <Form.Field
                    control={Select}
                    label="Category"
                    // name="adminRole"
                    selection
                    onChange={(e, { value }) =>
                      setFieldValue("category", value)
                    }
                    // onBlur={handleBlur}
                    value={values.category}
                    error={
                      errors.category ? { content: errors.category } : null
                    }
                    options={[
                      { key: "generic", value: 0, text: "Generic" },
                      { key: "specific", value: 1, text: "Specific" }
                    ]}
                    placeholder="Role"
                  /> */}

                  <Form.Field
                    control={Select}
                    label="Platform"
                    // name="isCustomer"
                    selection
                    onChange={(e, { value }) =>
                      setFieldValue("platform", value)
                    }
                    // onBlur={handleBlur}
                    value={values.platform}
                    error={
                      errors.platform ? { content: errors.platform } : null
                    }
                    options={[
                      { key: "x64", value: 1, text: "x64" },
                      { key: "x86", value: 0, text: "x86" }
                    ]}
                    placeholder="platform"
                  />
                </Form.Group>

                <Divider />
                <Button.Group floated="right">
                  <Button
                    negative
                    onClick={e => {
                      e.preventDefault();
                      this.onClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    positive
                    size="medium"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Button.Group>
              </Form>
            )}
          </Formik>
        </Modal.Content>
        <Modal.Actions />
      </React.Fragment>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  savePackage,
  updatePackage,
  closeModalService,
  onEditPackage
})(PackageModalForm);
