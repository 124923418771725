/* eslint-disable no-console */
import { LOCATION_CHANGE } from "connected-react-router";
import {
  FETCH_COMPUTER_ITEMS,
  FETCH_COMPUTER_ITEMS_SUCCESS,
  GET_COMPUTERS_WITH_INSTALLATION,
  GET_COMPUTERS_WITH_INSTALLATION_SUCCESS,
  // UPDATE_COMPUTER_ITEM,
  // UPDATE_COMPUTER_ITEM_SUCCESS,
  FETCH_COMPUTER_INSTALLATIONS,
  FETCH_COMPUTER_INSTALLATIONS_SUCCESS,
  FETCH_INSTALLATION_ITEMS,
  FETCH_INSTALLATION_ITEMS_SUCCESS,
  ON_SELECT_COMPUTER,
  ON_SELECT_INSTALLATION,
  GET_ESD_LOGS,
  RESET_ESD_LOGS,
  GET_INSTALLATION_ITEM_REPORT,
  GET_COMPUTER_ITEM_REPORT,
  GET_COMPUTER_STATUS,
  GET_SYNCED_COMPUTER_ITEM,
  GET_SYNCED_INSTALLATION_ITEM,
  GET_UPDATED_COMPUTER_ITEM,
  GET_UPDATED_INSTALLATION_ITEM,
  REMOVE_DELETED_COMPUTER_ITEM,
  REMOVE_DELETED_INSTALLATION_ITEM,
  GET_IP_LOOKUP_RESTULT,
  GET_USER_LOOKUP_RESULT,
  FETCH_AGENT_SESSION_DATA,
  FETCH_AGENT_SESSION_DATA_SUCCESS,
  FETCH_AGENT_SESSION_DATA_FAILURE,
  FETCH_SESSION_ACTIVITY_INFO,
  FETCH_SESSION_ACTIVITY_INFO_SUCCESS,
  FETCH_SESSION_ACTIVITY_INFO_FAILURE,
  FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_USER_NOTIFICATION_SETTINGS,
  UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
  GENERATE_SESSION_REPORT,
  GENERATE_SESSION_REPORT_SUCCESS,
  GENERATE_SESSION_REPORT_FAILURE,
  GENERATE_CREDENTIALS,
  GENERATE_CREDENTIALS_SUCCESS,
  GENERATE_CREDENTIALS_FAILURE,
  SEND_REMOTE_COMPUTER_REQUEST,
  SEND_REMOTE_COMPUTER_REQUEST_FAILURE,
  SEND_REMOTE_COMPUTER_REQUEST_SUCCESS,
  SEND_REMOTE_INSTALL_REQUEST,
  SEND_REMOTE_INSTALL_REQUEST_FAILURE,
  SEND_REMOTE_INSTALL_REQUEST_SUCCESS
} from "../constants/ActionTypes";

const INIT_STATE = {
  computerItems: {
    items: [],
    nextPage: null,
    previousPage: null,
    totalPages: 0
  },
  installationItems: {
    items: [],
    nextPage: null,
    previousPage: null,
    totalPages: 0
  },
  sessionData: {
    items: [],
    nextPage: null,
    previousPage: null,
    totalPages: 0
  },

  sessionActivty: {
    items: [],
    nextPage: null,
    previousPage: null,
    totalPages: 0
  },

  notificationSettings: {},
  selectedComputerItem: {},
  selectedInstallationItem: {},
  isFetchingComputerItems: false,
  isFetchingInstallationItems: false,
  isFetchingSessioninfo: false,
  isFetchingAgentSessionData: false,
  isUpdatingNotificationSettings: false,
  isGeneratingReport: false,
  isGeneratingCredentails: false,
  isSendingInstallRequest: false,
  isSendingComputerRequest: false,
  esdLogs: [],
  installationItemReport: [],
  computerItemReport: [],
  ipLookupResult: "",
  userLookupResult: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        sessionActivty: INIT_STATE.sessionActivty,
        sessionData: INIT_STATE.sessionData,
        esdLogs: []
      };
    }

    case SEND_REMOTE_INSTALL_REQUEST: {
      return {
        ...state,
        isSendingInstallRequest: true
      };
    }

    case SEND_REMOTE_INSTALL_REQUEST_SUCCESS: {
      return {
        ...state,
        isSendingInstallRequest: false
      };
    }

    case SEND_REMOTE_INSTALL_REQUEST_FAILURE: {
      return {
        ...state,
        isSendingInstallRequest: false
      };
    }

    case SEND_REMOTE_COMPUTER_REQUEST: {
      return {
        ...state,
        isSendingComputerRequest: true
      };
    }

    case SEND_REMOTE_COMPUTER_REQUEST_SUCCESS: {
      return {
        ...state,
        isSendingComputerRequest: false
      };
    }

    case SEND_REMOTE_COMPUTER_REQUEST_FAILURE: {
      return {
        ...state,
        isSendingComputerRequest: false
      };
    }

    case GENERATE_CREDENTIALS: {
      return {
        ...state,
        isGeneratingCredentails: true
      };
    }

    case GENERATE_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        isGeneratingCredentails: false
      };
    }

    case GENERATE_CREDENTIALS_FAILURE: {
      return {
        ...state,
        isGeneratingCredentails: false
      };
    }

    case GENERATE_SESSION_REPORT: {
      return {
        ...state,
        isGeneratingReport: true
      };
    }

    case GENERATE_SESSION_REPORT_SUCCESS: {
      return {
        ...state,
        isGeneratingReport: false
      };
    }

    case GENERATE_SESSION_REPORT_FAILURE: {
      return {
        ...state,
        isGeneratingReport: false
      };
    }

    case UPDATE_USER_NOTIFICATION_SETTINGS: {
      return {
        ...state,
        isUpdatingNotificationSettings: true
      };
    }

    case UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        notificationSettings: action.payload,
        isUpdatingNotificationSettings: false
      };
    }
    case FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        notificationSettings: action.payload
      };
    }
    case FETCH_AGENT_SESSION_DATA: {
      return {
        ...state,
        isFetchingAgentSessionData: true
      };
    }

    case FETCH_AGENT_SESSION_DATA_SUCCESS: {
      return {
        ...state,
        isFetchingAgentSessionData: false,
        sessionData: action.payload
      };
    }

    case FETCH_AGENT_SESSION_DATA_FAILURE: {
      return {
        ...state,
        isFetchingAgentSessionData: false
      };
    }

    case FETCH_SESSION_ACTIVITY_INFO: {
      return {
        ...state,
        isFetchingSessioninfo: true
      };
    }

    case FETCH_SESSION_ACTIVITY_INFO_SUCCESS: {
      return {
        ...state,
        isFetchingSessioninfo: false,
        sessionActivty: action.payload
      };
    }

    case FETCH_SESSION_ACTIVITY_INFO_FAILURE: {
      return {
        ...state,
        isFetchingSessioninfo: false
      };
    }

    case RESET_ESD_LOGS: {
      return {
        ...state,
        esdLogs: []
      };
    }

    case GET_IP_LOOKUP_RESTULT: {
      return {
        ...state,
        ipLookupResult: action.payload
      };
    }

    case GET_USER_LOOKUP_RESULT: {
      return {
        ...state,
        userLookupResult: action.payload
      };
    }

    case GET_COMPUTER_STATUS: {
      const computerItems = Object.assign({}, state.computerItems);
      const computerItem = JSON.parse(action.payload);
      return {
        ...state,
        computerItems: {
          ...computerItems,
          items: computerItems.items.map(c =>
            c.id === computerItem.Id
              ? { ...c, onlineStatus: computerItem.OnlineStatus }
              : c
          )
        }
      };
    }

    case GET_SYNCED_COMPUTER_ITEM: {
      let computerItems = Object.assign({}, state.computerItems);
      const computerItem = [JSON.parse(action.payload)];
      computerItems = {
        ...computerItems,
        items: computerItems.items.concat(computerItem)
      };
      return {
        ...state,
        computerItems
      };
    }

    case GET_SYNCED_INSTALLATION_ITEM: {
      let installationItems = Object.assign({}, state.installationItems);
      const installationItem = [JSON.parse(action.payload)];
      installationItems = {
        ...installationItems,
        items: installationItems.items.concat(installationItem)
      };
      return {
        ...state,
        installationItems
      };
    }

    case GET_UPDATED_COMPUTER_ITEM: {
      const computerItems = Object.assign({}, state.computerItems);
      const computerItem = JSON.parse(action.payload);
      return {
        ...state,
        computerItems: {
          ...computerItems,
          items: computerItems.items.map(c =>
            c.id === computerItem.id ? computerItem : c
          )
        }
      };
    }

    case GET_UPDATED_INSTALLATION_ITEM: {
      const installationItems = Object.assign({}, state.installationItems);
      const installationItem = JSON.parse(action.payload);
      return {
        ...state,
        installationItems: {
          ...installationItems,
          items: installationItems.items.map(c =>
            c.id === installationItem.id ? installationItem : c
          )
        }
      };
    }

    case REMOVE_DELETED_COMPUTER_ITEM: {
      const computerItems = Object.assign({}, state.computerItems);
      return {
        ...state,
        computerItems: {
          ...computerItems,
          items: computerItems.items.filter(
            c => c.id !== Number(action.payload)
          )
        }
      };
    }

    case REMOVE_DELETED_INSTALLATION_ITEM: {
      const installations = Object.assign({}, state.installationItems);
      return {
        ...state,
        installations: {
          ...installations,
          items: installations.items.filter(
            c => c.id !== Number(action.payload)
          )
        }
      };
    }

    case GET_INSTALLATION_ITEM_REPORT: {
      return {
        ...state,
        installationItemReport: action.payload
      };
    }

    case GET_COMPUTER_ITEM_REPORT: {
      return {
        ...state,
        computerItemReport:
          action.payload instanceof Array ? action.payload : []
      };
    }
    case GET_ESD_LOGS: {
      //state.esdLogs.push(action.payload);
      return {
        ...state,
        esdLogs: [...state.esdLogs, action.payload]
      };
    }
    case ON_SELECT_INSTALLATION: {
      return {
        ...state,
        selectedInstallationItem: action.payload
      };
    }

    case ON_SELECT_COMPUTER: {
      return {
        ...state,
        selectedComputerItem: action.payload
      };
    }
    case FETCH_COMPUTER_ITEMS: {
      return {
        ...state,
        isFetchingComputerItems: true
      };
    }

    case FETCH_COMPUTER_ITEMS_SUCCESS: {
      return {
        ...state,
        isFetchingComputerItems: false,
        computerItems: action.payload
      };
    }

    case GET_COMPUTERS_WITH_INSTALLATION: {
      return {
        ...state,
        isFetchingComputerItems: true
      };
    }

    case GET_COMPUTERS_WITH_INSTALLATION_SUCCESS: {
      return {
        ...state,
        isFetchingComputerItems: false,
        computerItems: action.payload
      };
    }

    case FETCH_COMPUTER_INSTALLATIONS: {
      return {
        ...state,
        isFetchingInstallationItems: true
      };
    }

    case FETCH_COMPUTER_INSTALLATIONS_SUCCESS: {
      return {
        ...state,
        isFetchingInstallationItems: false,
        installationItems: action.payload
      };
    }

    case FETCH_INSTALLATION_ITEMS: {
      return {
        ...state,
        isFetchingInstallationItems: true
      };
    }

    case FETCH_INSTALLATION_ITEMS_SUCCESS: {
      return {
        ...state,
        isFetchingInstallationItems: false,
        installationItems: action.payload
      };
    }

    default:
      return state;
  }
};
