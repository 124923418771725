import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  // ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNIN_CUSTOMER,
  SIGNIN_CUSTOMER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SEND_RESET_LINK,
  SEND_RESET_LINK_SUCCESS,
  SEND_RESET_LINK_FAILED,
  SIGNIN_USER_FAILED,
  RESET_ERRORS,
  FETCH_USER_INFO,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILED
} from "../constants/ActionTypes";

export const resetPassword = data => {
  return {
    type: RESET_PASSWORD,
    payload: data
  };
};

export const resetPasswordSuccess = data => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data
  };
};

export const resetPasswordFailed = data => {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: data
  };
};

export const sendResetLink = data => {
  return {
    type: SEND_RESET_LINK,
    payload: data
  };
};

export const sendResetLinkSuccess = data => {
  return {
    type: SEND_RESET_LINK_SUCCESS,
    payload: data
  };
};

export const sendResetLinkFailed = data => {
  return {
    type: SEND_RESET_LINK_FAILED,
    payload: data
  };
};
export const customerSignIn = customer => {
  return {
    type: SIGNIN_CUSTOMER,
    payload: customer
  };
};

export const customerSignInSuccess = customer => {
  return {
    type: SIGNIN_CUSTOMER_SUCCESS,
    payload: customer
  };
};
export const userSignUp = user => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = authUser => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInFailed = data => {
  return {
    type: SIGNIN_USER_FAILED,
    payload: data
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

// export const showAuthLoader = () => {
//   return {
//     type: ON_SHOW_LOADER
//   };
// };

export const setInitUrl = url => ({
  type: INIT_URL,
  payload: url
});

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const resetError = () => ({ type: RESET_ERRORS });

export const fetchUserInfo = () => ({ type: FETCH_USER_INFO });
export const fetchUserInfoSuccess = data => ({
  type: FETCH_USER_INFO_SUCCESS,
  payload: data
});
export const fetchUserInfoFailed = err => ({
  type: FETCH_USER_INFO_FAILED,
  payload: err
});
