/* eslint-disable no-console */
import {
  all,
  call,
  take,
  fork,
  put,
  takeLatest
  // select,
  // delay
} from "redux-saga/effects";
// import { delay } from "redux-saga";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNIN_CUSTOMER,
  SEND_RESET_LINK,
  RESET_PASSWORD
} from "../constants/ActionTypes";
import {
  // showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  customerSignInSuccess,
  sendResetLinkSuccess,
  sendResetLinkFailed,
  resetPasswordSuccess,
  resetPasswordFailed,
  userSignInFailed
  // userSignIn,
  // customerSignIn
} from "../actions";

import { push } from "connected-react-router";
// import { changeUiLayout, changeUserUi } from "../actions/Setting";
import { API, authAPI } from "../api";
import { errorhandler } from "./error";
// export const RefreshTokenRequest = function*() {
//   try {
//     const authUser = yield select(({ auth }) => auth.authUser);
//     const authResponse = yield call(
//       API.request,
//       authAPI.refreshToken(authUser.token)
//     );
//     if (!authResponse) {
//       throw new Error("Token Refresh Failed");
//     }
//     console.log("authResponse", authResponse);
//     yield put({
//       type: REFRESH_TOKEN_SUCCESS,
//       payload: authResponse
//     });
//   } catch (error) {
//     console.log("Error", error);
//   }
// };

// export const refreshToken = function*(options = {}) {
//   options = {
//     delayByInterval: 30,
//     nextAction: null,

//     ...options
//   };

//   console.log("action: ", options.nextAction);
//   try {
//     yield call(RefreshTokenRequest);
//     yield delay(options.delayByInterval);
//     yield put(options.nextAction);
//   } catch (error) {
//     console.log("eror: ", error);
//     //yield call(errorhandler, error);
//   }
// };

function* signInUserWithEmailPassword({ payload }) {
  try {
    // yield call(delay, 500);
    const signInUserResponse = yield call(
      API.request,
      authAPI.webLogin(payload)
    );
    if (!signInUserResponse) throw new Error("Authentication Failed!");

    localStorage.setItem(
      "provolve_auth_user",
      JSON.stringify(signInUserResponse)
    );

    // yield put(changeUserUi());
    yield put(userSignInSuccess(signInUserResponse));
    yield put(push("/store/customers"));
  } catch (error) {
    yield put(userSignInFailed(error));
    // yield call(errorhandler, error);
  }
}

function* signInUserWithLicense({ payload }) {
  try {
    // yield call(delay, 500);
    const signInUserResponse = yield call(
      API.request,
      authAPI.esdLogin(payload)
    );

    if (!signInUserResponse) throw new Error("Authentication Failed!");

    localStorage.setItem(
      "provolve_auth_user",
      JSON.stringify(signInUserResponse)
    );

    // yield put(changeUiLayout());
    yield put(customerSignInSuccess(signInUserResponse));
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* sendResetLinkRequest({ payload }) {
  try {
    // yield call(delay, 500);
    const response = yield call(API.request, authAPI.forgotPwd(payload));

    if (!response) throw new Error("Authentication Failed!");

    yield put(sendResetLinkSuccess(response));
  } catch (error) {
    yield put(sendResetLinkFailed(error));
    // yield call(errorhandler, error);
  }
}

function* resetPwdRequest({ payload }) {
  try {
    // yield call(delay, 500);
    const response = yield call(API.request, authAPI.resetPwd(payload));

    if (!response) throw new Error("Authentication Failed!");

    yield put(resetPasswordSuccess(response));
    yield put(push("/"));
  } catch (error) {
    yield put(resetPasswordFailed(error));
    // yield call(errorhandler, error);
  }
}

// review this function
export function* signOutUser() {
  while (true) {
    yield take(SIGNOUT_USER);

    yield put(userSignOutSuccess());

    localStorage.removeItem("provolve_auth_user");
  }
}

export function* signInUser() {
  yield takeLatest(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signInCustomer() {
  yield takeLatest(SIGNIN_CUSTOMER, signInUserWithLicense);
}

export function* sendResetLink() {
  yield takeLatest(SEND_RESET_LINK, sendResetLinkRequest);
}

export function* resetPassd() {
  yield takeLatest(RESET_PASSWORD, resetPwdRequest);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(signInCustomer),
    fork(sendResetLink),
    fork(resetPassd)
  ]);
}
