import React from "react";
import PropTypes from "prop-types";
import { Table, Button } from "semantic-ui-react";

const bytesToSize = bytes => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
};

// eslint-disable-next-line react/prop-types
const PackageItem = ({ _package, onDeleteItem, onDownload, user }) => (
  <Table.Row className="tbl-pad">
    <Table.Cell>{_package.fileName}</Table.Cell>
    <Table.Cell>{bytesToSize(parseInt(_package.size))}</Table.Cell>

    <Table.Cell textAlign="right">
      <Button.Group size="tiny">
        <Button
          style={{ width: "auto" }}
          onClick={() => onDownload(_package.fileName)}
          basic
          color="blue"
        >
          Download
        </Button>
        {!user.profile.role.includes("APIClient") ? (
          <Button
            style={{ width: "auto" }}
            onClick={() => onDeleteItem(_package.id)}
            basic
            color="red"
          >
            Delete
          </Button>
        ) : null}
      </Button.Group>
    </Table.Cell>
  </Table.Row>
);

PackageItem.propTypes = {
  _package: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  user: PropTypes.object
};

export default PackageItem;
