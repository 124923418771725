/* eslint-disable no-unused-vars */
// /* eslint-disable prettier/prettier */
import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import createOidcMiddleware, { createUserManager, loadUser } from "redux-oidc";

// import createHistory from "history/createBrowserHistory";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import createRootReducer from "../reducers";
import userManager from "../util/userManager";
import { signalrMiddleware } from "../middlewares/signalr";
export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const oidcMiddleware = createOidcMiddleware(userManager);
const loggerMiddleware = createLogger({
  predicate: (getState, action) => process.env.NODE_ENV === "development"
});
const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  routeMiddleware,
  loggerMiddleware,
  sagaMiddleware,
  signalrMiddleware,
  oidcMiddleware
];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose , signalrMiddleware;

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const initialState = {};
const store = createStore(
  createRootReducer(createBrowserHistory()),
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);
loadUser(store, userManager);
sagaMiddleware.run(rootSaga);
export default store;
