/* eslint-disable no-console */
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { saveAs } from "file-saver";
import {
  BulkAssignPackagesFailure,
  BulkAssignPackagesSuccess,
  BulkDetachPackagesFailure,
  BulkDetachPackagesSuccess,
  createLicenseSuccess,
  // createtLicense,
  fetchCustomerPrvPackagesSuccess,
  fetchCustomerPubPackagesSuccess,
  fetchCustomerPUploadSuccess,
  fetchCustomerSuccess,
  getLicensesSuccess,
  getLicenseSuccess,
  onFetchSelectedCustomerPackages,
  removeCustomerSuccess,
  saveCustomerSucess,
  sendInviteFailed,
  sendInviteSuccess,
  setActiveLicenseSuccess,
  showCustomerMessage,
  updateCustomerSuccess
  // updateUnassignedPackageState,
  // updateUnassignedPUploadState
} from "../actions";

import { needRefreshToken } from "../util/checkTokenExpiration";

import {
  ADD_CUSTOMER_PACKAGE,
  ADD_CUSTOMER_PUPLOAD,
  BULK_ASSIGN_PACKAGES,
  BULK_DETACH_PACKAGES,
  CREATE_LICENSE,
  FETCH_ALL_CUSTOMER,
  FETCH_ALL_CUSTOMER_PACKAGE,
  FETCH_CUSTOMER_PRV_PACKAGES,
  FETCH_CUSTOMER_PUB_PACKAGES,
  FETCH_CUSTOMER_PUPLOADS,
  GET_LICENSE,
  GET_LICENSES,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMER_PACKAGE,
  REMOVE_CUSTOMER_PUPLOAD,
  REMOVE_LICENSE,
  SAVE_CUSTOMER,
  SEND_INVITE,
  SET_ACTIVE_LICENSE,
  SIGNOUT_USER,
  UPDATE_CUSTOMER
} from "../constants/ActionTypes";
import Notify from "../actions/Notify";
import { push } from "connected-react-router";
import { API, customersAPI, usersAPI } from "../api";
import { errorhandler } from "./error";
import { API_URL } from "../constants/Api";
import { fetchUnAssagnedPackageRequest } from "./Package";
import { fetchUnAssagnedPUploadRequest } from "./PUpload";

export function* fetchCustomerRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedCustomer = yield call(
        API.request,
        customersAPI.getCustomers(token, payload)
      );

      yield put(fetchCustomerSuccess(fetchedCustomer));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* fetchCustomerPrvPackageRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const fetchedPackages = yield call(
        API.request,
        customersAPI.getCustomerPUploads(token, payload)
      );

      if (fetchedPackages) {
        yield put(fetchCustomerPrvPackagesSuccess(fetchedPackages));
      }
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* fetchCustomerPubPackageRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const fetchedPackages = yield call(
        API.request,
        customersAPI.getCustomerPackages(token, payload)
      );

      if (fetchedPackages) {
        yield put(fetchCustomerPubPackagesSuccess(fetchedPackages));
      }
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* fetchCustomerPackagesRequest({ user }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const fetchedPackages = yield all({
        packages: call(
          API.request,
          customersAPI.getCustomerPackages(token, user.id)
        ),
        puploads: call(
          API.request,
          customersAPI.getCustomerPUploads(token, user.id)
        )
      });

      yield put(
        onFetchSelectedCustomerPackages(fetchedPackages.packages, user)
      );
      yield put(fetchCustomerPUploadSuccess(fetchedPackages.puploads));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* saveCustomerRequest({ customerData }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const customerResponse = yield call(
        API.request,
        customersAPI.createCustomer(token, {
          firstName: customerData.firstName,
          lastName: customerData.lastName,
          companyName: customerData.companyName,
          email: customerData.email,
          phoneNumber: customerData.phoneNumber,
          role: ["Customer"]
        })
      );

      if (customerResponse && customerResponse.succeeded) {
        // if (
        //   Object.prototype.hasOwnProperty.call(customerData, "issueDate") &&
        //   Object.prototype.hasOwnProperty.call(customerData, "agentExpDate") &&
        //   Object.prototype.hasOwnProperty.call(customerData, "licenses") &&
        //   Object.prototype.hasOwnProperty.call(customerData, "trial")
        // ) {
        //   yield put(
        //     createtLicense({
        //       issueDate: customerData.issueDate,
        //       expirationDate: customerData.agentExpDate,
        //       numberOfClients: Number(customerData.licenses),
        //       licenseType: Number(customerData.trial),
        //       userId: customerResponse.items.id
        //     })
        //   );
        // }

        yield put(saveCustomerSucess(customerResponse.items));

        yield put(
          showCustomerMessage(
            `${customerResponse.items.firstName} successfully Created`
          )
        );

        Notify.success(
          `${customerResponse.items.firstName} acccount successfully Created`
        );

        yield fork(fetchCustomerRequest, { payload: { Limit: 12 } });
      } else {
        if (
          customerResponse &&
          Object.prototype.hasOwnProperty.call(customerResponse, "errors")
        ) {
          if (customerResponse.errors instanceof Array) {
            Object.keys(customerResponse.errors).forEach(key => {
              Notify.error(customerResponse.errors[key]);
            });
          }
        }
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* addCustomerPackage({ payload }) {
  const { user, _package } = payload;

  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      const requestPayload = {
        packageId: _package.id,
        userId: user.id,
        packageType: 1
      };
      yield call(
        API.request,
        customersAPI.addCustomerPackage(token, requestPayload)
      );

      yield fork(fetchCustomerPubPackageRequest, {
        payload: { Limit: 12, id: user.id }
      });

      yield fork(fetchUnAssagnedPackageRequest, {
        payload: { Limit: 12, id: user.id }
      });

      // yield put(onAddCustomerPackageSuccess(_package));
      // yield put(updateUnassignedPackageState(_package.id));
      // yield put(
      //   showCustomerMessage(
      //     `${_package.fileName} Successfully added to ${user.firstName}`
      //   )
      // );
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* removeCustomerPackage({ payload }) {
  const { user, packageId } = payload;
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();

    if (!shouldRefresh) {
      yield call(
        API.request,
        customersAPI.removeCustomerPackage(token, user, packageId)
      );

      yield fork(fetchCustomerPubPackageRequest, {
        payload: { Limit: 12, id: user.id }
      });

      // yield put(onRemoveCustomerPackageSuccess(packageId));
      // yield put(
      //   showCustomerMessage(`Successfully removed from ${user.firstName}`)
      // );
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* addCustomerPUploadRequest({ payload }) {
  const { user, _package } = payload;

  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const requestPayload = {
        packageId: _package.id,
        userId: user.id,
        packageType: 0
      };
      yield call(
        API.request,
        customersAPI.addCustomerPUPload(token, requestPayload)
      );
      if (_package) {
        // yield put(onAddCustomerPUploadSuccess(_package));
        // yield put(updateUnassignedPUploadState(_package.id));
        // yield put(
        //   showCustomerMessage(
        //     `Private Package Successfully added to ${user.firstName}`
        //   )
        // );
        // yield fork();
        yield fork(fetchCustomerPrvPackageRequest, {
          payload: { Limit: 12, id: user.id }
        });

        yield fork(fetchUnAssagnedPUploadRequest, {
          payload: { Limit: 12, id: user.id }
        });
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* removeCustomerPUploadRequest({ payload }) {
  const { user, _package } = payload;
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      yield call(
        API.request,
        customersAPI.removeCustomerPUPload(token, user, _package.id)
      );
      // yield put(onRemoveCustomerPUploadSuccess(_package.id));
      yield fork(fetchCustomerPrvPackageRequest, {
        payload: { Limit: 12, id: user.id }
      });

      yield put(
        showCustomerMessage(
          `Private Package Successfully removed from ${user.firstName}`
        )
      );
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* fetchCustomerPUploadsRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const packages = yield call(
        API.request,
        customersAPI.getCustomerPUploads(token, payload)
      );
      yield put(fetchCustomerPUploadSuccess(packages));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* removeCustomerRequest({ customerId }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const selectedCustomer = yield select(
      ({ customerData }) => customerData.selectedCustomer
    );
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      if (selectedCustomer.role && selectedCustomer.role.length === 2) {
        yield call(API.request, usersAPI.deleteAdminUser(token, customerId));
      } else {
        yield call(API.request, customersAPI.remoteCustomer(token, customerId));
      }
      yield fork(fetchCustomerRequest, { payload: { Limit: 12 } });
      yield put(removeCustomerSuccess(customerId));
      yield put(showCustomerMessage("Customer Successfully deleted"));
      yield put(push("/store/customers/"));

      Notify.success("Customer Successfully deleted");
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* updateCustomerRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);

    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      var response = yield call(
        API.request,
        customersAPI.updateCustomer(token, payload)
      );

      if (response && response.succeeded) {
        const currentPage = yield select(
          ({ customerData }) => customerData.customers.page
        );

        yield fork(fetchCustomerRequest, {
          payload: { Limit: 12, Page: currentPage }
        });
        yield put(updateCustomerSuccess(payload));

        yield put(showCustomerMessage("Customer Successfully Updated"));
        Notify.success("Customer Successfully Updated");
      } else {
        if (
          response &&
          Object.prototype.hasOwnProperty.call(response, "errors")
        ) {
          if (response.errors instanceof Array) {
            Object.keys(response.errors).forEach(key => {
              Notify.error(response.errors[key]);
            });
          }
        }
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

const downloadFile = async (auth_token, licenseId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${auth_token}`,
      "Content-Type": "application/json"
    }
  };
  return await fetch(`${API_URL.SERVER}/license/${licenseId}`, requestOptions);
};

function* getLicenseRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const response = yield call(downloadFile, token, payload);
      if (response.status >= 200 && response.status < 300) {
        const data = yield response.blob();
        saveAs(data, "ESD.lic");
        yield put(getLicenseSuccess());
      } else {
        throw response;
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* getLicensesRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const licenses = yield call(
        API.request,
        customersAPI.getLicenses(token, payload)
      );
      yield put(getLicensesSuccess(licenses));
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* setActiveLicenseRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        customersAPI.setActiveLicense(token, payload)
      );
      yield put(setActiveLicenseSuccess(response.items));

      if (response.succeeded) {
        yield fork(getLicensesRequest, {
          payload: { Limit: 12, UserId: response.items.userId }
        });
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* createLicenseRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const licenses = yield call(
        API.request,
        customersAPI.createLicense(token, payload)
      );
      yield put(createLicenseSuccess(licenses));
      yield fork(getLicensesRequest, {
        payload: { Limit: 12, UserId: payload.userId }
      });
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* removeLicenseRequest({ payload }) {
  try {
    console.log(payload);
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      yield call(
        API.request,
        customersAPI.removeLicense(token, payload.licenseId)
      );
      yield fork(getLicensesRequest, {
        payload: { Limit: 12, UserId: payload.userId }
      });
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* sendInviteRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        customersAPI.sendInvite(token, payload)
      );
      if (response.succeeded) {
        yield put(sendInviteSuccess());
        Notify.success("Invite Successfully sent");
      } else {
        yield put(sendInviteFailed());
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* batchAssignPackageRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    console.log("payload: ", payload);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        customersAPI.batchAssignPackage(token, payload)
      );

      console.log("response: ", response);
      if (response.succeeded) {
        yield put(BulkAssignPackagesSuccess());
        Notify.success("Package Successfully assigned");
      } else {
        yield put(BulkAssignPackagesFailure());
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

function* batchDetachPackageRequest({ payload }) {
  try {
    const token = yield select(({ oidc }) => oidc.user.access_token);
    const shouldRefresh = yield needRefreshToken();
    if (!shouldRefresh) {
      const response = yield call(
        API.request,
        customersAPI.batchDetachPackage(token, payload)
      );
      if (response.succeeded) {
        yield put(BulkDetachPackagesSuccess());
        Notify.success("Package Successfully assigned");
      } else {
        yield put(BulkDetachPackagesFailure());
      }
    }
    if (shouldRefresh) {
      yield put({ type: SIGNOUT_USER });
    }
  } catch (error) {
    yield call(errorhandler, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_ALL_CUSTOMER, fetchCustomerRequest),
    takeLatest(FETCH_ALL_CUSTOMER_PACKAGE, fetchCustomerPackagesRequest),
    takeLatest(SAVE_CUSTOMER, saveCustomerRequest),
    takeLatest(ADD_CUSTOMER_PACKAGE, addCustomerPackage),
    takeLatest(REMOVE_CUSTOMER_PACKAGE, removeCustomerPackage),
    takeLatest(ADD_CUSTOMER_PUPLOAD, addCustomerPUploadRequest),
    takeLatest(REMOVE_CUSTOMER_PUPLOAD, removeCustomerPUploadRequest),
    takeLatest(FETCH_CUSTOMER_PUPLOADS, fetchCustomerPUploadsRequest),
    takeLatest(UPDATE_CUSTOMER, updateCustomerRequest),
    takeLatest(REMOVE_CUSTOMER, removeCustomerRequest),

    takeLatest(FETCH_CUSTOMER_PRV_PACKAGES, fetchCustomerPrvPackageRequest),
    takeLatest(FETCH_CUSTOMER_PUB_PACKAGES, fetchCustomerPubPackageRequest),

    takeLatest(GET_LICENSE, getLicenseRequest),
    takeLatest(REMOVE_LICENSE, removeLicenseRequest),
    takeLatest(SET_ACTIVE_LICENSE, setActiveLicenseRequest),
    takeLatest(GET_LICENSES, getLicensesRequest),
    takeLatest(CREATE_LICENSE, createLicenseRequest),
    takeLatest(SEND_INVITE, sendInviteRequest),

    takeLatest(BULK_ASSIGN_PACKAGES, batchAssignPackageRequest),
    takeLatest(BULK_DETACH_PACKAGES, batchDetachPackageRequest)
  ]);
}
