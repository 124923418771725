/* eslint-disable no-console */

export const request = async (
  { url, opt, isFile = false },
  parseJSON = true
) => {
  console.warn(url);
  const request = fetch(url, opt);
  if (parseJSON) {
    return request
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (isFile) return response.blob();
          return response.json();
        } else {
          throw response;
        }
      })
      .catch(async error => {
        if (error.status === 401) {
          throw { message: "User token expired", status: 401 };
        } else if (error.status === 504) {
          throw new Error(
            "Request timed out, check whether you have provided correct details!"
          );
        } else if (error.status === 502) {
          throw new Error(
            "The server returned an invalid or incomplete response."
          );
        } else if (error.status === 500) {
          throw new Error("Oops, Server didnot respond correctly");
        } else {
          // const text = await error.text(); // Parse it as text
          // const data = JSON.parse(text);
          const errorResponse = await error.json();
          //console.log("errorResponsess: ", errorResponse);
          throw errorResponse;
        }
      });
  }
  return request;
};

export const requestOptions = (
  method = "GET",
  token = "",
  customHeaders = {},
  opt = {},
  postFormData = false
) => {
  const reqHeaders = !postFormData
    ? {
        "Content-Type": "application/json"
      }
    : {};

  if (token) {
    reqHeaders["Authorization"] = `Bearer ${token}`;
  }

  return {
    method: method,
    headers: {
      ...reqHeaders,
      ...customHeaders
    },
    ...opt
  };
};

export default {
  request,
  requestOptions
};
