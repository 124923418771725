import React from "react";
import PropTypes from "prop-types";
import { List } from "semantic-ui-react";
import CustomerItem from "./customerItem";

const CustomerList = ({ customers, onSelectCustomer, selectedCustomer }) => {
  return (
    <List animated selection divided verticalAlign="middle">
      {customers.items.map(customer => (
        <CustomerItem
          key={customer.id}
          customer={customer}
          onSelectCustomer={onSelectCustomer}
          selectedCustomer={selectedCustomer}
        />
      ))}
    </List>
  );
};

CustomerList.propTypes = {
  customers: PropTypes.object.isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object
};

export default CustomerList;
