import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Button, Grid } from "semantic-ui-react";
import CustomTable from "../../../../../components/Table";
import SearchFilter from "../../../../../components/SearchBox";
import Loader from "../../../../../components/Loader";
import {
  createOrgn,
  deleteOrgn,
  updateOrgn,
  fetchOrgn,
  openModalService,
  closeModalService
} from "../../../../../actions";
import EntryForm from "../../components/EntryForm";
class OrgnList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }

  componentDidMount() {
    this.props.fetchOrgn({ Limit: 10 });
  }

  navigateToView = id => {
    this.props.push(`/store/orgn/${id}/view`);
  };

  searchTable = search => {
    this.setState({ search }, () =>
      this.props.fetchOrgn({ Term: search, Limit: 10 })
    );
  };

  handleAddOrganisation = () => {
    this.props.openModalService({
      children: (
        <EntryForm
          onSubmit={this.props.createOrgn}
          closeModalService={this.props.closeModalService}
        />
      ),
      size: "tiny"
    });
  };

  render() {
    const { navigateToView, handleAddOrganisation, state } = this;
    const { search } = state;

    const columnnData = [
      { key: "name", name: "Name" },
      { key: "address", name: "Address" },
      { key: "contact", name: "Contact" },
      { key: "email", name: "Email" },
      {
        key: "actions",
        name: "",
        custom(_item) {
          return (
            <Button.Group size="small">
              <Button
                basic
                style={{ width: "auto" }}
                onClick={() => navigateToView(_item.id)}
                color="blue"
              >
                View
              </Button>
            </Button.Group>
          );
        }
      }
    ];
    const { orgns, isProcessingOrgnRequest } = this.props;
    const { items, totalPages } = orgns;
    return (
      <React.Fragment>
        <Grid.Row>
          <Grid.Column computer="10" mobile="16">
            <Button basic color="blue" onClick={handleAddOrganisation}>
              Add Organisation
            </Button>
          </Grid.Column>
          <Grid.Column computer="6" mobile="16">
            <SearchFilter
              placeholder="search by name"
              onChange={evt => this.searchTable(evt.target.value)}
              value={search}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            {isProcessingOrgnRequest ? <Loader /> : null}
            <CustomTable
              columns={columnnData}
              data={items}
              dataKey="id"
              totalPages={totalPages}
              handlePaginationChange={filters => this.props.fetchOrgn(filters)}
            />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

OrgnList.propTypes = {
  orgns: PropTypes.shape({
    items: PropTypes.array,
    nextPage: PropTypes.string,
    previousPage: PropTypes.string,
    totalPages: PropTypes.number
  }).isRequired,
  isProcessingOrgnRequest: PropTypes.bool,
  createOrgn: PropTypes.func.isRequired,
  deleteOrgn: PropTypes.func.isRequired,
  updateOrgn: PropTypes.func.isRequired,
  fetchOrgn: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  openModalService: PropTypes.func,
  closeModalService: PropTypes.func
};

// use selectors instead
const mapStateToProps = ({ orgnData }) => {
  const { isProcessingOrgnRequest, orgns } = orgnData;
  return { isProcessingOrgnRequest, orgns };
};

export default connect(mapStateToProps, {
  createOrgn,
  deleteOrgn,
  updateOrgn,
  fetchOrgn,
  push,
  openModalService,
  closeModalService
})(OrgnList);
