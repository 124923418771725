export default [
  {
    name: "customers",
    active: true,
    text: "Customers",
    route: "/store/customers",
    icon: "users",
    organisation: "any",
    roles: ["Admin", "SuperAdmin", "Normal", "Customer"]
  },

  {
    name: "packages",
    active: false,
    text: "Packages",
    route: "/store/packages",
    icon: "disk",
    organisation: "default",
    roles: ["Admin", "SuperAdmin", "Normal", "APIClient"]
  },

  {
    name: "computerItems",
    active: false,
    text: "Computer Items",
    route: "/store/manager/computer_items",
    icon: "computer",
    organisation: "any",
    roles: ["Customer"]
  },

  {
    name: "installationItems",
    active: false,
    text: "Installation Items",
    route: "/store/manager/installation_items",
    icon: "archive",
    organisation: "any",
    roles: ["Customer"]
  },
  {
    name: "orgn",
    active: false,
    text: "Organisations",
    route: "/store/orgn",
    icon: "building",
    organisation: "default",
    roles: ["Admin", "SuperAdmin"]
  },
  {
    name: "admin",
    active: false,
    text: "Admins",
    route: "/store/users",
    icon: "adn",
    organisation: "any",
    roles: ["SuperAdmin"]
  },

  {
    name: "settings",
    active: false,
    text: "Settings",
    route: "/store/settings/",
    icon: "setting",
    organisation: "any",
    roles: ["Customer"]
  }
];
