/* eslint-disable react/prop-types */
import React from "react";

import SearchBox from "../../../../components/SearchBox";

const SearchFilter = ({ updateSearch, search }) => (
  <SearchBox
    placeholder="Search"
    value={search}
    onChange={event => updateSearch(event.target.value)}
  />
);

export default SearchFilter;
