import {
  FETCH_ALL_PUPLOAD,
  FETCH_ALL_PUPLOAD_SUCCESS,
  SAVE_PUPLOAD,
  SAVE_PUPLOAD_SUCCESS,
  FILTER_PUPLOADS,
  ON_HIDE_LOADER,
  DELETE_PUPLOAD,
  DELETE_PUPLOAD_SUCCESS,
  UPDATE_PUPLOAD,
  UPDATE_PUPLOAD_SUCCESS,
  SHOW_MESSAGE,
  ON_EDIT_PUPLOAD,
  ON_OPEN_PUPLOAD_MODAL,
  ON_CLOSE_PUPLOAD_MODAL,
  FETCH_UN_ASSIGENED_PUPLOADS,
  UPDATE_UNASSIGNED_PUPLOAD_STATE,
  DOWNLOAD_PUPLOAD,
  DOWNLOAD_PUPLOAD_SUCCESS,
  UPDATE_SEARCH_PUPLOAD,
  HANDLE_REQUEST_CLOSE,
  UPLOAD_FAILURE,
  UPLOAD_PROGRESS,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS
} from "../constants/ActionTypes";

export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE
  };
};

export const onDownloadFIle = file => {
  return {
    type: DOWNLOAD_PUPLOAD,
    file
  };
};

export const downloadPUploadSuccess = file => {
  return {
    type: DOWNLOAD_PUPLOAD_SUCCESS,
    payload: file
  };
};
export const onEditPUpload = id => {
  return {
    type: ON_EDIT_PUPLOAD,
    payload: id
  };
};
export const openPUploadModal = () => {
  return {
    type: ON_OPEN_PUPLOAD_MODAL
  };
};

export const updateUnassignedPUploadState = id => {
  return {
    type: UPDATE_UNASSIGNED_PUPLOAD_STATE,
    payload: id
  };
};

export const closePUploadModal = () => {
  return {
    type: ON_CLOSE_PUPLOAD_MODAL
  };
};
export const filterPUpload = filterText => {
  return {
    type: FILTER_PUPLOADS,
    payload: filterText
  };
};
export const fetchPUpload = filters => {
  return {
    type: FETCH_ALL_PUPLOAD,
    payload: filters
  };
};

export const fetchPUploadSuccess = packages => {
  return {
    type: FETCH_ALL_PUPLOAD_SUCCESS,
    payload: packages
  };
};

export const fetchUnAssignedPUpload = payload => {
  return {
    type: FETCH_UN_ASSIGENED_PUPLOADS,
    payload
  };
};

export const fetchUnAssignedPUploadSuccess = packages => {
  return {
    type: FETCH_ALL_PUPLOAD_SUCCESS,
    payload: packages
  };
};

export const savePUpload = (file, name) => {
  const formData = new FormData();
  formData.append("file", file);
  return {
    type: SAVE_PUPLOAD,
    formData
  };
};

export const savePUploadSuccess = _package => {
  return {
    type: SAVE_PUPLOAD_SUCCESS,
    payload: _package
  };
};

export const deletePUpload = packageId => {
  return {
    type: DELETE_PUPLOAD,
    packageId
  };
};

export const deletePUploadSuccess = packageId => {
  return {
    type: DELETE_PUPLOAD_SUCCESS,
    payload: packageId
  };
};

export const updatePUpload = _package => {
  return {
    type: UPDATE_PUPLOAD,
    _package
  };
};

export const updatePUploadSuccess = _package => {
  return {
    type: UPDATE_PUPLOAD_SUCCESS,
    payload: _package
  };
};

export const showPUploadMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const updateSearchPUpload = searchText => {
  return {
    type: UPDATE_SEARCH_PUPLOAD,
    payload: searchText
  };
};

export const uploadRequest = (file, name) => {
  const formData = new FormData();
  formData.append("file", file, name);
  return {
    type: UPLOAD_REQUEST,
    payload: formData
  };
};
export const uploadProgress = (file, progress) => ({
  type: UPLOAD_PROGRESS,
  payload: progress,
  meta: { file }
});
export const uploadSuccess = file => ({
  type: UPLOAD_SUCCESS,
  meta: { file }
});
export const uploadFailure = (file, err) => ({
  type: UPLOAD_FAILURE,
  payload: err,
  error: true,
  meta: { file }
});
