/* eslint-disable react/prop-types */
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Table, Grid } from "semantic-ui-react";
// eslint-disable-next-line no-unused-vars

import { connect } from "react-redux";

import CustomScrollbars from "../../../../../util/CustomScrollbars";
import Loader from "../../../../../components/Loader";
import Pagination from "../../../../../components/Pagination";
import {
  getLicenses,
  setActiveLicense,
  getLicense,
  removeLicense,
  openModalService
} from "../../../../../actions";
import LicenseItem from "./LicenseItem";

const pageLimit = 13;
class Licenses extends React.Component {
  componentDidMount() {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id")
    ) {
      this.props.getLicenses({
        userId: this.props.selectedCustomer.id,
        Limit: pageLimit
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id") &&
      prevProps.selectedCustomer !== this.props.selectedCustomer
    ) {
      this.props.getLicenses({
        userId: this.props.selectedCustomer.id,
        Limit: pageLimit
      });
    }
  }

  componentWillUnmount() {}

  handleDownloadLicense = licenseId => this.props.getLicense(licenseId);

  handleSetActiveLicense = licenseId => this.props.setActiveLicense(licenseId);

  handleRemoveLicense = licenseId =>
    this.props.removeLicense({
      licenseId,
      userId: this.props.selectedCustomer.id
    });

  get isAdmin() {
    return (
      this.props.user.profile.role.includes("Admin") ||
      this.props.user.profile.role.includes("SuperAdmin") ||
      this.props.user.profile.role.includes("Normal")
    );
  }

  get isCustomer() {
    if (
      this.props.selectedCustomer &&
      Object.prototype.hasOwnProperty.call(this.props.selectedCustomer, "id")
    ) {
      return (
        this.props.user.profile.role.includes("Customer") &&
        this.props.user.profile.sub === this.props.selectedCustomer.id
      );
    }
    return false;
  }
  render() {
    const {
      isFetchingLicenses,
      isFetchingLicense,
      selectedCustomer,
      isActivatingLicense,
      isRemovingLicense,
      licenses
    } = this.props;

    return (
      <Grid.Row>
        <Grid.Column>
          {isFetchingLicenses ? <Loader /> : null}
          <CustomScrollbars
            className=""
            style={{
              height: "calc(100vh - 281px)"
            }}
          >
            <Table selectable padded basic="very">
              <Table.Header>
                <Table.Row>
                  {/*<Table.HeaderCell>Idx</Table.HeaderCell>*/}
                  <Table.HeaderCell>Computers</Table.HeaderCell>
                  <Table.HeaderCell>License Type</Table.HeaderCell>
                  <Table.HeaderCell>Created</Table.HeaderCell>
                  <Table.HeaderCell>Expires</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {licenses.items.map(_license => (
                  <LicenseItem
                    key={_license.id}
                    license={_license}
                    // isCustomer={this.isCustomer}
                    // isAdmin={this.isAdmin}
                    isFetchingLicense={isFetchingLicense}
                    isActivatingLicense={isActivatingLicense}
                    isRemovingLicense={isRemovingLicense}
                    setActiveLicense={this.handleSetActiveLicense}
                    downloadLicense={this.handleDownloadLicense}
                    removeLicense={this.handleRemoveLicense}
                  />
                ))}
              </Table.Body>
            </Table>
          </CustomScrollbars>
          <Pagination
            totalPages={licenses.totalPages}
            limit={pageLimit}
            handlePaginationChange={filters =>
              this.props.getLicenses({
                userId:
                  selectedCustomer &&
                  Object.prototype.hasOwnProperty.call(
                    selectedCustomer,
                    "id"
                  ) &&
                  selectedCustomer.id,
                ...filters
              })
            }
          />
        </Grid.Column>
      </Grid.Row>
    );
  }
}

const mapStateToProps = ({ settings, customerData, oidc }) => {
  const { width } = settings;
  const { user } = oidc;
  const {
    isFetchingLicenses,
    isFetchingLicense,
    selectedCustomer,
    isActivatingLicense,
    isRemovingLicense,
    licenses
  } = customerData;
  return {
    user,
    width,
    isFetchingLicenses,
    isFetchingLicense,
    selectedCustomer,
    isActivatingLicense,
    isRemovingLicense,
    licenses
  };
};
export default connect(mapStateToProps, {
  getLicenses,
  setActiveLicense,
  getLicense,
  removeLicense,
  openModalService
})(Licenses);
