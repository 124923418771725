import {
  SWITCH_LANGUAGE,
  TOGGLE_SIDEBAR,
  WINDOW_WIDTH,
  SET_ACTIVE_MENU,
  ON_HIDE_LOADER
} from "../constants/ActionTypes";

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

export function onToggleSidebar(isNavCollapsed) {
  return {
    type: TOGGLE_SIDEBAR,
    isNavCollapsed
  };
}

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}
export function setAtiveMenu(name) {
  return {
    type: SET_ACTIVE_MENU,
    payload: name
  };
}
