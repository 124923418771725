import {
  FETCH_ALL_USER_SUCCESS,
  ON_ADD_USER,
  ON_USER_CLOSE,
  SHOW_MESSAGE,
  DELETE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  SAVE_USER_SUCCESS,
  ON_USER_SELECT,
  FETCH_ALL_USER,
  FETCH_CLIENT_DETAILS,
  FETCH_CLIENT_DETAILS_FAILURE,
  FETCH_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_DETAILS,
  UPDATE_CLIENT_DETAILS_FAILURE,
  UPDATE_CLIENT_DETAILS_SUCCESS,
  GET_USER_BY_ID_SUCCESS
} from "../constants/ActionTypes";

const INIT_STATE = {
  userList: {
    items: [],
    page: 1,
    nextPage: null,
    previousPage: null,
    totalPages: null
  },
  client: {},

  selectedUser: null,

  isProcessingRequest: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload
      };
    }

    case ON_USER_SELECT: {
      return {
        ...state,
        selectedUser: action.payload
      };
    }

    case FETCH_CLIENT_DETAILS: {
      return {
        ...state,
        isProcessingRequest: true
      };
    }

    case FETCH_CLIENT_DETAILS_FAILURE: {
      return {
        ...state,
        isProcessingRequest: false
      };
    }

    case FETCH_CLIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        client: action.payload,
        isProcessingRequest: false
      };
    }

    case UPDATE_CLIENT_DETAILS: {
      return {
        ...state,
        isProcessingRequest: true
      };
    }

    case UPDATE_CLIENT_DETAILS_FAILURE: {
      return {
        ...state,
        isProcessingRequest: false
      };
    }

    case UPDATE_CLIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        client: action.payload,
        isProcessingRequest: false
      };
    }

    case FETCH_ALL_USER: {
      return {
        ...state,
        isProcessingRequest: true
      };
    }
    case FETCH_ALL_USER_SUCCESS: {
      return {
        ...state,
        isProcessingRequest: false,
        // allUser: action.payload,
        userList: action.payload
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }

    case ON_ADD_USER: {
      return { ...state, addUserState: true };
    }
    case ON_USER_CLOSE: {
      return { ...state, addUserState: false };
    }

    case SAVE_USER_SUCCESS: {
      state.userList.push(action.payload);
      state.allUser.push(action.payload);
      return {
        ...state,
        alertMessage: "User Added Successfully",
        showMessage: true
      };
    }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        alertMessage: "User Updated Successfully",
        showMessage: true,
        userList: state.userList
      };
    }

    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        alertMessage: "User Deleted Successfully",
        showMessage: true,

        userList: state.allUser.filter(user => user.id !== action.payload.id)
      };
    }

    default:
      return state;
  }
};
