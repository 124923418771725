import {
  SWITCH_LANGUAGE,
  TOGGLE_SIDEBAR,
  WINDOW_WIDTH,
  SET_ACTIVE_MENU
} from "../constants/ActionTypes";

const initialSettings = {
  navCollapsed: false,
  width: window.innerWidth,
  activeMenu: "Customers",
  loader: false
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: state.navCollapsed
      };

    case SET_ACTIVE_MENU: {
      return {
        ...state,
        activeMenu: action.payload
      };
    }

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload
      };

    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    }

    case WINDOW_WIDTH:
      // check
      var navState = state.navCollapsed;

      if (!navState) {
        navState = action.width < 768 ? true : state.navCollapsed;
      }

      return {
        ...state,
        width: action.width,
        navCollapsed: navState
      };
    default:
      return state;
  }
};

export default settings;
