import {
  FETCH_ALL_CUSTOMER,
  FETCH_ALL_CUSTOMER_PACKAGE,
  FETCH_ALL_CUSTOMER_PACKAGE_SUCCESS,
  FETCH_ALL_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER,
  SAVE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  REMOVE_CUSTOMER,
  REMOVE_CUSTOMER_SUCCESS,
  REMOVE_CUSTOMER_PACKAGE,
  ADD_CUSTOMER_PACKAGE,
  ADD_CUSTOMER_PACKAGE_SUCCESS,
  ADD_CUSTOMER_PUPLOAD,
  REMOVE_CUSTOMER_PUPLOAD,
  REMOVE_CUSTOMER_PUPLOAD_SUCCESS,
  ADD_CUSTOMER_PUPLOAD_SUCCESS,
  REMOVE_CUSTOMER_PACKAGE_SUCCESS,

  // FILTER_CONTACT,
  FILTER_CUSTOMERS,
  ON_HIDE_LOADER,
  ON_SELECT_CUSTOMER,
  SHOW_MESSAGE,
  UPDATE_SEARCH_CUSTOMER,
  CUSTOMER_INFO_STATE,
  ON_SELECT_STORE,
  FETCH_CUSTOMER_PUPLOADS,
  FETCH_CUSTOMER_PUPLOADS_SUCCESS,
  UPDATE_SEARCH_CUSTOMER_PACKAGE,
  FILTER_CUSTOMER_PACKAGES,
  FILTER_CUSTOMER_PUPLOADS,
  UPDATE_SEARCH_CUSTOMER_PUPLOAD,
  HANDLE_REQUEST_CLOSE,
  FETCH_CUSTOMER_PUB_PACKAGES,
  FETCH_CUSTOMER_PUB_PACKAGES_SUCCESS,
  FETCH_CUSTOMER_PRV_PACKAGES,
  FETCH_CUSTOMER_PRV_PACKAGES_SUCCESS,
  GET_LICENSE,
  GET_LICENSE_SUCCESS,
  GET_LICENSE_FAILURE,
  REMOVE_LICENSE,
  REMOVE_LICENSE_FAILURE,
  REMOVE_LICENSE_SUCCESS,
  GET_LICENSES,
  GET_LICENSES_SUCCESS,
  GET_LICENSES_FAILURE,
  SET_ACTIVE_LICENSE,
  SET_ACTIVE_LICENSE_SUCCESS,
  SET_ACTIVE_LICENSE_FAILURE,
  CREATE_LICENSE,
  CREATE_LICENSE_SUCCESS,
  CREATE_LICENSE_FAILURE,
  SEND_INVITE,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_FAILED,
  BULK_ASSIGN_PACKAGES,
  BULK_ASSIGN_PACKAGES_SUCCESS,
  BULK_ASSIGN_PACKAGES_FAILURE,
  BULK_DETACH_PACKAGES,
  BULK_DETACH_PACKAGES_FAILURE,
  BULK_DETACH_PACKAGES_SUCCESS
} from "../constants/ActionTypes";

// handle fetching customer packages
export const fetchCustomerPrvPackages = filters => ({
  type: FETCH_CUSTOMER_PRV_PACKAGES,
  payload: filters
});
export const fetchCustomerPubPackages = filters => ({
  type: FETCH_CUSTOMER_PUB_PACKAGES,
  payload: filters
});

export const fetchCustomerPubPackagesSuccess = packages => ({
  type: FETCH_CUSTOMER_PUB_PACKAGES_SUCCESS,
  payload: packages
});

export const fetchCustomerPrvPackagesSuccess = packages => ({
  type: FETCH_CUSTOMER_PRV_PACKAGES_SUCCESS,
  payload: packages
});

export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE
  };
};
export const showStore = store => {
  return {
    type: ON_SELECT_STORE,
    payload: store
  };
};
export const fetchCustomer = filters => {
  return {
    type: FETCH_ALL_CUSTOMER,
    payload: filters
  };
};
export const fetchCustomerPackage = () => {
  return {
    type: FETCH_ALL_CUSTOMER_PACKAGE
  };
};

export const fetchCustomerSuccess = customers => {
  return {
    type: FETCH_ALL_CUSTOMER_SUCCESS,
    payload: customers
  };
};
export const fetchCustomerPackageSuccess = packages => {
  return {
    type: FETCH_ALL_CUSTOMER_PACKAGE_SUCCESS,
    payload: packages
  };
};

export const showCustomerMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const saveCustomer = customerData => {
  return {
    type: SAVE_CUSTOMER,
    customerData
  };
};

export const updateCustomer = customerData => {
  return {
    type: UPDATE_CUSTOMER,
    payload: customerData
  };
};

export const updateCustomerSuccess = responseData => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: responseData
  };
};

export const removeCustomer = customerId => {
  return {
    type: REMOVE_CUSTOMER,
    customerId
  };
};

export const removeCustomerSuccess = customerId => {
  return {
    type: REMOVE_CUSTOMER_SUCCESS,
    payload: customerId
  };
};

export const saveCustomerSucess = customerData => {
  return {
    type: SAVE_CUSTOMER_SUCCESS,
    payload: customerData
  };
};

export const onAddCustomerPackage = (user, _package) => {
  return {
    type: ADD_CUSTOMER_PACKAGE,
    payload: { user, _package }
  };
};

export const onAddCustomerPUpload = (user, _package) => {
  return {
    type: ADD_CUSTOMER_PUPLOAD,
    payload: { user, _package }
  };
};

export const onAddCustomerPUploadSuccess = _package => {
  return {
    type: ADD_CUSTOMER_PUPLOAD_SUCCESS,
    payload: _package
  };
};

export const onRemoveCustomerPUpload = (user, _package) => {
  return {
    type: REMOVE_CUSTOMER_PUPLOAD,
    payload: { user, _package }
  };
};

export const onRemoveCustomerPUploadSuccess = _package => {
  return {
    type: REMOVE_CUSTOMER_PUPLOAD_SUCCESS,
    payload: _package
  };
};

export const onRemoveCustomerPackage = (user, packageId) => {
  return {
    type: REMOVE_CUSTOMER_PACKAGE,
    payload: { user, packageId }
  };
};

export const onAddCustomerPackageSuccess = _package => {
  return {
    type: ADD_CUSTOMER_PACKAGE_SUCCESS,
    payload: _package
  };
};

export const onRemoveCustomerPackageSuccess = packageId => {
  return {
    type: REMOVE_CUSTOMER_PACKAGE_SUCCESS,
    payload: packageId
  };
};

export const filterCustomers = searchText => {
  return {
    type: FILTER_CUSTOMERS,
    payload: searchText
  };
};

export const filterCustomerPackages = searchText => {
  return {
    type: FILTER_CUSTOMER_PACKAGES,
    payload: searchText
  };
};

export const filterCustomerPUploads = searchText => {
  return {
    type: FILTER_CUSTOMER_PUPLOADS,
    payload: searchText
  };
};

export const onSelectCustomer = userId => {
  return {
    type: ON_SELECT_CUSTOMER,
    payload: userId
  };
};

export const onFetchSelectedCustomerPackages = (packages, user) => {
  return {
    type: ON_SELECT_CUSTOMER,
    payload: { packages, user }
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const userInfoState = state => {
  return {
    type: CUSTOMER_INFO_STATE,
    payload: state
  };
};

export const updateSearchCustomer = userName => {
  return {
    type: UPDATE_SEARCH_CUSTOMER,
    payload: userName
  };
};

export const updateSearchCustomerPackage = fileName => {
  return {
    type: UPDATE_SEARCH_CUSTOMER_PACKAGE,
    payload: fileName
  };
};

export const updateSearchCustomerPUpload = fileName => {
  return {
    type: UPDATE_SEARCH_CUSTOMER_PUPLOAD,
    payload: fileName
  };
};

export const fetchCustomerPUploads = id => {
  return {
    type: FETCH_CUSTOMER_PUPLOADS,
    id
  };
};

export const fetchCustomerPUploadSuccess = packages => {
  return {
    type: FETCH_CUSTOMER_PUPLOADS_SUCCESS,
    payload: packages
  };
};

export const createtLicense = payload => {
  return {
    type: CREATE_LICENSE,
    payload
  };
};

export const createLicenseSuccess = payload => {
  return {
    type: CREATE_LICENSE_SUCCESS,
    payload
  };
};

export const createLicenseFailure = payload => {
  return {
    type: CREATE_LICENSE_FAILURE,
    payload
  };
};

export const getLicense = licenseId => {
  return {
    type: GET_LICENSE,
    payload: licenseId
  };
};

export const getLicenseSuccess = payload => {
  return {
    type: GET_LICENSE_SUCCESS,
    payload
  };
};

export const getLicenseFailure = payload => {
  return {
    type: GET_LICENSE_FAILURE,
    payload
  };
};

export const removeLicense = licenseId => {
  return {
    type: REMOVE_LICENSE,
    payload: licenseId
  };
};

export const removeLicenseSuccess = payload => {
  return {
    type: REMOVE_LICENSE_SUCCESS,
    payload
  };
};

export const removeLicenseFailure = payload => {
  return {
    type: REMOVE_LICENSE_FAILURE,
    payload
  };
};

export const getLicenses = filters => {
  return {
    type: GET_LICENSES,
    payload: filters
  };
};

export const getLicensesSuccess = payload => {
  return {
    type: GET_LICENSES_SUCCESS,
    payload
  };
};

export const getLicensesFailure = payload => {
  return {
    type: GET_LICENSES_FAILURE,
    payload
  };
};

export const setActiveLicense = filters => {
  return {
    type: SET_ACTIVE_LICENSE,
    payload: filters
  };
};

export const setActiveLicenseSuccess = payload => {
  return {
    type: SET_ACTIVE_LICENSE_SUCCESS,
    payload
  };
};

export const setActiveLicenseFailure = payload => {
  return {
    type: SET_ACTIVE_LICENSE_FAILURE,
    payload
  };
};

export const sendInvite = payload => ({ type: SEND_INVITE, payload });
export const sendInviteSuccess = () => ({ type: SEND_INVITE_SUCCESS });
export const sendInviteFailed = () => ({ type: SEND_INVITE_FAILED });

export const BulkAssignPackages = payload => ({
  type: BULK_ASSIGN_PACKAGES,
  payload
});

export const BulkAssignPackagesSuccess = payload => ({
  type: BULK_ASSIGN_PACKAGES_SUCCESS,
  payload
});

export const BulkAssignPackagesFailure = payload => ({
  type: BULK_ASSIGN_PACKAGES_FAILURE,
  payload
});

export const BulkDetachPackages = payload => ({
  type: BULK_DETACH_PACKAGES,
  payload
});

export const BulkDetachPackagesSuccess = payload => ({
  type: BULK_DETACH_PACKAGES_SUCCESS,
  payload
});

export const BulkDetachPackagesFailure = payload => ({
  type: BULK_DETACH_PACKAGES_FAILURE,
  payload
});
