/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Button, Checkbox, Icon, Table, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchPackage,
  onEditPackage,
  fetchUnAssignedPUpload,
  updateSearchPackage,
  saveJobs,
  onAddCustomerPUpload
} from "../../../../actions";
import CustomScrollbars from "../../../../util/CustomScrollbars";
import Pagination from "../../../../components/Pagination";

const bytesToSize = bytes => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
};

const PackageItem = ({ _package, onDeleteItem, onSelectItem, isSelected }) => (
  <Table.Row
    className="clickable"
    onClick={evt => onSelectItem(evt, _package.id)}
  >
    <Table.Cell collapsing>
      <Checkbox
        checked={isSelected}
        onChange={evt => onSelectItem(evt, _package.id)}
        fitted
      />
    </Table.Cell>
    <Table.Cell>{_package.fileName}</Table.Cell>
    <Table.Cell>{bytesToSize(parseInt(_package.size))}</Table.Cell>
  </Table.Row>
);

PackageItem.propTypes = {
  _package: PropTypes.object.isRequired
};

class PrvPackages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      pUploadData: []
    };
  }
  componentDidMount() {
    this.props.fetchUnAssignedPUpload({
      id: this.props.selectedCustomer.id,
      Limit: 12
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.packages !== this.props.packages) {
      this.setState({ pUploadData: this.props.packages.items, checked: false });
    }
  }

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({
        selected: this.state.pUploadData.map(n => n.id),
        checked: checked
      });
      return;
    }
    this.setState({ selected: [], checked: checked });
  };

  onSelectItem = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  onDeselect = () => this.setState({ selected: [], checked: false });

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  onAddCustomerPUpload = () => {
    const { packages } = this.props;
    const { selected } = this.state;

    // const job = {
    //   userId: this.props.selectedCustomer.id,
    //   status: "pending",
    //   packages: { ...selected }
    // };
    // this.props.saveJobs(job);
    selected.forEach(_packageId => {
      const _package = packages.items.find(
        _package => _package.id === _packageId
      );
      this.props.onAddCustomerPUpload(this.props.selectedCustomer, _package);
    });
  };

  render() {
    const { width } = this.props;
    return (
      <React.Fragment>
        <Modal.Header>Assign Private Package</Modal.Header>
        <Modal.Content scrolling>
          <Table selectable padded basic="very">
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    onChange={(e, { checked }) =>
                      this.handleSelectAllClick(e, checked)
                    }
                    checked={this.state.checked}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>Package Name</Table.HeaderCell>
                <Table.HeaderCell>Size</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.pUploadData.map(_package => {
                const isSelected = this.isSelected(_package.id);
                return (
                  <PackageItem
                    key={_package.id}
                    _package={_package}
                    isSelected={isSelected}
                    onSelectItem={this.onSelectItem}
                  />
                );
              })}
            </Table.Body>
          </Table>
          <Pagination
            totalPages={this.props.packages.totalPages}
            limit={12}
            handlePaginationChange={filters => {
              this.props.fetchUnAssignedPUpload({
                id: this.props.selectedCustomer.id,
                ...filters
              });

              this.onDeselect();
            }}
          />
        </Modal.Content>

        <Modal.Actions>
          <Button.Group>
            <Button onClick={this.props.onClose}>Close</Button>
            <Button primary onClick={this.onAddCustomerPUpload}>
              Proceed <Icon name="right chevron" />
            </Button>
          </Button.Group>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ pUploadData, customerData, settings }) => {
  const { packages } = pUploadData;
  const { selectedCustomer } = customerData;
  const { width } = settings;
  return {
    packages,
    selectedCustomer,
    width
  };
};
export default connect(mapStateToProps, {
  fetchPackage,
  saveJobs,
  onEditPackage,
  fetchUnAssignedPUpload,
  updateSearchPackage,
  onAddCustomerPUpload
})(PrvPackages);
