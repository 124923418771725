/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import * as signalR from "@aspnet/signalr";
import pollyJs from "polly-js";
import jwtDecode from "jwt-decode";
// import { push } from "connected-react-router";
import {
  RECEIVED_MESSAGE,
  GET_TEXT_STRING,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  GET_ESD_LOGS,
  SEND_ESD_COMMAND,
  SEND_ESD_PACKAGE_INSTALL_CMD,
  SEND_ESD_REPORT_COMMAND,
  GET_INSTALLATION_ITEM_REPORT,
  GET_COMPUTER_ITEM_REPORT,
  GET_COMPUTER_STATUS,
  GET_SYNCED_COMPUTER_ITEM,
  GET_SYNCED_INSTALLATION_ITEM,
  GET_UPDATED_COMPUTER_ITEM,
  GET_UPDATED_INSTALLATION_ITEM,
  REMOVE_DELETED_COMPUTER_ITEM,
  REMOVE_DELETED_INSTALLATION_ITEM,
  SEND_INSTALLATION_ITEM_COMMAND_TO_MANAGER,
  GET_IP_LOOKUP_RESTULT,
  GET_USER_LOOKUP_RESULT
} from "../constants/ActionTypes";

import {
  USER_EXPIRED,
  SESSION_TERMINATED,
  USER_EXPIRING,
  USER_FOUND,
  USER_SIGNED_OUT
} from "redux-oidc";

import { API_URL } from "../constants/Api";

// const SERVER_URL = API_URL.API.replace("/api", "") + "/esdhub";
//console.log("Server URl: ", SERVER_URL);
const SERVER_URL = API_URL.API + "/esdhub";
let socket = null;

// https://cdn.jsdelivr.net/npm/polly-js@1.6.5/src/polly.min.js

const tryOpenSignalRConnection = (signalRConnection, store) => {
  pollyJs()
    .handle(err => {
      // console.log("Socket Error: ", err.message, err.status);
      const authUser = store.getState().oidc.user;
      if (!authUser) return false;
      if (!authUser.profile.role.includes("Customer")) return false;
      const isTokenValid = authUser.expires_at > Date.now() / 1000;
      const currentRoute = store.getState().router.location.pathname;
      //console.log("currentRoute", currentRoute);
      if (currentRoute === "/signin") {
        return false;
      }
      //console.log("Retrying Connection");
      return isTokenValid;
    })
    .waitAndRetry(10)
    .executeForPromise(async () => await signalRConnection.start())
    .then(() => {
      // dispatch action for connected indicator
      console.log("Connected");
    })
    .catch(error => {
      // dispatch action for connection error indicator
      // console.log("Error", error);
    });
};

export const init = store => {
  const authUser = store.getState().oidc.user;

  if (!authUser) return;
  if (!authUser.profile.role.includes("Customer")) return;

  const isTokenValid = authUser.expires_at > Date.now() / 1000;
  if (!isTokenValid) return;

  const authToken = authUser.access_token;

  socket = new signalR.HubConnectionBuilder()
    .withUrl(`${SERVER_URL}?user_agent=web`, {
      accessTokenFactory: () => authToken
    })
    .configureLogging(signalR.LogLevel.None)
    .build();
  // socket.serverTimeoutInMilliseconds = 1000000;

  tryOpenSignalRConnection(socket, store);
  socket.onclose(() => {
    //console.log("Retrying Connection");

    tryOpenSignalRConnection(socket, store);
  });

  const actions = [
    GET_ESD_LOGS,
    RECEIVED_MESSAGE,
    GET_INSTALLATION_ITEM_REPORT,
    GET_COMPUTER_ITEM_REPORT,
    GET_COMPUTER_STATUS,
    GET_SYNCED_COMPUTER_ITEM,
    GET_SYNCED_INSTALLATION_ITEM,
    GET_UPDATED_COMPUTER_ITEM,
    GET_UPDATED_INSTALLATION_ITEM,
    REMOVE_DELETED_COMPUTER_ITEM,
    REMOVE_DELETED_INSTALLATION_ITEM,
    GET_IP_LOOKUP_RESTULT,
    GET_USER_LOOKUP_RESULT
  ];

  if (socket) {
    actions.forEach(action => {
      socket.on(action, payload => {
        store.dispatch({
          type: action,
          payload: payload
        });
      });
    });
  }
};

export const signalrMiddleware = store => next => action => {
  const result = next(action);

  // Each of these actions will trigger an emit via Signalr
  const actions = [
    GET_TEXT_STRING,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SEND_ESD_COMMAND,
    SEND_ESD_PACKAGE_INSTALL_CMD,
    SEND_ESD_REPORT_COMMAND,
    SEND_INSTALLATION_ITEM_COMMAND_TO_MANAGER,
    USER_FOUND,
    USER_SIGNED_OUT,
    USER_EXPIRING,
    SESSION_TERMINATED,
    USER_EXPIRED
  ];

  if (actions.indexOf(action.type) > -1) {
    const state = store.getState();
    const userId = state.oidc && state.oidc.user && state.oidc.user.profile.sub;
    switch (action.type) {
      case USER_FOUND:
        init(store);
        break;
      case USER_SIGNED_OUT: {
        if (socket !== null) socket.stop();
        break;
      }
      case USER_EXPIRING: {
        if (socket !== null) socket.stop();
        break;
      }
      case SESSION_TERMINATED: {
        if (socket !== null) socket.stop();
        break;
      }
      case USER_EXPIRED: {
        if (socket !== null) socket.stop();
        break;
      }

      case SEND_ESD_PACKAGE_INSTALL_CMD:
        socket
          .invoke(
            action.type,
            userId,
            action.payload.command,
            JSON.stringify(action.payload.data),
            "web"
          )
          .catch(e => console.log("Error", e));
        break;

      case SEND_ESD_COMMAND:
        socket
          .invoke(
            action.type,
            userId,
            action.payload.command,
            JSON.stringify(action.payload.data),
            "web",
            null
          )
          .catch(e => console.log("Error", e));
        break;
      default:
        socket
          .invoke(
            action.type,
            userId,
            action.payload.command,
            action.payload.data
          )
          .catch(e => console.log("Error", e));
        break;
    }
  }

  return result;
};
