import {
  CREATE_ORGN,
  FETCH_ORGN,
  CREATE_ORGN_SUCCESS,
  CREATE_ORGN_FAILURE,
  FETCH_ORGN_SUCCESS,
  FETCH_ORGN_FAILURE,
  UPDATE_ORGN,
  UPDATE_ORGN_FAILURE,
  UPDATE_ORGN_SUCCESS,
  DELETE_ORGN,
  DELETE_ORGN_FAILURE,
  DELETE_ORGN_SUCCESS,
  FETCH_ORGN_USERS,
  FETCH_ORGN_USERS_SUCCESS,
  FETCH_ORGN_USERS_FAILURE,
  CREATE_ORGN_ADMIN,
  CREATE_ORGN_ADMIN_FAILURE,
  CREATE_ORGN_ADMIN_SUCCESS,
  GET_ORGN,
  GET_ORGN_SUCCESS,
  GET_ORGN_FAILURE
} from "../constants/ActionTypes";

export const createOrgn = data => ({ type: CREATE_ORGN, payload: data });
export const createOrgnSuccess = data => ({
  type: CREATE_ORGN_SUCCESS,
  payload: data
});
export const createOrgnFailed = reason => ({
  type: CREATE_ORGN_FAILURE,
  payload: reason
});

export const getOrgn = orgnID => ({ type: GET_ORGN, payload: orgnID });
export const getOrgnSuccess = data => ({
  type: GET_ORGN_SUCCESS,
  payload: data
});
export const getOrgnFailure = reason => ({
  type: GET_ORGN_FAILURE,
  payload: reason
});

export const fetchOrgn = filters => ({ type: FETCH_ORGN, payload: filters });
export const fetchOrgnSuccess = data => ({
  type: FETCH_ORGN_SUCCESS,
  payload: data
});
export const fetchOrgnFailure = reason => ({
  type: FETCH_ORGN_FAILURE,
  payload: reason
});

export const fetchOrgnUsers = payload => ({ type: FETCH_ORGN_USERS, payload });
export const fetchOrgnUserSuccess = data => ({
  type: FETCH_ORGN_USERS_SUCCESS,
  payload: data
});
export const fetchOrgnUserFailure = reason => ({
  type: FETCH_ORGN_USERS_FAILURE,
  payload: reason
});

export const updateOrgn = data => ({ type: UPDATE_ORGN, payload: data });
export const updateOrgnSuccess = data => ({
  type: UPDATE_ORGN_SUCCESS,
  payload: data
});
export const updateOrgnFailure = reason => ({
  type: UPDATE_ORGN_FAILURE,
  payload: reason
});

export const deleteOrgn = orgnID => ({ type: DELETE_ORGN, payload: orgnID });
export const deleteOrgnSuccess = data => ({
  type: DELETE_ORGN_SUCCESS,
  payload: data
});
export const deleteOrgnFailure = reason => ({
  type: DELETE_ORGN_FAILURE,
  payload: reason
});

export const createOrgnAdmin = data => ({
  type: CREATE_ORGN_ADMIN,
  payload: data
});

export const createOrgnAdminSuccess = data => ({
  type: CREATE_ORGN_ADMIN_SUCCESS,
  payload: data
});

export const createOrgnAdminFailure = data => ({
  type: CREATE_ORGN_ADMIN_FAILURE,
  payload: data
});
