import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as oidcReducer } from "redux-oidc";
import Settings from "./Settings";
import Auth from "./Auth";
import CustomerData from "./Customer";
import PackageData from "./Package";
import UserData from "./User";
import PUploadData from "./PUpload";
import ModalService from "./ModalService";
import esdActivityData from "./EsdActivity";
import socketService from "./SignalrHub";
import orgnData from "./Orgn";

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    oidc: oidcReducer,
    customerData: CustomerData,
    packageData: PackageData,
    UserData: UserData,
    pUploadData: PUploadData,
    orgnData,
    modalService: ModalService,
    esdActivityData,
    socketService
  });
