/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Menu, Header, Button, Icon } from "semantic-ui-react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import PageNotFound from "../../404";
import ComputerInstallations from "./ComputerInstallations";
import InstallationDetails from "./InstallationDetails";
import { onSelectInstallation } from "../../../../actions";
import CustomScrollbars from "../../../../util/CustomScrollbars";
class InstallationDetail extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.installationItems !== this.props.installationItems) {
      const selectedInstallation =
        this.props.installationItems &&
        this.props.installationItems.items.find(
          _installation =>
            _installation.id === Number(this.props.match.params.computerId)
        );
      this.props.onSelectInstallation(selectedInstallation);
    }
  }
  render() {
    const { match, selectedInstallationItem, width, location } = this.props;
    const { pathname } = location;
    return (
      <React.Fragment>
        <div className="module-box-header">
          <Menu secondary>
            <Menu.Item>
              <Header as="h3">
                {selectedInstallationItem
                  ? selectedInstallationItem.installationName
                  : ""}
                <Header.Subheader>
                  {selectedInstallationItem
                    ? selectedInstallationItem.installationType
                    : ""}
                </Header.Subheader>
              </Header>
            </Menu.Item>

            <Menu.Menu position="right">
              <Menu.Item>
                <Button.Group basic>
                  {/* <Button>Rename</Button>*/}
                  {pathname ===
                  `/store/manager/installation_items/${
                    selectedInstallationItem ? selectedInstallationItem.id : ""
                  }/comp_items` ? (
                    <Button
                      as={Link}
                      basic
                      color="blue"
                      to={`/store/manager/installation_items/${
                        selectedInstallationItem
                          ? selectedInstallationItem.id
                          : ""
                      }/details`}
                    >
                      <Icon name="archive" />
                      Installation Details
                    </Button>
                  ) : (
                    <Button
                      as={Link}
                      basic
                      color="blue"
                      to={`/store/manager/installation_items/${
                        selectedInstallationItem
                          ? selectedInstallationItem.id
                          : ""
                      }/comp_items`}
                    >
                      <Icon name="computer" />
                      Computer Items
                    </Button>
                  )}
                </Button.Group>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        <div className="module-box-content">
          <CustomScrollbars
            style={{
              height:
                width >= 1200 ? "calc(100vh - 220px)" : "calc(100vh - 205px)"
            }}
          >
            <Switch>
              <Redirect
                exact
                from={`${match.url}/`}
                to={`${match.url}/details`}
              />
              <Route
                exact
                path={`${match.url}/details`}
                component={InstallationDetails}
              />
              <Route
                exact
                path={`${match.url}/comp_items`}
                component={ComputerInstallations}
              />
              <Route component={PageNotFound} />
            </Switch>
          </CustomScrollbars>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ esdActivityData, settings, router }) => {
  const { width } = settings;
  const { location } = router;
  const { selectedInstallationItem, installationItems } = esdActivityData;
  return { selectedInstallationItem, installationItems, width };
};

export default connect(mapStateToProps, { onSelectInstallation })(
  InstallationDetail
);
