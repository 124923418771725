import {
  CHANNEL_OFF,
  CHANNEL_ON,
  SERVER_OFF,
  SERVER_ON
} from "../constants/ActionTypes";

const INIT_STATE = {
  channelStatus: "off",
  serverStatus: "unknown"
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANNEL_ON:
      return { ...state, channelStatus: "on" };
    case CHANNEL_OFF:
      return { ...state, channelStatus: "off", serverStatus: "unknown" };

    case SERVER_OFF:
      return {
        ...state,
        serverStatus: "off"
      };
    case SERVER_ON:
      return { ...state, serverStatus: "on" };

    default:
      return state;
  }
};
