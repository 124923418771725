/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { Table, Modal, Button } from "semantic-ui-react";

import { sendEsdCommand } from "../../../../actions";

class ComputerReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.sendEsdCommand({
      command: "ShowComputerReport",
      data: this.props.selectedComputerItem
    });
  }

  render() {
    const {
      selectedComputerItem,
      computerItemReport,
      closeModalService
    } = this.props;
    return (
      <React.Fragment>
        <Modal.Header>{`Report Status for ${selectedComputerItem.computerName}`}</Modal.Header>
        <Modal.Content>
          <Table basic="very" striped padded="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Installation</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Check</Table.HeaderCell>
                <Table.HeaderCell>Error</Table.HeaderCell>
                <Table.HeaderCell>TimeStamp</Table.HeaderCell>
                <Table.HeaderCell>Counter</Table.HeaderCell>
                <Table.HeaderCell>UserName</Table.HeaderCell>
                <Table.HeaderCell>Info</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {computerItemReport &&
                computerItemReport.map((_item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{_item.installation}</Table.Cell>
                    <Table.Cell>{_item.status}</Table.Cell>
                    <Table.Cell>{_item.check}</Table.Cell>
                    <Table.Cell>{_item.error}</Table.Cell>
                    <Table.Cell>{_item.timeStamp}</Table.Cell>
                    <Table.Cell>{_item.counter}</Table.Cell>
                    <Table.Cell>{_item.userName}</Table.Cell>
                    <Table.Cell>{_item.info}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModalService}>Close</Button>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ esdActivityData }) => {
  const { selectedComputerItem, computerItemReport } = esdActivityData;
  return {
    selectedComputerItem,
    computerItemReport
  };
};
export default connect(mapStateToProps, { sendEsdCommand })(ComputerReport);
