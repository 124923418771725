export const useDecodeLicense = file => {
  let licenseData = {
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    issueDate: "",
    agentExpDate: "",
    licenses: "",
    trial: "",
    checkSum: ""
  };
  var reader = new FileReader();
  reader.readAsText(file);
  reader.onloadend = function() {
    if (reader.readyState === 2) {
      var lines = this.result.split("\n");
      for (var line = 0; line < lines.length; line++) {
        switch (line) {
          case 1: {
            const fullName = lines[line].split("=")[1];
            licenseData["firstName"] = fullName
              .split(" ")[0]
              .replace(/\s+/g, "")
              .trim();
            licenseData["lastName"] = fullName
              .split(" ")[1]
              .replace(/\s+/g, "")
              .trim();

            break;
          }
          case 2:
            licenseData["companyName"] = lines[line].split("=")[1].trim();
            break;
          case 3:
            licenseData["email"] = lines[line].split("=")[1].trim();
            break;
          case 4:
            licenseData["phoneNumber"] = lines[line].split("=")[1].trim();
            break;
          case 5:
            licenseData["issueDate"] = convertDates(
              lines[line].split("=")[1].trim()
            );
            break;
          case 6:
            licenseData["agentExpDate"] = convertDates(
              lines[line].split("=")[1].trim()
            );
            break;
          case 7:
            licenseData["licenses"] = lines[line].split("=")[1].trim();
            break;
          case 8:
            licenseData["trial"] = lines[line].split("=")[1].trim();
            break;
          case 9:
            licenseData["checkSum"] = lines[line].split("=")[1].trim();
            break;

          default:
            break;
        }
      }
    }
  };

  return licenseData;
};

const convertDates = string => {
  var year = string.substring(0, 4);
  var month = string.substring(4, 6);
  var day = string.substring(6, 8);
  var hour = string.substring(8, 10);
  var minute = string.substring(10, 12);
  var second = string.substring(12, 14);
  const date =
    year +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hour +
    ":" +
    minute +
    ":" +
    second +
    ".000Z";

  return date;
};
