import queryString from "query-string";
import { API_URL } from "../constants/Api";
import API from "./api";

export const getOrganisations = (token, filters) => {
  const params = queryString.stringify(filters);
  return {
    url: `${API_URL.SERVER}/organisation/?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const getOrganisation = (token, organisationId) => {
  return {
    url: `${API_URL.SERVER}/organisation/${organisationId}`,
    opt: API.requestOptions("GET", token)
  };
};

export const createOrganisation = (token, orgnData) => {
  const body = JSON.stringify(orgnData);
  return {
    url: `${API_URL.SERVER}/organisation`,
    opt: API.requestOptions("POST", token, null, { body })
  };
};

export const createUserInOrg = (token, UserData) => {
  const body = JSON.stringify(UserData);
  return {
    url: `${API_URL.SERVER}/organisation/${UserData.organisationId}/admin`,
    opt: API.requestOptions("POST", token, null, { body })
  };
};

export const updateOrganisation = (token, orgnData) => {
  const body = JSON.stringify(orgnData);
  return {
    url: `${API_URL.SERVER}/organisation/${orgnData.id}`,
    opt: API.requestOptions("PUT", token, null, { body })
  };
};

export const deleteOrganisation = (token, id) => {
  return {
    url: `${API_URL.SERVER}/organisation/${id}`,
    opt: API.requestOptions("DELETE", token)
  };
};

export const getOrganisationUsers = (token, orgID, filters) => {
  const params = queryString.stringify(filters);
  return {
    url: `${API_URL.SERVER}/organisation/${orgID}/users/?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export default {
  getOrganisation,
  getOrganisations,
  createOrganisation,
  updateOrganisation,
  deleteOrganisation,
  getOrganisationUsers,
  createUserInOrg
};
