import { createSelector } from "reselect";

export const selectCustomerData = state => state.customerData;
export const getActivePage = createSelector(
  [selectCustomerData],
  state => state.customers.page
);

export const getSelectedCustomer = createSelector(
  [selectCustomerData],
  state => state.selectedCustomer
);

export const selectInviteStatus = createSelector(
  [selectCustomerData],
  state => state.isSendingInvite
);
