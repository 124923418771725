/* eslint-disable react/prop-types */
import React from "react";
import { List } from "semantic-ui-react";
import PropTypes from "prop-types";

const ComputerItem = ({ computer, onSelectItem, selectedComputerItem }) => (
  <List.Item
    as="a"
    active={
      selectedComputerItem ? selectedComputerItem.id === computer.id : false
    }
    onClick={() => onSelectItem(computer)}
  >
    <List.Icon
      name="computer"
      size="large"
      verticalAlign="middle"
      color={computer.onlineStatus ? "green" : "red"}
    />

    <List.Content>
      <List.Header as="h4">{computer.computerName}</List.Header>
      <List.Description>{computer.computerDesc}</List.Description>
    </List.Content>
  </List.Item>
);

ComputerItem.propTypes = {
  computer: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired
};

const ComputerList = ({ computers, onSelectItem, selectedComputerItem }) => (
  <List animated selection divided verticalAlign="middle">
    {computers &&
      Object.prototype.hasOwnProperty.call(computers, "items") &&
      computers.items.map(computer => (
        <ComputerItem
          key={computer.id}
          computer={computer}
          onSelectItem={onSelectItem}
          selectedComputerItem={selectedComputerItem}
        />
      ))}
  </List>
);

ComputerList.propTypes = {
  computers: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired
};

export default ComputerList;
