import queryString from "query-string";
import {
  API_URL_COMPUTER_ITEMS,
  API_URL_INSTALL_ITEMS,
  API_URL
} from "../constants/Api";
import API from "./api";

export const getComputerInstallations = (token, filters) => {
  let computerId = filters.computerId;
  delete filters.computerId;
  const params = queryString.stringify(filters);
  return {
    url: `${API_URL_INSTALL_ITEMS}computer/${computerId}?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const getComputerWithInstallations = (token, filters) => {
  let installationId = filters.installationId;
  delete filters.installationId;
  const params = queryString.stringify(filters);
  return {
    url: `${API_URL_COMPUTER_ITEMS}installation/${installationId}?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const getComputerItems = (token, filters) => {
  let userId = filters.userId;
  delete filters.userId;
  const params = queryString.stringify(filters);
  return {
    url: `${API_URL.API}/computerItem/user/${userId}?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const getInstallationItems = (token, filters) => {
  let userId = filters.userId;
  delete filters.userId;
  const params = queryString.stringify(filters);
  return {
    url: `${API_URL.API}/installationitem/user/${userId}?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const sendRemoteInstallCmd = (token, data, userId) => {
  return {
    url: `${API_URL.API}/send-request/${userId}/install/`,
    opt: API.requestOptions("POST", token, {}, { body: JSON.stringify(data) })
  };
};

export const sendRemoteComputerCmd = (token, data, userId) => {
  return {
    url: `${API_URL.API}/send-request`,
    opt: API.requestOptions("POST", token, {}, { body: JSON.stringify(data) })
  };
};

export const fetchSessionData = (token, filters) => {
  const query = {
    StartDate: filters.StartDate,
    EndDate: filters.EndDate,
    Limit: filters.Limit,
    Page: filters.Page
  };
  const params = queryString.stringify(query);
  return {
    url: `${API_URL.API}/reports/active-agents/${filters.UserId}?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const fetchAgentSessionData = (token, filters) => {
  const sessionId = filters.connectionID;
  delete filters.connectionID;
  const params = queryString.stringify(filters);
  return {
    url: `${API_URL.API}/computer/sessions/${sessionId}?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export const fetchUserNotificationSettings = (token, userId) => {
  return {
    url: `${API_URL.API}/email-settings/${userId}`,
    opt: API.requestOptions("GET", token)
  };
};

export const updateUserNotificationSettings = (token, data) => {
  return {
    url: `${API_URL.API}/email-settings/${data.id}`,
    opt: API.requestOptions("PUT", token, {}, { body: JSON.stringify(data) })
  };
};

export const generateXlsxReport = (token, filters) => {
  const query = { StartDate: filters.StartDate, EndDate: filters.EndDate };
  const params = queryString.stringify(query);
  return {
    url: `${API_URL.API}/reports/active-agents/${filters.UserId}/file?${params}`,
    opt: API.requestOptions("GET", token)
  };
};

export default {
  getComputerItems,
  getInstallationItems,
  getComputerWithInstallations,
  getComputerInstallations,
  sendRemoteComputerCmd,
  sendRemoteInstallCmd,
  fetchSessionData,
  fetchAgentSessionData,
  fetchUserNotificationSettings,
  updateUserNotificationSettings,
  generateXlsxReport
};
