import React, { useCallback, useEffect } from "react";
import { Button, Divider, Form, Modal, Select } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModalService,
  updateCustomer,
  fetchOrgn
} from "../../../../actions";

import { Formik } from "formik";
import * as Yup from "yup";

import { getSelectedCustomer } from "../../../../selectors/customer";
import { selectOrganisations } from "../../../../selectors/organisation";
import CustomInput from "../../../../components/InputField";

const schema = Yup.object().shape({
  id: Yup.string(),

  organisationId: Yup.string()
    .label("Organisation")
    .required()
});

const AttachOrganisation = () => {
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(getSelectedCustomer);
  const organisations = useSelector(selectOrganisations);

  useEffect(() => {
    dispatch(fetchOrgn({ Limit: 10000 }));
    return () => {};
  }, [dispatch]);

  const onClose = useCallback(() => dispatch(closeModalService()), [dispatch]);

  const handleSubmit = useCallback(
    values => {
      if (Object.prototype.hasOwnProperty.call(values, "id")) {
        dispatch(updateCustomer(values));
      }
      setTimeout(() => {
        onClose();
      }, 500);
    },
    [dispatch, onClose]
  );

  const orgOptions = organisations.items.map(_org => ({
    key: _org.id,
    text: _org.name,
    value: _org.id
  }));

  return (
    <React.Fragment>
      <Modal.Header>Change Organisation</Modal.Header>
      <Modal.Content>
        <Formik
          enableReinitialize={true}
          initialValues={selectedCustomer}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSubmit(values);
              actions.setSubmitting(false);
            }, 1000);
          }}
        >
          {({ isSubmitting, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <CustomInput
                id="organisationId"
                name="organisationId"
                label="Organisation*"
                control={Select}
                options={orgOptions}
                onChange={(evt, data) =>
                  setFieldValue("organisationId", data.value)
                }
                search
                placeholder="select OrganisationId"
              />

              <Divider />
              <Button.Group floated="right">
                <Button negative onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  positive
                  size="medium"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Button.Group>
            </Form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions />
    </React.Fragment>
  );
};

export default AttachOrganisation;
