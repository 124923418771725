import { createUserManager } from "redux-oidc";
import Oidc from "oidc-client";

import { API_URL } from "../constants/Api";
Oidc.Log.logger = console;
const userManagerConfig = {
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  client_id: "6ff604d1-ef2a-4cbd-a3d6-38e80704e895",
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }/callback`,
  response_type: "code",
  scope: `openid profile IdentityServerApi read:user read:user:all create:user update:user remove:user read:organisation:all read:organisation create:organisation update:organisation remove:organisation read:license read:license:all create:license update:license read:package read:package:user read:package:all assign:package create:package update:package remove:package read:installationItem read:installationItem:user read:installationItem:all create:installationItem update:installationItem remove:installationItem read:computerItem create:computerItem remove:computerItem update:computerItem read:computerItem:user read:computerItem:all`,

  authority: API_URL.SERVER.replace("api", ""), //"http://download.easysoftwaredeployment.nl:5007/",
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}/signin`,
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}/callback`,
  automaticSilentRenew: false,
  filterProtocolClaims: false,
  loadUserInfo: true
};

//console.log("Authority: ", userManagerConfig.authority)
const userManager = createUserManager(userManagerConfig);

export default userManager;
