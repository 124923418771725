import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form, Modal, Button, Input } from "semantic-ui-react";
import * as Yup from "yup";

import TextField from "../../../../../components/InputField";

const EntryFormSchema = Yup.object().shape({
  redirectUris: Yup.string()
    .label("Callback URL")
    .required("Required")
});

function EntryForm({ onSubmit, closeModalService, apiClient }) {
  const formikRef = useRef(null);

  return (
    <>
      <Modal.Header>Update Callback</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={
            apiClient || {
              redirectUris: ""
            }
          }
          innerRef={formikRef}
          enableReinitialize
          validationSchema={EntryFormSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              const payload = {
                ...values,
                redirectUris: [values.redirectUris]
              };
              onSubmit(payload);

              actions.setSubmitting(false);

              formikRef.current.resetForm();
            }, 1000);
          }}
          //   // eslint-disable-next-line no-unused-vars
          //   onReset={(values, formikBag) => {
          //     setTimeout(() => {
          //       closeModalService();
          //     }, 200);
          //   }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <TextField
                name="redirectUris"
                type="text"
                // label="Callback URL"
                placeholder="Callback urls"
                control={Input}
                fluid
              />

              <Button.Group floated="right">
                <Button onClick={closeModalService}>CANCEL</Button>

                <Button
                  color="blue"
                  type="submit"
                  disabled={props.isSubmitting}
                >
                  SAVE
                </Button>
              </Button.Group>
            </Form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions />
    </>
  );
}

EntryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  closeModalService: PropTypes.func.isRequired,

  apiClient: PropTypes.object,

  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default EntryForm;
