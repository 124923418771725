import { createSelector } from "reselect";

export const getPackagesSelector = state => state.packageData;
export const getPackageListActivePage = createSelector(
  [getPackagesSelector],
  state => state.packageList.page
);

export const getSelectedPackageSelector = createSelector(
  [getPackagesSelector],
  state => state.selectedPackage
);

export const getSelectedPackageStatusSelector = createSelector(
  [getPackagesSelector],
  state => state.isFindingPackage
);
