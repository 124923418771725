import queryString from "query-string";
import {
  API_URL_USERS,
  API_URL_USERS_ID_SERVER,
  API_URL
} from "../constants/Api";
import api from "./api";

export const getCustomers = (token, filters) => {
  const params = queryString.stringify(filters);
  return {
    url: API_URL_USERS + "customers?" + params,
    opt: api.requestOptions("GET", token)
  };
};

export const getCustomerPackages = (token, filters) => {
  let userId = filters.id;
  delete filters.id;
  const params = queryString.stringify(filters);
  return {
    url: API_URL.API + `/user-package/software/${userId}?${params}`,
    opt: api.requestOptions("GET", token)
  };
};

export const getUnAssignedPackages = (token, payload) => {
  let userId = payload.id;
  delete payload.id;
  const params = queryString.stringify(payload);
  return {
    url: API_URL.API + `/user-package/software/${userId}/unassigned?${params}`,
    opt: api.requestOptions("GET", token)
  };
};

export const getUnAssignedPUpload = (token, payload) => {
  let userId = payload.id;
  delete payload.id;
  const params = queryString.stringify(payload);
  return {
    url: API_URL.API + `/user-package/esd/${userId}/unassigned?${params}`,
    opt: api.requestOptions("GET", token)
  };
};

export const createCustomer = (token, userData) => {
  const body = JSON.stringify(userData);
  return {
    url: API_URL.SERVER + "/user",
    opt: api.requestOptions("POST", token, null, { body })
  };
};

export const updateCustomer = (token, customerData) => {
  return {
    url: API_URL.SERVER + "/user/" + customerData.id,
    opt: api.requestOptions(
      "PUT",
      token,
      {},
      { body: JSON.stringify(customerData) }
    )
  };
};

export const remoteCustomer = (token, id) => {
  return {
    url: API_URL_USERS_ID_SERVER + id,
    opt: api.requestOptions("DELETE", token)
  };
};

export const addCustomerPackage = (token, payload) => {
  return {
    url: API_URL.API + `/user-package/`,
    opt: api.requestOptions(
      "POST",
      token,
      {},
      { body: JSON.stringify(payload) }
    )
  };
};

export const removeCustomerPackage = (token, user, packageId) => {
  return {
    url: API_URL.API + `/user-package/${packageId}/user/${user.id}/type/1`,
    opt: api.requestOptions("DELETE", token)
  };
};

export const addCustomerPUPload = (token, payload) => {
  return {
    url: API_URL.API + `/user-package/`,
    opt: api.requestOptions(
      "POST",
      token,
      {},
      { body: JSON.stringify(payload) }
    )
  };
};

export const removeCustomerPUPload = (token, user, packageId) => {
  return {
    url: API_URL.API + `/user-package/${packageId}/user/${user.id}/type/0`,
    opt: api.requestOptions("DELETE", token)
  };
};

export const getCustomerPUploads = (token, filters) => {
  let userId = filters.id;
  delete filters.id;
  const params = queryString.stringify(filters);
  return {
    url: API_URL.API + `/user-package/esd/${userId}?${params}`,
    opt: api.requestOptions("GET", token)
  };
};

export const createLicense = (token, license) => {
  return {
    url: API_URL.SERVER + `/license/`,
    opt: api.requestOptions(
      "POST",
      token,
      {},
      { body: JSON.stringify(license) }
    )
  };
};

export const getLicenses = (token, filters) => {
  const params = queryString.stringify(filters);
  return {
    url: API_URL.SERVER + `/license/?${params}`,
    opt: api.requestOptions("GET", token)
  };
};

export const setActiveLicense = (token, licenseId) => {
  return {
    url: API_URL.SERVER + `/License/SetActive/${licenseId}`,
    opt: api.requestOptions("PUT", token)
  };
};

export const getLicense = (token, licenseId) => {
  return {
    url: API_URL.SERVER + `/License/${licenseId}`,
    opt: api.requestOptions("GET", token)
  };
};

export const removeLicense = (token, licenseId) => {
  return {
    url: API_URL.SERVER + `/License/${licenseId}`,
    opt: api.requestOptions("DELETE", token)
  };
};

export const sendInvite = (token, payload) => {
  return {
    url: API_URL.SERVER + `/user/invite`,
    opt: api.requestOptions(
      "POST",
      token,
      {},
      { body: JSON.stringify(payload) }
    )
  };
};

export const batchAssignPackage = (token, payload) => {
  return {
    url: API_URL.API + "/user-package/batch/attach",
    opt: api.requestOptions(
      "POST",
      token,
      {},
      { body: JSON.stringify(payload) }
    )
  };
};

export const batchDetachPackage = (token, payload) => {
  return {
    url: API_URL.API + `/user-package/batch/detach`,
    opt: api.requestOptions(
      "POST",
      token,
      {},
      { body: JSON.stringify(payload) }
    )
  };
};

export default {
  getCustomerPUploads,
  getCustomerPackages,
  getCustomers,
  createCustomer,
  updateCustomer,
  remoteCustomer,
  removeCustomerPUPload,
  removeCustomerPackage,
  addCustomerPUPload,
  addCustomerPackage,
  getUnAssignedPUpload,
  getUnAssignedPackages,

  getLicenses,
  createLicense,
  getLicense,
  setActiveLicense,
  sendInvite,
  removeLicense,

  batchAssignPackage,
  batchDetachPackage
};
