/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { Grid, Table, Modal, Button } from "semantic-ui-react";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { sendEsdReportCommand } from "../../../../actions";

const COLORS = ["#F2DF32", "#32CD32", "#4169E1", "#FF0000", "#FF8C00"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class InstallationReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.sendEsdReportCommand({
      command: "ShowReports",
      data: this.props.selectedInstallationItem
    });
  }

  get getPieChatData() {
    let totalCount = [
      { name: "Waiting", value: 0 },
      { name: "Installed", value: 0 },
      { name: "Removed", value: 0 },
      { name: "Failed", value: 0 },
      { name: "Rejected", value: 0 }
    ];
    const { installationItemReport } = this.props;
    if (installationItemReport.length) {
      totalCount.map(_item => {
        _item.value = installationItemReport.reduce(function(
          total,
          currentValue
        ) {
          return total + currentValue[_item.name.toLowerCase()];
        },
        _item.value);
      });
    }

    return totalCount;
  }

  render() {
    const {
      selectedInstallationItem,
      installationItemReport,
      closeModalService
    } = this.props;

    return (
      <React.Fragment>
        <Modal.Header>{`Report Status for ${selectedInstallationItem.installationName}`}</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <PieChart width={470} height={200}>
                  <Pie
                    data={this.getPieChatData}
                    labelLine={false}
                    cx={200}
                    cy={100}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {this.getPieChatData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="middle"
                  />
                </PieChart>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Table
                  padded="very"
                  basic="very"
                  columns={6}
                  textAlign="center"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Computer</Table.HeaderCell>
                      <Table.HeaderCell>Waiting</Table.HeaderCell>
                      <Table.HeaderCell>Installed</Table.HeaderCell>
                      <Table.HeaderCell>Removed</Table.HeaderCell>
                      <Table.HeaderCell>Failed</Table.HeaderCell>
                      <Table.HeaderCell>Rejected</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {installationItemReport.map((_item, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{_item.computer}</Table.Cell>
                        <Table.Cell>{_item.waiting}</Table.Cell>
                        <Table.Cell>{_item.installed}</Table.Cell>
                        <Table.Cell>{_item.removed}</Table.Cell>
                        <Table.Cell>{_item.failed}</Table.Cell>
                        <Table.Cell>{_item.rejected}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModalService}>Close</Button>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ esdActivityData }) => {
  const { selectedInstallationItem, installationItemReport } = esdActivityData;
  return {
    selectedInstallationItem,
    installationItemReport
  };
};
export default connect(mapStateToProps, { sendEsdReportCommand })(
  InstallationReport
);
