/* eslint-disable no-console */
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";
import MainApp from "./MainApp";

//console.log("Environment", process.env.NODE_ENV);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://ba5dc6f1bf304b648d2d4a3773616514@o361612.ingest.sentry.io/4255758"
  });
}

const rootElement = document.getElementById("root");
ReactDOM.render(<MainApp />, rootElement);
serviceWorker.unregister();
