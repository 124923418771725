import React from "react";
import {
  Container,
  Menu,
  Responsive,
  Dropdown,
  Image
} from "semantic-ui-react";
import PropTypes from "prop-types";

import SidebarChevronRightBtn from "./SidebarChevronRightBtn";

const TopNav = ({
  onToggleNav,
  userSignOut,
  navCollapsed,
  activeMenu,
  width
}) => (
  <Container fluid className="app-main-header">
    <Menu className="menu-header" fixed="top" text>
      <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Menu.Item}>
        <SidebarChevronRightBtn
          onToggleNav={onToggleNav}
          isNavCollapsed={navCollapsed}
        />
      </Responsive>

      <Menu.Item className="status-icon">
        {width > Responsive.onlyTablet.minWidth ? (
          <span>{activeMenu}</span>
        ) : null}
      </Menu.Item>
      <Menu.Menu position="right">
        <Dropdown
          item
          trigger={
            <React.Fragment>
              <Menu.Item className="userProfile">
                <Image
                  src={require("../../assets/profile.png")}
                  avatar
                  size="mini"
                />
              </Menu.Item>
            </React.Fragment>
          }
          icon={null}
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => userSignOut()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  </Container>
);

TopNav.propTypes = {
  onToggleNav: PropTypes.func.isRequired,

  navCollapsed: PropTypes.bool.isRequired,
  activeMenu: PropTypes.string,
  width: PropTypes.number.isRequired,
  userSignOut: PropTypes.func.isRequired
};

export default TopNav;
